import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const DeleteIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M5.263 6.324A1 1 0 0 1 6 6h12a1 1 0 0 1 .996 1.087L18.12 17.18A3.087 3.087 0 0 1 15.043 20H8.957a3.087 3.087 0 0 1-3.076-2.82L5.004 7.087a1 1 0 0 1 .259-.763ZM7.09 8l.783 9.007c.049.562.519.993 1.083.993h6.086c.564 0 1.034-.431 1.083-.993L16.909 8H7.091Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M10 9.5a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-1 0V10a.5.5 0 0 1 .5-.5Zm4 0a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-1 0V10a.5.5 0 0 1 .5-.5ZM11.182 5C10.445 5 10 5.528 10 6v1a1 1 0 0 1-2 0V6c0-1.737 1.508-3 3.182-3h1.636C14.492 3 16 4.263 16 6v1a1 1 0 1 1-2 0V6c0-.472-.445-1-1.182-1h-1.636Z"
        clipRule="evenodd"
      />
      <path fillRule="evenodd" d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z" clipRule="evenodd" />
    </Icon>
  );
};

export const DeleteSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M5.263 6.324A1 1 0 0 1 6 6h12a1 1 0 0 1 .996 1.087L18.12 17.18A3.087 3.087 0 0 1 15.043 20H8.957a3.087 3.087 0 0 1-3.076-2.82L5.004 7.087a1 1 0 0 1 .259-.763ZM7.09 8l.783 9.007c.049.562.519.993 1.083.993h6.086c.564 0 1.034-.431 1.083-.993L16.909 8H7.091Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M10 9.5a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-1 0V10a.5.5 0 0 1 .5-.5Zm4 0a.5.5 0 0 1 .5.5v5.5a.5.5 0 0 1-1 0V10a.5.5 0 0 1 .5-.5ZM11.182 5C10.445 5 10 5.528 10 6v1a1 1 0 0 1-2 0V6c0-1.737 1.508-3 3.182-3h1.636C14.492 3 16 4.263 16 6v1a1 1 0 1 1-2 0V6c0-.472-.445-1-1.182-1h-1.636Z"
      clipRule="evenodd"
    />
    <path fillRule="evenodd" d="M4 7a1 1 0 0 1 1-1h14a1 1 0 1 1 0 2H5a1 1 0 0 1-1-1Z" clipRule="evenodd" />
  </svg>
);
