import { FC } from "react";

import { IconProps, Icon } from "src/ui/icons/icon";

export const ModelIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M10 10H8V16H10V10Z" fill="currentColor" />
      <path d="M14 12H16V16H14V12Z" fill="currentColor" />
      <path d="M13 8H11V16H13V8Z" fill="currentColor" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.58065 4C5.15539 4 4 5.15539 4 6.58065V17.4194C4 18.8446 5.15539 20 6.58065 20H17.4194C18.8446 20 20 18.8446 20 17.4194V6.58065C20 5.15539 18.8446 4 17.4194 4H6.58065ZM5.54839 6.58065C5.54839 6.01054 6.01054 5.54839 6.58065 5.54839H17.4194C17.9895 5.54839 18.4516 6.01054 18.4516 6.58065V17.4194C18.4516 17.9895 17.9895 18.4516 17.4194 18.4516H6.58065C6.01054 18.4516 5.54839 17.9895 5.54839 17.4194V6.58065Z"
        fill="currentColor"
      />
    </Icon>
  );
};
