import { FC, useEffect, useState } from "react";

import {
  ConfirmationDialog,
  EditableHeading,
  EditableDescription,
  Paragraph,
  SectionHeading,
  useToast,
  FormField,
  Column,
  Row,
  Button,
  Text,
  Avatar,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { capitalize } from "lodash";
import { useNavigate, useParams } from "react-router-dom";

import { ColumnSettings } from "src/components/audiences/column-settings";
import { ParentTraits } from "src/components/audiences/parent-traits";
import { Relationships } from "src/components/audiences/relationships";
import { getSchemaModelType, getSchemaModelTypeNames } from "src/components/audiences/utils";
import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { SaveWarning } from "src/components/modals/save-warning";
import { ColumnSelect } from "src/components/models/column-select";
import { Query } from "src/components/models/query";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ObjectQuery,
  ResourcePermissionGrant,
  useDeleteObjectMutation,
  useObjectQuery,
  useUpdateAudiencesByParentIdMutation,
  useUpdateEventMutation,
  useUpdateObjectMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { modelColumnIgnoreFields, modelColumnMappers } from "src/pages/models/model-activity";
import { QueryType } from "src/types/models";
import { SchemaModelType } from "src/types/schema";
import { PageSpinner } from "src/ui/loading";
import { Message } from "src/ui/message";
import { Tabs } from "src/ui/tabs";
import { isTimestampColumn, useModelState } from "src/utils/models";
import { formatDate } from "src/utils/time";

import { MatchBoostingSettingsWrapper } from "./match-boosting";
import { parentModelActivityMappers } from "./parent-model-activity";

enum Tab {
  QUERY = "Query",
  COLUMNS = "Columns",
  RELATIONSHIPS = "Relationships",
  TRAITS = "Traits",
  ACTIVITY = "Activity",
  MATCH_BOOSTING = "Match boosting",
}

export const AudienceObject: FC = () => {
  const { id } = useParams<{ id?: string }>();

  const { data: object, isLoading } = useObjectQuery(
    {
      id: id ?? "",
    },
    {
      enabled: Boolean(id),
      notifyOnChangeProps: "tracked",
      select: (data) => data.segments_by_pk,
    },
  );

  if (!id || isLoading) {
    return <PageSpinner />;
  }

  if (!object) {
    return <Warning subtitle="This resource may have been deleted" title="Not found" />;
  }
  const type = getSchemaModelType(object);
  const { typePath, typeNameCapitalized } = getSchemaModelTypeNames(type);

  const objectCrumb = (
    <Row align="center">
      {typeNameCapitalized}:&nbsp;
      <Text color="text.secondary" size="lg">
        {object.name}
      </Text>
    </Row>
  );

  return (
    <>
      <Page
        crumbs={[
          {
            label: "Schema",
            link: `/schema/${typePath}`,
          },
          {
            label: objectCrumb,
          },
        ]}
        title={`${object.name} - ${typeNameCapitalized} - Audiences`}
      >
        <SchemaObject object={object} />
      </Page>
    </>
  );
};

export const SchemaObject: FC<Readonly<{ object: NonNullable<ObjectQuery["segments_by_pk"]> }>> = ({ object }) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();
  const type = getSchemaModelType(object);
  const { typeName, typePath } = getSchemaModelTypeNames(type);
  const { modelState, setName, setPrimaryKey, setTimestampColumn, initModelState } = useModelState();
  const { appMatchBoosting } = useFlags();

  const isParentModel = type === SchemaModelType.Parent;
  const isEvent = type === SchemaModelType.Event;

  const [updatingPrimaryKey, setUpdatingPrimaryKey] = useState<boolean>(false);
  const [updatingTimestamp, setUpdatingTimestamp] = useState<boolean>(false);
  const [updatingPrimaryLabel, setUpdatingPrimaryLabel] = useState<boolean>(false);
  const [updatingSecondaryLabel, setUpdatingSecondaryLabel] = useState<boolean>(false);
  const [deleteQuery, setDeleteQuery] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const [primaryLabel, setPrimaryLabel] = useState<string | undefined | null>("");
  const [secondaryLabel, setSecondaryLabel] = useState<string | undefined | null>("");
  const [description, setDescription] = useState(object.description ?? "");

  const queryType = object.query_type as QueryType;
  const columns = object.columns;
  const source = object.connection;

  const { mutateAsync: updateObject } = useUpdateObjectMutation();
  const { mutateAsync: updateEvent } = useUpdateEventMutation();
  const { mutateAsync: deleteObject } = useDeleteObjectMutation();

  const { mutateAsync: updateAudiencesByParentId } = useUpdateAudiencesByParentIdMutation();

  const trackUpdate = () => {
    analytics.track("Model Updated", {
      model_id: object.id,
      model_type: queryType,
      model_name: object.name,
      source_id: source?.id,
      source_type: source?.type,
    });
  };

  const updatePermission = useHasPermission([{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]);

  const saveName = async () => {
    try {
      await updateObject({
        id: object.id,
        input: {
          name: modelState.name,
          updated_by: user?.id != null ? String(user?.id) : undefined,
        },
      });
      toast({
        id: `update-name`,
        title: `Name was updated`,
        variant: "success",
      });

      trackUpdate();
    } catch (e) {
      toast({
        id: "update-name-error",
        title: "Failed to update name",
        message: e.message,
        variant: "error",
      });
    }
  };

  const saveDescription = async () => {
    try {
      await updateObject({
        id: object.id,
        input: {
          description,
          updated_by: user?.id != null ? String(user?.id) : undefined,
        },
      });
    } catch (e) {
      toast({
        id: "update-description-error",
        title: "Failed to update description",
        message: e.message,
        variant: "error",
      });
    }

    trackUpdate();
  };

  const updatePrimaryKey = async (value: string) => {
    setUpdatingPrimaryKey(true);
    setPrimaryKey(value);
    const oldPrimaryKey = object.primary_key;
    try {
      await updateObject({
        id: object.id,
        input: {
          primary_key: value,
          updated_by: user?.id != null ? String(user?.id) : undefined,
          visual_query_primary_label: primaryLabel,
          visual_query_secondary_label: secondaryLabel,
        },
      });
      if (isParentModel) {
        // Update the primary key for all the audiences associated with this parent model
        await updateAudiencesByParentId({
          parent_id: object.id,
          input: {
            primary_key: value,
          },
        });
      }
      toast({
        id: "update-primary-key",
        title: "Primary key updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-key-error",
        title: "Failed to update primary key",
        message: e.message,
        variant: "error",
      });
      setPrimaryKey(oldPrimaryKey ?? "");
    }
    setUpdatingPrimaryKey(false);
  };

  const updatePrimaryLabel = async (value: string) => {
    setUpdatingPrimaryLabel(true);
    setPrimaryLabel(value);
    const oldPrimaryLabel = object.visual_query_primary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          updated_by: user?.id != null ? String(user?.id) : undefined,
          visual_query_primary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setPrimaryLabel(oldPrimaryLabel);
    }
    setUpdatingPrimaryLabel(false);
  };

  const updateSecondaryLabel = async (value: string) => {
    setUpdatingSecondaryLabel(true);
    setSecondaryLabel(value);
    const oldSecondaryLabel = object.visual_query_secondary_label;
    try {
      await updateObject({
        id: object.id,
        input: {
          updated_by: user?.id != null ? String(user?.id) : undefined,
          visual_query_secondary_label: value,
        },
      });
      toast({
        id: "update-primary-key",
        title: "Primary label updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-primary-label-error",
        title: "Failed to update primary label",
        message: e.message,
        variant: "error",
      });
      setSecondaryLabel(oldSecondaryLabel);
    }
    setUpdatingSecondaryLabel(false);
  };

  const updateTimestamp = async (value: string) => {
    setUpdatingTimestamp(true);
    setTimestampColumn(value);
    const oldTimestamp = object.event?.timestamp_column;
    try {
      await updateEvent({
        id: object.id,
        set: {
          timestamp_column: modelState?.timestampColumn,
        },
      });

      toast({
        id: "update-timestamp",
        title: "Timestamp column updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-timestamp-error",
        title: "Failed to update timestamp column",
        message: e.message,
        variant: "error",
      });
      setTimestampColumn(oldTimestamp ?? "");
    }
    setUpdatingTimestamp(false);
  };

  const dirty =
    modelState?.name !== object.name ||
    modelState?.primaryKey !== object.primary_key ||
    modelState?.timestampColumn !== object.event?.timestamp_column ||
    primaryLabel !== object.visual_query_primary_label ||
    secondaryLabel !== object.visual_query_secondary_label;

  useEffect(() => {
    initModelState(object);
    setPrimaryLabel(object.visual_query_primary_label);
    setSecondaryLabel(object.visual_query_secondary_label);
  }, [object]);

  useEffect(() => {
    setDescription(object.description ?? "");
  }, [object.description]);

  const hasPrimaryKeyIssue = Boolean(
    isParentModel && object.columns?.length && !object.columns.some((c) => c.name === object.primary_key),
  );

  const updatedByUsername = object.updated_by_user?.name || object.created_by_user?.name;

  const matchBoostingTab = appMatchBoosting ? [Tab.MATCH_BOOSTING] : [];
  const TABS = isParentModel
    ? [Tab.QUERY, Tab.RELATIONSHIPS, Tab.TRAITS, Tab.COLUMNS, Tab.ACTIVITY, ...matchBoostingTab]
    : [Tab.QUERY, Tab.RELATIONSHIPS, Tab.COLUMNS];

  return (
    <>
      <PermissionProvider permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Update] }]}>
        <Row sx={{ width: "100%", flex: 1 }}>
          <Column sx={{ mr: 12, flex: 1 }}>
            <Column sx={{ mb: 6 }}>
              <Row sx={{ alignItems: "center", justifyContent: "space-between", gap: 8 }}>
                <EditableHeading
                  isDisabled={!updatePermission.hasPermission}
                  size="lg"
                  value={modelState.name}
                  onChange={setName}
                  onSubmit={saveName}
                />

                <Permission permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Delete] }]}>
                  <Button
                    onClick={() => {
                      setDeleteQuery(true);
                    }}
                  >
                    Delete
                  </Button>
                </Permission>
              </Row>
              <Row>
                <EditableDescription value={description} onChange={setDescription} onSubmit={saveDescription} />
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name} />
                  <Text size="lg" fontWeight="medium">
                    {source?.name}
                  </Text>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate((object.updated_at || object.created_at)!)}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={object.slug} />
                </Row>
              </DetailBar>
            </Column>

            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {hasPrimaryKeyIssue && (
              <Message sx={{ width: "100%", maxWidth: "100%", mb: 8 }} variant="warning">
                <FormField label="Looks like your primary key is set to an undefined column.">
                  As a result, your syncs may fail or undefined behavior may occur, go to the Configuration tab to select a new
                  primary key.
                </FormField>
              </Message>
            )}

            {tab === Tab.QUERY && (
              <Column gap={8}>
                <Query
                  model={object}
                  actions={
                    <Permission>
                      <Button onClick={() => navigate(`/schema/${typePath}/${object.id}/query`)}>Edit</Button>
                    </Permission>
                  }
                />

                {(isParentModel || isEvent) && (
                  <Column gap={6}>
                    <SectionHeading>Configuration</SectionHeading>
                    <Permission>
                      {isEvent && (
                        <FormField label="Timestamp">
                          <ColumnSelect
                            isLoading={updatingTimestamp}
                            columns={object.columns?.filter(isTimestampColumn)}
                            model={object}
                            value={modelState.timestampColumn}
                            onChange={updateTimestamp}
                          />
                        </FormField>
                      )}
                      {isParentModel && (
                        <>
                          <FormField
                            label="Primary key"
                            tip="Key that is unique and consistent across queries. E.g. “id” or “email”"
                          >
                            <ColumnSelect
                              isLoading={updatingPrimaryKey}
                              model={object}
                              value={modelState.primaryKey}
                              onChange={updatePrimaryKey}
                            />
                          </FormField>
                          <FormField
                            label="Primary label"
                            tip="Used when previewing results from an audience. E.g. “full_name”"
                          >
                            <ColumnSelect
                              isLoading={updatingPrimaryLabel}
                              model={object}
                              value={primaryLabel ?? ""}
                              onChange={updatePrimaryLabel}
                            />
                          </FormField>
                          <FormField label="Secondary label" tip="Used when previewing results from an audience. E.g. “email”">
                            <ColumnSelect
                              isLoading={updatingSecondaryLabel}
                              model={object}
                              value={secondaryLabel ?? ""}
                              onChange={updateSecondaryLabel}
                            />
                          </FormField>
                        </>
                      )}
                    </Permission>
                  </Column>
                )}
              </Column>
            )}

            {tab === Tab.ACTIVITY && (
              <ResourceActivityTimeline
                primaryResource={{
                  mappers: parentModelActivityMappers,
                  resourceNameOverride: "Parent Model",
                  resource: "Audience Schema",
                  resourceId: String(object.id),
                }}
                relatedResources={[
                  {
                    mappers: modelColumnMappers,
                    resource: "Model Column",
                    resourceNameOverride: "Model column",
                    resourceId: `model_id:${object.id}:%`,
                    ignoreColumns: modelColumnIgnoreFields,
                  },
                ]}
              />
            )}

            {tab === Tab.RELATIONSHIPS && <Relationships model={object} />}

            {tab === Tab.TRAITS && <ParentTraits model={object} />}

            {tab === Tab.COLUMNS && <ColumnSettings columns={columns} modelId={object.id} source={source} />}

            {tab === Tab.MATCH_BOOSTING && (
              <MatchBoostingSettingsWrapper columns={columns} model={object} segmentType="schema" />
            )}
          </Column>
        </Row>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText={`Delete ${typeName}`}
        isOpen={deleteQuery}
        title={`Delete ${typeName}`}
        variant="danger"
        onClose={() => {
          setDeleteQuery(false);
        }}
        onConfirm={async () => {
          await deleteObject({
            id: object.id,
          });

          analytics.track("Model Deleted", {
            model_id: object.id,
            model_type: queryType,
            model_name: object.name,
            source_id: source?.id,
            source_type: source?.type,
          });

          toast({
            id: `delete-${typePath}`,
            title: `${capitalize(typeName)} was deleted`,
            variant: "success",
          });

          navigate(`/schema/${typePath}`);
        }}
      >
        <Paragraph>Are you sure you want to delete this {typeName}? You won't be able to undo this.</Paragraph>
      </ConfirmationDialog>

      <SaveWarning dirty={dirty && !deleteQuery} />
    </>
  );
};
