import { FC } from "react";

import { IconProps, Icon } from "src/ui/icons/icon";

export const AudienceIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M15 6.2c0 1.767-1.343 3.2-3 3.2S9 7.967 9 6.2C9 4.433 10.343 3 12 3s3 1.433 3 3.2Zm1 9.6c0-2.356-1.79-4.267-4-4.267s-4 1.91-4 4.267V19h8v-3.2Zm4-7.467c0 1.178-.895 2.134-2 2.134s-2-.956-2-2.134.895-2.133 2-2.133 2 .955 2 2.133ZM18 19v-3.2a6.705 6.705 0 0 0-.75-3.1c.24-.065.491-.1.75-.1 1.657 0 3 1.433 3 3.2V19h-3ZM4 8.333c0 1.178.895 2.134 2 2.134s2-.956 2-2.134S7.105 6.2 6 6.2s-2 .955-2 2.133ZM6 19v-3.2c0-1.125.272-2.181.75-3.1-.24-.065-.491-.1-.75-.1-1.657 0-3 1.433-3 3.2V19h3Z" />
    </Icon>
  );
};

export const AudienceSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M15 6.2c0 1.767-1.343 3.2-3 3.2S9 7.967 9 6.2C9 4.433 10.343 3 12 3s3 1.433 3 3.2Zm1 9.6c0-2.356-1.79-4.267-4-4.267s-4 1.91-4 4.267V19h8v-3.2Zm4-7.467c0 1.178-.895 2.134-2 2.134s-2-.956-2-2.134.895-2.133 2-2.133 2 .955 2 2.133ZM18 19v-3.2a6.705 6.705 0 0 0-.75-3.1c.24-.065.491-.1.75-.1 1.657 0 3 1.433 3 3.2V19h-3ZM4 8.333c0 1.178.895 2.134 2 2.134s2-.956 2-2.134S7.105 6.2 6 6.2s-2 .955-2 2.133ZM6 19v-3.2c0-1.125.272-2.181.75-3.1-.24-.065-.491-.1-.75-.1-1.657 0-3 1.433-3 3.2V19h3Z" />
  </svg>
);
