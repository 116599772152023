import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const CubeIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M11.553 2.106a1 1 0 0 1 .894 0l8 4A1 1 0 0 1 21 7v10a1 1 0 0 1-.553.894l-8 4a1 1 0 0 1-.894 0l-8-4A1 1 0 0 1 3 17V7a1 1 0 0 1 .553-.894l8-4ZM5 8.618v7.764l6 3v-7.764l-6-3Zm8 3v7.764l6-3V8.618l-6 3ZM17.764 7 12 9.882 6.236 7 12 4.118 17.764 7Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export const CubeSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M11.553 2.106a1 1 0 0 1 .894 0l8 4A1 1 0 0 1 21 7v10a1 1 0 0 1-.553.894l-8 4a1 1 0 0 1-.894 0l-8-4A1 1 0 0 1 3 17V7a1 1 0 0 1 .553-.894l8-4ZM5 8.618v7.764l6 3v-7.764l-6-3Zm8 3v7.764l6-3V8.618l-6 3ZM17.764 7 12 9.882 6.236 7 12 4.118 17.764 7Z"
      clipRule="evenodd"
    />
  </svg>
);
