import { createContext, FC, ReactNode, useContext, useState } from "react";

import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import { DraftOperation, DraftsQuery, ModelDraft, ResourceToPermission, SyncDraft } from "src/graphql";
import { useDraftMerger } from "src/hooks/use-draft-merger";

type UpdateResourceOrDraftFn = (
  updatePayload: SyncDraft | ModelDraft,
  onUpdate: () => void,
  update: UpdateResourceFunc,
  resourceIsDraft: boolean,
) => Promise<void>;

type DraftProviderType = {
  draft: DraftsQuery["drafts"][0] | undefined;
  draftChange: SyncDraft | ModelDraft | undefined;
  refetch: () => void;
  setDraftChange: (change: SyncDraft | ModelDraft) => void;
  editingDraft: boolean;
  editingDraftChanges: boolean;
  setEditingDraft: (editing: boolean) => void;
  setSubmitDraftModalOpen: (open: boolean) => void;
  resourceType: ResourceToPermission | undefined;
  onViewDraft: () => void;
  updateResourceOrDraft: UpdateResourceOrDraftFn | undefined;
  mergeResourceWithDraft: (resource: Record<string, unknown>) => Record<string, unknown>;
};

const defaultDraftContext: DraftProviderType = {
  draft: undefined,
  refetch: () => undefined,
  draftChange: undefined,
  setDraftChange: () => undefined,
  editingDraft: false,
  editingDraftChanges: false,
  setEditingDraft: () => undefined,
  setSubmitDraftModalOpen: () => undefined,
  resourceType: undefined,
  onViewDraft: () => undefined,
  updateResourceOrDraft: undefined,
  mergeResourceWithDraft: (resource) => resource,
};

export const DraftContext = createContext<DraftProviderType>(defaultDraftContext);

type UpdateResourceFunc = () => Promise<void>;

interface Props {
  children: ReactNode;
  initialResourceIsDraft: boolean;
  resourceType: ResourceToPermission;
  resourceId: string | undefined;
  onPublish?: () => void;
}

export const DraftProvider: FC<Props> = ({ children, resourceType, resourceId, initialResourceIsDraft, onPublish }) => {
  const [draftChange, setDraftChange] = useState<SyncDraft | ModelDraft>();
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { workspace } = useUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const editingDraft = searchParams.get("editing") === "true";

  const { draft, mergeResourceWithDraft, refetch } = useDraftMerger({ resourceId: resourceId?.toString() ?? "", resourceType });

  const setEditingDraft = (editing: boolean) => {
    if (editing) {
      setSearchParams({
        editing: "true",
      });
    }

    if (!editing && searchParams.has("editing")) {
      setSearchParams({});
    }
  };

  const updateResourceOrDraft = async (
    updatePayload: SyncDraft | ModelDraft,
    onUpdate: () => void,
    update: UpdateResourceFunc,
    resourceIsDraft: boolean,
  ) => {
    if (!resourceId) {
      return;
    }

    if (!workspace?.approvals_required || resourceIsDraft) {
      await update();
      setEditingDraft(false);
      onUpdate();
      return;
    }

    // only set the draft change if we're currently editing - otherwise, we're going to show
    // the modal confirmation to delete the existing draft.
    if (draft && !editingDraft) {
      setDraftChange(updatePayload);
      return;
    }

    setEditingDraft(true);
    setDraftChange(updatePayload);
  };

  const onViewDraft = () => {
    // this can't happen - we can't view a draft with no resource id set.
    if (!resourceId) {
      return;
    }

    let base = location.pathname;
    if (!location.pathname.endsWith(resourceId)) {
      // We are within a nested route, so we need to remove the route suffix.
      // e.g. /models/100/query - we should navigate to /models/100/draft
      const parts = base.split("/");
      parts.pop();
      base = parts.join("/");
    }

    draft?.approval_requests.length === 0 ? setSubmitDraftModalOpen(true) : navigate(`${base}/draft`);
  };

  const customMergeResourceWithDraft = (resource: Readonly<Record<string, unknown>>) => {
    if (!editingDraft) {
      return resource;
    }

    return mergeResourceWithDraft(resource);
  };

  const editingDraftChanges = editingDraft && draft?.operation === DraftOperation.Update;

  return (
    <DraftContext.Provider
      value={{
        draft,
        refetch,
        setDraftChange,
        editingDraft,
        editingDraftChanges,
        setEditingDraft,
        setSubmitDraftModalOpen,
        resourceType,
        draftChange,
        onViewDraft,
        updateResourceOrDraft,
        mergeResourceWithDraft: customMergeResourceWithDraft,
      }}
    >
      <>
        {children}
        {workspace?.approvals_required && (
          <DraftSubmissionModal
            draft={draftChange}
            getResourceId={() => (resourceId ? String(resourceId) : "")}
            open={submitDraftModalOpen}
            operation={initialResourceIsDraft ? DraftOperation.Create : DraftOperation.Update}
            resource={resourceType}
            onClose={() => {
              setSubmitDraftModalOpen(false);
            }}
            onSubmit={(_resourceId: string, { publishNow }: { publishNow: boolean }) => {
              refetch();
              if (publishNow) {
                onPublish && onPublish();
              }
            }}
          />
        )}
      </>
    </DraftContext.Provider>
  );
};

export const useDraft = () => useContext(DraftContext);
