import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const TraitIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M12 2a.735.735 0 0 0-.36.093L3.392 6.708a.759.759 0 0 0-.287.283.784.784 0 0 0 .287 1.07l2.914 1.63-2.914 1.632a.76.76 0 0 0-.287.283.784.784 0 0 0 .287 1.07l2.914 1.63-2.914 1.632a.759.759 0 0 0-.287.283.784.784 0 0 0 .287 1.07l8.248 4.614a.735.735 0 0 0 .72 0l8.248-4.615a.76.76 0 0 0 .287-.282.784.784 0 0 0-.287-1.07l-2.914-1.631 2.914-1.632a.758.758 0 0 0 .287-.282.785.785 0 0 0-.287-1.07l-2.914-1.631 2.914-1.632a.759.759 0 0 0 .287-.282.784.784 0 0 0-.287-1.07L12.36 2.093A.735.735 0 0 0 12 2Zm0 1.647 6.68 3.737-2.916 1.632L12 11.122 5.32 7.384 12 3.647Zm-4.124 6.922 3.764 2.106a.735.735 0 0 0 .72 0l3.764-2.106L18.68 12l-2.915 1.632L12 15.737l-3.772-2.11L5.319 12l2.557-1.43Zm0 4.615 3.764 2.106a.736.736 0 0 0 .72 0l3.764-2.106 2.557 1.43L12 20.352l-6.68-3.738 2.556-1.43Z" />
    </Icon>
  );
};

export const TraitSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M12 2a.735.735 0 0 0-.36.093L3.392 6.708a.759.759 0 0 0-.287.283.784.784 0 0 0 .287 1.07l2.914 1.63-2.914 1.632a.76.76 0 0 0-.287.283.784.784 0 0 0 .287 1.07l2.914 1.63-2.914 1.632a.759.759 0 0 0-.287.283.784.784 0 0 0 .287 1.07l8.248 4.614a.735.735 0 0 0 .72 0l8.248-4.615a.76.76 0 0 0 .287-.282.784.784 0 0 0-.287-1.07l-2.914-1.631 2.914-1.632a.758.758 0 0 0 .287-.282.785.785 0 0 0-.287-1.07l-2.914-1.631 2.914-1.632a.759.759 0 0 0 .287-.282.784.784 0 0 0-.287-1.07L12.36 2.093A.735.735 0 0 0 12 2Zm0 1.647 6.68 3.737-2.916 1.632L12 11.122 5.32 7.384 12 3.647Zm-4.124 6.922 3.764 2.106a.735.735 0 0 0 .72 0l3.764-2.106L18.68 12l-2.915 1.632L12 15.737l-3.772-2.11L5.319 12l2.557-1.43Zm0 4.615 3.764 2.106a.736.736 0 0 0 .72 0l3.764-2.106 2.557 1.43L12 20.352l-6.68-3.738 2.556-1.43Z" />
  </svg>
);
