import { FC } from "react";

import { Row, Text } from "@hightouchio/ui";
import { capitalize } from "lodash";

import {
  ConditionType,
  EventCondition,
  FunnelCondition,
  TimestampOperator,
  Window,
  windowOperatorOptions,
  funnelWindowOperatorOptions,
  shouldResetValue,
  shouldResetTimeType,
  TimeType,
} from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { XButton } from "./condition-buttons";
import { Filter } from "./filter";
import { TimestampInput } from "./timestamp-input";
import { getTimeValue } from "./utils";

type Props = { eventName?: string } & (
  | {
      condition: EventCondition;
      onChange: (updates: Partial<EventCondition>) => void;
    }
  | {
      condition: FunnelCondition;
      onChange: (updates: Partial<FunnelCondition>) => void;
    }
);

export const WindowFilter: FC<Readonly<Props>> = ({ condition, onChange, eventName }) => {
  const { window } = condition;

  const isFunnelWindow = condition.type === ConditionType.Funnel;
  const operatorLabel = capitalize(windowOperatorOptions.find((option) => option.value === window?.operator)?.label);
  const formattedValue = getTimeValue(window?.value, window?.timeType, isFunnelWindow);

  const update = (updates: Partial<Window>) => {
    onChange({ window: { ...window!, ...updates } });
  };

  return (
    <Row align="center" pl={8}>
      <Filter
        content={
          <>
            <NewSelect
              options={isFunnelWindow ? funnelWindowOperatorOptions : windowOperatorOptions}
              placeholder="Filter on"
              sx={{ flex: "0 0 auto" }}
              value={window?.operator}
              width={200}
              onChange={(operator) => {
                if (shouldResetTimeType(window?.operator, operator)) {
                  update({ operator, timeType: TimeType.Relative, value: undefined });
                } else if (shouldResetValue(operator, window?.operator)) {
                  update({ operator, value: undefined });
                } else {
                  update({ operator });
                }
              }}
            />
            {window && (
              <TimestampInput condition={window} hideTime={false} showRelativeDirections={!isFunnelWindow} onChange={update} />
            )}
          </>
        }
        sx={{ mr: 2 }}
      >
        <Row>
          <Text color="text.secondary" mr={1}>
            {operatorLabel}
          </Text>

          {formattedValue && (
            <>
              {formattedValue}
              {eventName && (
                <>
                  <Text fontWeight="medium" ml={1}>
                    {window?.operator === TimestampOperator.Between ? "after" : "of"}
                  </Text>
                  <Text isTruncated ml={1} color="text.secondary" fontWeight="normal">
                    {eventName}
                  </Text>
                </>
              )}
            </>
          )}
        </Row>
      </Filter>
      <XButton onRemove={() => onChange({ window: null })} />
    </Row>
  );
};
