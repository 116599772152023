import { useEffect, useMemo, useState } from "react";

import { SparklesIcon } from "@heroicons/react/24/solid";
import { Column, Row, Text, Button, Pill } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { isEmpty } from "lodash";

import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Labels } from "src/components/labels/labels";
import { Query } from "src/components/models/query";
import { ResourceSelect } from "src/components/resource-select";
import { useDraft } from "src/contexts/draft-context";
import { SegmentsBoolExp, SegmentsOrderBy, useModelsAndAudiencesQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { InfoModal } from "src/ui/modal/info-modal";
import { TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { QueryTypeIcon } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";

enum SortKeys {
  Name = "name",
  UpdatedAt = "updated_at",
  QueryType = "query_type",
}

export const ModelSelect = ({ onSelect }) => {
  const [search, setSearch] = useState("");
  const [previewModel, setPreviewModel] = useState<any>();
  const { setEditingDraft } = useDraft();

  const { appDisableSyncCreationForAudiences } = useFlags();

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 200,
    sortOptions: Object.values(SortKeys),
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const filters: SegmentsBoolExp = {
      _and: [
        {
          is_schema: { _eq: false },
        },
      ],
    };
    if (search) {
      filters._and!.push({ name: { _ilike: `%${search}%` } });
    }
    return filters;
  }, [search]);

  const {
    data,
    error,
    isFetching: loading,
  } = useModelsAndAudiencesQuery(
    {
      offset,
      limit,
      filters,
      orderBy,
    },
    { keepPreviousData: true },
  );

  const models = data?.segments.filter((model) => {
    if (appDisableSyncCreationForAudiences) {
      return !model.visual_query_filter;
    }

    return true;
  });
  const count = data?.segments_aggregate?.aggregate?.count;

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.Name),
        cell: ({ name, connection: source, query_type, query_runs, matchboosting_enabled, parent }) => {
          return (
            <Row gap={3} overflow="hidden" width="100%" align="center">
              <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name} />
              <Column gap={1} overflow="hidden">
                <Text isTruncated fontWeight="medium">
                  {name || "Private model"}
                </Text>
                <Row gap={2}>
                  <Pill>
                    <Row gap={2} align="center">
                      <QueryTypeIcon type={query_type} />
                      {query_runs?.[0] ? `${abbreviateNumber(query_runs?.[0]?.size)} rows` : "Unknown size"}
                    </Row>
                  </Pill>
                  {(matchboosting_enabled || parent?.matchboosting_enabled) && (
                    <Pill>
                      <Row gap={2} align="center">
                        <SparklesIcon color="#F5C24D" width={16} />
                        Boosted
                      </Row>
                    </Pill>
                  )}
                </Row>
              </Column>
            </Row>
          );
        },
      },
      {
        name: "Labels",
        key: "tags",
        max: ".5fr",
        cell: (tags) => {
          if (isEmpty(tags)) {
            return "--";
          }
          return <Labels labels={tags} />;
        },
        breakpoint: "md",
      },
      {
        ...LastUpdatedColumn,
        max: "max-content",
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.UpdatedAt),
        breakpoint: "md",
      },
      {
        name: "",
        max: "max-content",
        cell: (model) =>
          model.query_type !== QueryType.Visual ? (
            <Button size="sm" onClick={() => setPreviewModel(model)}>
              View query
            </Button>
          ) : null,
      },
    ],
    [models],
  );

  useEffect(() => {
    setEditingDraft(true);
  }, []);

  useEffect(() => {
    setPage(0);
  }, [filters]);

  return (
    <>
      <ResourceSelect
        columns={columns}
        data={models}
        error={Boolean(error)}
        label="model"
        loading={loading}
        pagination={{ count, page, setPage, limit }}
        search={search}
        onSearch={setSearch}
        onSelect={onSelect}
      />
      <InfoModal
        height="50%"
        isOpen={Boolean(previewModel)}
        title={previewModel?.name}
        width="50%"
        onClose={() => setPreviewModel(undefined)}
      >
        <Query model={previewModel} />
      </InfoModal>
    </>
  );
};
