import { FC, ReactNode } from "react";

import { Box, Column, Menu, MenuActionsButton, MenuItem, MenuList, Row, Text } from "@hightouchio/ui";

import { ConditionType } from "src/types/visual";
import { CopySVG, DescriptionAddSVG, TraitSVG } from "src/ui/icons/new-icons";

import { AndOrToggleButton, DeleteButton, TextButton } from "./condition-buttons";
import { GroupIndicatorBar } from "./group-indicator-bar";

interface ConditionWrapperProps {
  children: ReactNode;
  conditionType: ConditionType.And | ConditionType.Or;
  disableGrouping?: boolean;
  onAddTrait?: () => void;
  onChange: (conditionType: ConditionType.And | ConditionType.Or) => void;
  onDuplicate: () => void;
  onGroup: () => void;
  onUngroup: (() => void) | undefined;
  onRemove: () => void;
}

export const ConditionWrapper: FC<Readonly<ConditionWrapperProps>> = ({
  children,
  conditionType,
  disableGrouping = false,
  onAddTrait,
  onChange,
  onDuplicate,
  onGroup,
  onRemove,
  onUngroup,
}) => {
  return (
    <Row width="100%">
      <Column flex={1} minWidth={0}>
        <Row flex={1} minWidth={0} mb={4}>
          <GroupIndicatorBar conditionType={conditionType} />

          <Row
            flex={1}
            minWidth="max-content"
            bg="white"
            borderBottomWidth="1px"
            borderRightWidth="1px"
            borderTopWidth="1px"
            borderBottomRightRadius="md"
            borderTopRightRadius="md"
            borderColor="base.border"
            boxShadow="xs"
            flexBasis="fit-content"
            gap={2}
            justify="space-between"
            p={4}
            position="relative"
          >
            <Column
              flex="1 0 auto"
              minWidth={0}
              sx={{
                "& > :not(:last-child)": { mb: 4 },
              }}
            >
              {children}
            </Column>

            <Row align="center" justify="center" gap={2} flexShrink={0}>
              <Menu>
                <MenuActionsButton />

                <MenuList>
                  <Box
                    as={MenuItem}
                    color="text.secondary"
                    icon={DescriptionAddSVG}
                    isDisabled={disableGrouping}
                    onClick={onGroup}
                  >
                    <Column>
                      <Text fontWeight="medium">Add {conditionType === ConditionType.And ? '"OR"' : '"AND"'} group</Text>
                      <Text color="text.secondary">
                        Create a nested {conditionType === ConditionType.And ? '"OR"' : '"AND"'} group from this filter
                      </Text>
                    </Column>
                  </Box>
                  <Box as={MenuItem} color="text.secondary" icon={CopySVG} onClick={onDuplicate}>
                    <Column>
                      <Text fontWeight="medium">Duplicate filter</Text>
                      <Text color="text.secondary">Create a copy of this filter</Text>
                    </Column>
                  </Box>
                  {onAddTrait && (
                    <Box as={MenuItem} color="text.secondary" icon={TraitSVG} onClick={onAddTrait}>
                      <Column>
                        <Text fontWeight="medium">Add trait</Text>
                        <Text color="text.secondary">Create a new custom trait based on this filter</Text>
                      </Column>
                    </Box>
                  )}
                </MenuList>
              </Menu>

              <DeleteButton onRemove={onRemove} />
            </Row>
          </Row>
        </Row>

        <Row gap={2}>
          <AndOrToggleButton
            mb={4}
            conditionType={conditionType}
            onClick={() => onChange(conditionType === ConditionType.And ? ConditionType.Or : ConditionType.And)}
          />

          {onUngroup && <TextButton onClick={onUngroup}>Ungroup</TextButton>}
        </Row>
      </Column>
    </Row>
  );
};
