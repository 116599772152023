import { FC, ReactNode, useMemo, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/solid";
import { Box, Column, IconButton, Row, Text } from "@hightouchio/ui";
import { format as formatDate } from "date-fns";
import { merge, sortBy } from "lodash";
import { format } from "numerable";
import {
  Brush,
  CartesianGrid,
  Label,
  Legend,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from "recharts";
import { isPresent } from "ts-extras";

import { LightningBoltIcon } from "src/ui/icons/new-icons";

export enum GoalColumn {
  Revenue = "revenue",
  AverageOrderSize = "averageOrderSize",
  NumberOfSignUps = "numSignUps",
}

const colors = ["#4FC26B", "#7958CD", "#E49A38", "#349AC6", "#D25046"];
const backgroundColors = ["#ECFEF5", "#EEE9FB", "#FEF9EC", "#EBFCFF", "#FFECEB"];

const valueFormatter = (value: number, graphKey: GoalColumn) => {
  if (graphKey === GoalColumn.Revenue) {
    return `$${format(value, "0.0a")}`;
  }

  return format(value, "0,0a");
};

const sharedStyles = {
  stroke: "#9AA6B2",
  fontFamily: "Inter",
  fontWeight: 600,
  letterSpacing: "0.03em",
};

type Audience = {
  id: number;
  name: string;
  revenueData: { [id: number]: number; date: string }[];
  averageOrderSizeData: { [id: number]: number; date: string }[];
  numberOfSignUpsPerDay: { [id: number]: number; date: string }[];
  changeEvents?: { label: string; date: string }[];
};
type PerformangeGraphProps = {
  audiences?: Audience[];
  graphKey: GoalColumn;

  onRemove: (number: number) => void;
};
type LegendContentProps = {
  audiences: Audience[];
  payload: any; // recharts data
  onRemove(index: number): void;
};

export const PerformanceGraph: FC<PerformangeGraphProps> = ({ audiences = [], graphKey, onRemove }) => {
  const [hoveredDate, setHoveredDate] = useState<string | null>(null);
  const revenueData = audiences.map(({ revenueData }) => revenueData);
  const averageOrderSizeData = audiences.map(({ averageOrderSizeData }) => averageOrderSizeData);
  const signUpsPerDay = audiences.map(({ numberOfSignUpsPerDay }) => numberOfSignUpsPerDay);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore not a tuple type but it's fake data
  const revenue = merge(...revenueData);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore not a tuple type but it's fake data
  const averageOrderSize = merge(...averageOrderSizeData);
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore not a tuple type but it's fake data
  const signUps = merge(...signUpsPerDay);

  let data: { [id: number]: number; date: string }[] = [];

  if (graphKey === GoalColumn.Revenue) {
    data = revenue;
  } else if (graphKey === GoalColumn.AverageOrderSize) {
    data = averageOrderSize;
  } else if (graphKey === GoalColumn.NumberOfSignUps) {
    data = signUps;
  }

  return (
    <ResponsiveContainer height="100%" minHeight="300px" width="100%">
      <LineChart
        data={data}
        margin={{
          top: 5,
          bottom: 5,
          right: 35,
          left: 30,
        }}
      >
        <CartesianGrid vertical={false} stroke="#E5E9ED" />
        <XAxis
          axisLine={{ stroke: "#E5E9ED" }}
          dataKey="date"
          minTickGap={50}
          tickFormatter={(timestamp: string) => formatDate(new Date(timestamp), "LLL d").toUpperCase()}
          tickLine={false}
          {...sharedStyles}
        />
        <YAxis axisLine={false} tickFormatter={(value) => valueFormatter(value, graphKey)} tickLine={false} {...sharedStyles} />

        <Tooltip active content={<TooltipContent audiences={audiences} />} cursor={{ strokeWidth: 2, stroke: "#252D36" }} />
        {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
        {/* @ts-ignore - Legend `content` has an incorrect type here. Something to deal with later. */}
        <Legend audiences={audiences} content={LegendContent} onRemove={(index) => onRemove(audiences[index]?.id ?? -1)} />
        {audiences.map(({ id, changeEvents = [] }, index) => (
          <>
            <Line key={id} dataKey={id} dot={false} stroke={colors[index % colors.length]} strokeWidth={2} type="linear" />
            {changeEvents.map(({ label, date }) => (
              <ReferenceLine
                key={label}
                x={date}
                stroke="#252D36"
                onMouseEnter={() => setHoveredDate(date)}
                onMouseLeave={() => setHoveredDate(null)}
              >
                <Label
                  content={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore - Legend `content` has an incorrect type here. Something to deal with later.
                    <EventContent
                      isHovered={hoveredDate === date}
                      onMouseEnter={() => setHoveredDate(date)}
                      onMouseLeave={() => setHoveredDate(null)}
                    />
                  }
                />
              </ReferenceLine>
            ))}
          </>
        ))}
        <Brush tickFormatter={(index) => formatDate(new Date(data[index]?.date || new Date()), "LLL d")}>
          <LineChart data={data}>
            <Line dataKey={audiences[0]?.id} dot={false} stroke="#9AA6B2" type="linear" />
          </LineChart>
        </Brush>
      </LineChart>
    </ResponsiveContainer>
  );
};

const TooltipContent = ({ active, audiences, label, payload }: TooltipProps<number, string> & { audiences: any[] }) => {
  const payloadData = payload?.[0];

  const changeEvents = useMemo(() => {
    return audiences.flatMap(({ changeEvents }) => changeEvents).filter(isPresent);
  }, []);

  // get data and sort in descending order
  const audienceData = useMemo(() => {
    if (!payloadData) {
      return audiences;
    }

    let data = audiences.map(({ id, name }, index) => {
      return {
        id,
        name,
        data: payloadData.payload[id],
        originalIndex: index,
      };
    });

    data = sortBy(data, "data", "desc");

    return data;
  }, [audiences, payloadData]);

  if (!active || !payloadData) {
    return null;
  }

  const xValue = payloadData.payload.date;

  const showInfoBox = changeEvents.some(({ date }) => date === xValue);
  const dateString = label ?? payloadData?.payload.value;

  return (
    <Column
      bg="white"
      border="1px solid"
      borderColor="#DBE1E8"
      borderRadius="8px"
      maxWidth="400px"
      minWidth="220px"
      mr={3}
      px={3}
      py={2}
      zIndex={2000}
    >
      <Text fontWeight="semibold" mb={2}>
        {formatDate(new Date(dateString), "LLL d, yyyy").toUpperCase().toUpperCase()}
      </Text>

      {showInfoBox && (
        <Row
          alignItems="center"
          display="flex"
          gap={3}
          color="text.secondary"
          bg="gray.background"
          borderWidth={1}
          borderColor="gray.border"
          borderRadius="lg"
          mb={2}
          p={2}
        >
          <LightningBoltIcon size={20} />
          <Column ml={-1}>
            <Text color="text.tertiary">Adults ages 22 - 34</Text>
            <Text>Marketing Email Campaign - Buy one get one 50% off</Text>
          </Column>
        </Row>
      )}

      {audienceData.map(({ id, name, data, originalIndex }) => {
        return (
          <Column key={id} mb={1}>
            <Text color="#697786">{name}</Text>

            <Box bg={backgroundColors[originalIndex % colors.length]} borderRadius="36px" mt={1} px={2} width="fit-content">
              <Text color={colors[originalIndex % colors.length]}>{format(data, "0,0")}</Text>
            </Box>
          </Column>
        );
      })}
    </Column>
  );
};

const LegendContent = ({ audiences, payload, onRemove }: LegendContentProps): ReactNode => {
  return (
    <Row as="ul" flexWrap="wrap" justifyContent="center" listStyleType="none">
      {payload.map((_, index) => (
        <Row key={`item-${index}`} alignItems="center" as="li" mx={2}>
          <Box bg={colors[index % colors.length]} borderRadius="50%" height="16px" mr={2} width="16px" />
          <Text color={colors[index % colors.length]}>{audiences[index]?.name || "--"}</Text>
          <IconButton aria-label="Remove condition." icon={XMarkIcon} ml={2} onClick={() => onRemove(index)} />
        </Row>
      ))}
    </Row>
  );
};

type EventContentProps = {
  viewBox: { x: number; y: number; width: number; height: number };
  parentViewBox: boolean;
  offset: number;
  isHovered?: boolean;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
};
const EventContent = (props: EventContentProps) => {
  const { viewBox, offset, isHovered, onMouseEnter, onMouseLeave } = props;

  const { x, y, width, height } = viewBox;

  // Define vertical offsets and position inverts based on the value being positive or negative
  const verticalSign = height >= 0 ? 1 : -1;
  const verticalOffset = verticalSign * offset;
  const verticalEnd = verticalSign > 0 ? "end" : "start";

  const style = {
    x: x + width / 2 - 9.5,
    y: y + height - verticalOffset - 5,
    textAnchor: "middle",
    verticalAnchor: verticalEnd,
  };
  return (
    <svg
      height={22}
      fill="#fff"
      width={22}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      {...style}
    >
      <circle cx="10" cy="10" r="9" stroke={isHovered ? "#252D36" : "#E5E9ED"} strokeWidth={isHovered ? "2px" : "1px"} />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.785 5.901a.5.5 0 0 1 .34.474v2.5h1.5a.5.5 0 0 1 .397.804l-3.25 4.25a.5.5 0 0 1-.897-.304v-2.5h-1.5a.5.5 0 0 1-.397-.804l3.25-4.25a.5.5 0 0 1 .557-.17Zm-2.398 4.224h.988a.5.5 0 0 1 .5.5v1.523l1.738-2.273h-.988a.5.5 0 0 1-.5-.5V7.852l-1.738 2.273Z"
        clipRule="evenodd"
      />
    </svg>
  );
};
