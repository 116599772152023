import { FC, useMemo, useEffect } from "react";

import { useFlags } from "launchdarkly-react-client-sdk";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { SegmentSetCondition, segmentSetOperatorOptions } from "src/types/visual";

import { FilterPopover } from "../filter-popover";
import { AttributeSelect } from "./attribute-select";
import { HStack, FilterProps } from "./condition";
import { validateSegmentSetCondition } from "./condition-validation";

export const SegmentSetFilter: FC<Readonly<FilterProps<SegmentSetCondition>>> = ({ condition, onChange, ...props }) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const filterId = useMemo<string>(uuidv4, []);

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const modelError = filterErrors?.modelId;
  const includesError = filterErrors?.includes;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validateSegmentSetCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [appAudienceQueryBuilderValidation, condition.modelId, condition.includes, filterId]);

  return (
    <HStack gap={2} sx={{ alignItems: "flex-start" }}>
      <AttributeSelect
        variant="secondary"
        error={includesError}
        options={segmentSetOperatorOptions}
        searchable={false}
        value={condition.includes}
        onChange={(value) => onChange({ includes: Boolean(value) })}
      />
      <FilterPopover {...props} condition={condition} hasError={Boolean(modelError)} onChange={onChange} />
    </HStack>
  );
};
