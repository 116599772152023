import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const SuggestionsIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props} width="16" height="16" viewBox="0 0 16 16">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.33374 9.33335C9.75287 8.77579 10 8.08421 10 7.33335C10 5.4924 8.50762 4.00002 6.66667 4.00002C4.82572 4.00002 3.33333 5.4924 3.33333 7.33335C3.33333 8.08421 3.58046 8.77579 3.99959 9.33335H9.33374ZM9.93266 10.6667C10.797 9.81968 11.3333 8.63915 11.3333 7.33335C11.3333 4.75602 9.244 2.66669 6.66667 2.66669C4.08934 2.66669 2 4.75602 2 7.33335C2 8.63915 2.53631 9.81968 3.40068 10.6667H9.93266Z"
        fill="#697786"
      />
      <path d="M4.66667 11.3334H8.66667V12.6667H4.66667V11.3334Z" fill="#697786" />
      <path
        d="M5.33333 13.3334H8C8 14.0697 7.40305 14.6667 6.66667 14.6667C5.93029 14.6667 5.33333 14.0697 5.33333 13.3334Z"
        fill="#697786"
      />
      <path
        d="M13.6667 5.33335L13.9368 6.06324L14.6667 6.33335L13.9368 6.60347L13.6667 7.33335L13.3966 6.60347L12.6667 6.33335L13.3966 6.06324L13.6667 5.33335Z"
        fill="#697786"
      />
      <path
        d="M12 0.666687L12.5402 2.12646L14 2.66669L12.5402 3.20692L12 4.66669L11.4598 3.20692L10 2.66669L11.4598 2.12646L12 0.666687Z"
        fill="#697786"
      />
    </Icon>
  );
};
