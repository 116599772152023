import { FC, MouseEvent, ReactElement, ReactNode, useCallback } from "react";

import { Text, Box, BoxProps } from "@hightouchio/ui";
import { get } from "lodash";

import { OrderBy } from "src/graphql";

import { SortIcon } from "./sortIcon";
import { TableColumn } from "./table";

export type HeaderCell = {
  children: ReactNode;
  onClick?: (event: MouseEvent) => void;
  sortDirection?: OrderBy | null;
  top?: number | string;
  isSortMenu?: boolean;
};

export type TableCellProps<Data> = {
  column: TableColumn;
  row: Data;
  onClick?: (row: any, event: MouseEvent) => void;
  height?: string;
};

export const Cell: FC<Readonly<{ children?: ReactNode; onClick?: (event: MouseEvent) => void } & BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box as="td" display="flex" alignItems="center" px={4} borderBottom="1px solid" borderColor="gray.100" {...props}>
      {typeof children === "string" ? <Text isTruncated>{children}</Text> : children}
    </Box>
  );
};

export function TableCell<Data>({
  column: { key, cell, defaultValue = "", divider, disabled, whitespace },
  row,
  height,
}: Readonly<TableCellProps<Data>>): ReactElement {
  const value = key ? get(row, key, defaultValue) : row;

  const handleClick = useCallback(
    (event: MouseEvent) => {
      if (disabled) {
        event.stopPropagation();
      }
    },
    [disabled],
  );

  return (
    <Cell
      onClick={handleClick}
      borderLeft={divider ? "1px solid var(--chakra-colors-border-base)" : undefined}
      height={height}
      whiteSpace={whitespace || "nowrap"}
    >
      {cell ? cell(value) : String(value)}
    </Cell>
  );
}

export const HeaderCell: FC<HeaderCell> = ({ children, onClick, sortDirection, top, isSortMenu, ...props }) => {
  return (
    <Box
      as="th"
      px={isSortMenu ? 0 : 4}
      alignItems="center"
      display="flex"
      flexWrap="nowrap"
      whiteSpace="nowrap"
      fontWeight="semibold"
      bg="white"
      fontSize="sm"
      py={3}
      pos={typeof top !== "undefined" ? "sticky" : undefined}
      zIndex={98}
      top={top}
      color="text.secondary"
      textTransform="uppercase"
      userSelect="none"
      borderBottom="2px solid"
      borderColor="base.border"
      sx={{
        "&:first-child": {
          pl: 8,
        },
        "&:last-child": {
          pr: isSortMenu ? 0 : 8,
        },
        "&:hover > .sort-icon": {
          transition: "background fill stroke 150ms ease-in",
          svg: {
            fill: "text.secondary",
          },
        },

        cursor: onClick ? "pointer" : "auto",
      }}
      onClick={onClick}
      {...props}
    >
      {children}
      {onClick && <SortIcon className="sort-icon" sortDirection={sortDirection} />}
    </Box>
  );
};
