import { FC, useEffect, useMemo } from "react";

import { TimeType } from "@hightouch/lib/query/visual/types";
import { Row, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { uniq } from "lodash";
import { v4 as uuidv4 } from "uuid";

import { useFormErrorContext } from "src/contexts/form-error-context";
import { useColumnSuggestionsQuery } from "src/graphql";
import {
  DefaultOperators,
  isColumnReference,
  isRelatedColumn,
  isTraitColumn,
  OperatorOptions,
  PropertyCondition,
  shouldResetTimeType,
  shouldResetValue,
} from "src/types/visual";
import { NewSelect } from "src/ui/new-select";

import { useQueryBuilderContext } from "../context/query-builder-context";
import { FilterPopover } from "../filter-popover/filter-popover";
import { AttributeSelect } from "./attribute-select";
import { FilterProps, HStack } from "./condition";
import { XButton } from "./condition-buttons";
import { validatePropertyCondition } from "./condition-validation";
import { Filter } from "./filter";
import { PropertyInput } from "./property-input";
import { TraitFilter } from "./trait-filter";
import { formatValue, getColumnOptions, getModelIdFromColumn, getPropertyNameFromColumn, getTraitOptions } from "./utils";

export type PropertyFilterProps = FilterProps<PropertyCondition> & {
  prefix?: boolean;
  showSecondaryFilter?: boolean;
};

export const PropertyFilter: FC<Readonly<PropertyFilterProps>> = ({ showSecondaryFilter = false, ...props }) => {
  const { appAudienceQueryBuilderValidation } = useFlags();
  const { columns: columnsOverride, traits: traitsOverride, condition, onChange, onRemove, parent } = props;
  const filterId = useMemo<string>(uuidv4, []);

  const { columns: allColumns, traits: allTraits } = useQueryBuilderContext();
  const columns = columnsOverride ?? allColumns;
  const traits = traitsOverride ?? allTraits;

  const { getErrors, setFieldError, removeErrors } = useFormErrorContext();

  const filterErrors = getErrors(filterId);
  const propertyError = filterErrors?.property;
  const operatorError = filterErrors?.operator;
  const valueError = filterErrors?.value;

  useEffect(() => {
    if (appAudienceQueryBuilderValidation) {
      setFieldError(filterId, validatePropertyCondition(condition));
    } else {
      removeErrors([filterId]);
    }

    return () => {
      removeErrors([filterId]);
    };
  }, [
    appAudienceQueryBuilderValidation,
    condition.property,
    condition.operator,
    condition.value,
    filterId,
    condition.propertyOptions,
  ]);

  const isDeprecatedPropertyCondition = !isColumnReference(condition.property);

  const modelId = isColumnReference(condition.property) ? getModelIdFromColumn(condition.property) : parent?.id;
  const columnName = isColumnReference(condition.property) ? getPropertyNameFromColumn(condition.property) : condition.property;

  const { data: columnSuggestionsData, isLoading: loadingSuggestions } = useColumnSuggestionsQuery(
    {
      modelIds: [String(modelId)],
      columnNames: [columnName],
    },
    {
      enabled: modelId !== null && columnName !== null,
    },
  );

  const suggestions = columnSuggestionsData?.getTopK?.columns?.find?.(
    (column) => column.modelId === String(modelId) && column.name === columnName,
  )?.values;

  const mergedModels = uniq([
    ...(columns?.map(({ model_name }) => model_name) || []),
    ...(traits?.map((trait) => trait.relationship.to_model.name) || []),
  ]);
  const isTrait = isRelatedColumn(condition.property) && isTraitColumn(condition.property.column);

  const propertyOptions =
    mergedModels.length > 1
      ? mergedModels.map((model) => {
          const modelColumns = columns?.filter(({ model_name }) => model_name === model);
          const traitsForModel = traits?.filter((trait) => trait.relationship.to_model.name === model);
          const traitOptions = getTraitOptions(condition.property, traitsForModel);
          return {
            label: model === parent?.name ? "Properties" : model,
            options: [...traitOptions, ...getColumnOptions(modelColumns ?? [], isDeprecatedPropertyCondition)],
          };
        })
      : [...getTraitOptions(condition.property, traits), ...getColumnOptions(columns ?? [], isDeprecatedPropertyCondition)];

  const connectionType = props.audience?.connection?.definition.type;
  const operatorOptions = condition.propertyType
    ? OperatorOptions[condition.propertyType].filter(
        (op) => !("supportedSources" in op) || op.supportedSources.includes(connectionType),
      )
    : undefined;
  const operatorLabel = operatorOptions?.find((option) => option.value === condition.operator)?.label;
  const formattedValue = formatValue(condition, condition.value);

  return (
    <>
      <HStack gap={2} sx={{ alignItems: "flex-start" }}>
        {showSecondaryFilter ? (
          <AttributeSelect
            disabled={loadingSuggestions}
            error={propertyError}
            loading={loadingSuggestions}
            options={propertyOptions}
            placeholder="Select a property"
            sx={{ flexShrink: 0, borderColor: propertyError ? "red" : undefined }}
            value={condition.property}
            onChange={(value, { type, case_sensitive }) => {
              onChange({
                propertyType: type,
                property: value,
                propertyOptions: {
                  caseSensitive: case_sensitive,
                },
                operator: DefaultOperators[type],
                value: null,
              });
            }}
          />
        ) : (
          <>
            <Text color="text.secondary" mt={1.5} ml={4}>
              {isTrait ? "Has custom trait" : "Has property"}
            </Text>
            <FilterPopover
              {...props}
              condition={condition}
              isDisabled={loadingSuggestions}
              hasError={Boolean(propertyError)}
              onChange={onChange}
            />
          </>
        )}

        {condition.property && (
          <Filter
            content={
              <Row sx={{ alignItems: "flex-start", gap: "8px" }}>
                <NewSelect
                  error={Boolean(operatorError)}
                  options={operatorOptions}
                  placeholder="Filter on"
                  sx={{ flex: "0 0 auto" }}
                  value={condition.operator}
                  onChange={(value) => {
                    if (shouldResetTimeType(condition.operator, value)) {
                      onChange({ operator: value, timeType: TimeType.Relative, value: null });
                    } else if (shouldResetValue(condition.operator, value)) {
                      onChange({ operator: value, value: null });
                    } else {
                      onChange({ operator: value });
                    }
                  }}
                />
                <PropertyInput {...props} error={valueError} loading={loadingSuggestions} suggestions={suggestions} />
              </Row>
            }
            error={operatorError || valueError}
          >
            <Row gap={1} overflow="hidden">
              <Text color="text.secondary">{operatorLabel}</Text> <Text fontWeight="medium">{formattedValue}</Text>
            </Row>
          </Filter>
        )}
        {onRemove && <XButton onRemove={onRemove} />}
      </HStack>
      {isTrait && <TraitFilter {...props} condition={condition} />}
    </>
  );
};
