import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const DescriptionAddIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M21.02 5H19V2.98C19 2.44 18.56 2 18.02 2H17.99C17.44 2 17 2.44 17 2.98V5H14.99C14.45 5 14.01 5.44 14 5.98V6.01C14 6.56 14.44 7 14.99 7H17V9.01C17 9.55 17.44 10 17.99 9.99H18.02C18.56 9.99 19 9.55 19 9.01V7H21.02C21.56 7 22 6.56 22 6.02V5.98C22 5.44 21.56 5 21.02 5Z" />
      <path d="M18 12C17.45 12 17 12.45 17 13V18.22C17 18.77 16.55 19.22 16 19.22H6C5.45 19.22 5 18.77 5 18.22V8C5 7.45 5.45 7 6 7H11C11.55 7 12 6.55 12 6C12 5.45 11.55 5 11 5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V13C19 12.45 18.55 12 18 12Z" />
      <path d="M7 10C7 9.45 7.45 9 8 9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11H8C7.45 11 7 10.55 7 10Z" />
      <path d="M8 12C7.45 12 7 12.45 7 13C7 13.55 7.45 14 8 14H14C14.55 14 15 13.55 15 13C15 12.45 14.55 12 14 12H8Z" />
      <path d="M7 16C7 15.45 7.45 15 8 15H14C14.55 15 15 15.45 15 16C15 16.55 14.55 17 14 17H8C7.45 17 7 16.55 7 16Z" />
    </Icon>
  );
};

export const DescriptionAddSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.02 5H19V2.98C19 2.44 18.56 2 18.02 2H17.99C17.44 2 17 2.44 17 2.98V5H14.99C14.45 5 14.01 5.44 14 5.98V6.01C14 6.56 14.44 7 14.99 7H17V9.01C17 9.55 17.44 10 17.99 9.99H18.02C18.56 9.99 19 9.55 19 9.01V7H21.02C21.56 7 22 6.56 22 6.02V5.98C22 5.44 21.56 5 21.02 5Z" />
    <path d="M18 12C17.45 12 17 12.45 17 13V18.22C17 18.77 16.55 19.22 16 19.22H6C5.45 19.22 5 18.77 5 18.22V8C5 7.45 5.45 7 6 7H11C11.55 7 12 6.55 12 6C12 5.45 11.55 5 11 5H5C3.9 5 3 5.9 3 7V19C3 20.1 3.9 21 5 21H17C18.1 21 19 20.1 19 19V13C19 12.45 18.55 12 18 12Z" />
    <path d="M7 10C7 9.45 7.45 9 8 9H14C14.55 9 15 9.45 15 10C15 10.55 14.55 11 14 11H8C7.45 11 7 10.55 7 10Z" />
    <path d="M8 12C7.45 12 7 12.45 7 13C7 13.55 7.45 14 8 14H14C14.55 14 15 13.55 15 13C15 12.45 14.55 12 14 12H8Z" />
    <path d="M7 16C7 15.45 7.45 15 8 15H14C14.55 15 15 15.45 15 16C15 16.55 14.55 17 14 17H8C7.45 17 7 16.55 7 16Z" />
  </svg>
);
