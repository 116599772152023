import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const MergeIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M2.654 3.317a.542.542 0 0 1 .759.108L5.2 5.808a1.625 1.625 0 0 0 1.3.65v1.084h-.011a1.625 1.625 0 0 0-1.289.65l-1.787 2.383a.542.542 0 0 1-.867-.65l1.787-2.383c.156-.208.339-.39.542-.542a2.707 2.707 0 0 1-.542-.542L2.546 4.075a.542.542 0 0 1 .108-.758ZM8.32 5.791a.542.542 0 0 1 .693-.832l2.163 1.621a.54.54 0 0 1 .008.833l-.005.005L9.012 9.04a.542.542 0 1 1-.693-.832l1.017-.667H6.5V6.458h2.837L8.32 5.791Z" />
    </Icon>
  );
};

export const MergeSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="14" viewBox="0 0 13 14" fill="currentColor">
    <path d="M2.654 3.317a.542.542 0 0 1 .759.108L5.2 5.808a1.625 1.625 0 0 0 1.3.65v1.084h-.011a1.625 1.625 0 0 0-1.289.65l-1.787 2.383a.542.542 0 0 1-.867-.65l1.787-2.383c.156-.208.339-.39.542-.542a2.707 2.707 0 0 1-.542-.542L2.546 4.075a.542.542 0 0 1 .108-.758ZM8.32 5.791a.542.542 0 0 1 .693-.832l2.163 1.621a.54.54 0 0 1 .008.833l-.005.005L9.012 9.04a.542.542 0 1 1-.693-.832l1.017-.667H6.5V6.458h2.837L8.32 5.791Z" />
  </svg>
);
