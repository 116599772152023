import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const FrameStackIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M10 5.344a2 2 0 0 1 2.575-1.916l8 2.4A2 2 0 0 1 22 7.744v5.912a2 2 0 0 1-2.575 1.915L18 15.145v1.512a2 2 0 0 1-2.575 1.916L14 18.144v1.512a2 2 0 0 1-2.575 1.916l-8-2.4A2 2 0 0 1 2 17.256v-5.912a2 2 0 0 1 2.575-1.916L6 9.856V8.344a2 2 0 0 1 2.575-1.916L10 6.856V5.344Zm8 5.4v2.312l2 .6V7.744l-8-2.4v2.112l4.575 1.372A2 2 0 0 1 18 10.744Zm-2 0-8-2.4v2.112l4.575 1.372A1.997 1.997 0 0 1 14 13.744v2.312l2 .6v-5.912Zm-12 .6v5.912l8 2.4v-5.912l-8-2.4Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export const FrameStackSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M10 5.344a2 2 0 0 1 2.575-1.916l8 2.4A2 2 0 0 1 22 7.744v5.912a2 2 0 0 1-2.575 1.915L18 15.145v1.512a2 2 0 0 1-2.575 1.916L14 18.144v1.512a2 2 0 0 1-2.575 1.916l-8-2.4A2 2 0 0 1 2 17.256v-5.912a2 2 0 0 1 2.575-1.916L6 9.856V8.344a2 2 0 0 1 2.575-1.916L10 6.856V5.344Zm8 5.4v2.312l2 .6V7.744l-8-2.4v2.112l4.575 1.372A2 2 0 0 1 18 10.744Zm-2 0-8-2.4v2.112l4.575 1.372A1.997 1.997 0 0 1 14 13.744v2.312l2 .6v-5.912Zm-12 .6v5.912l8 2.4v-5.912l-8-2.4Z"
      clipRule="evenodd"
    />
  </svg>
);
