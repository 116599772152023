import { FC, ReactNode, useEffect, useMemo, useRef, useState } from "react";

import { ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  LinkButton,
  Box as HightouchUiBox,
  Select as HightouchUiSelect,
  RadioGroup as HightouchUiRadioGroup,
  Radio,
  Checkbox as HightouchUiCheckbox,
  Button as HightouchUiButton,
  FormField,
  Switch,
  Textarea,
  TextInput,
  Alert,
  Combobox,
  SectionHeading,
  GroupedCombobox,
  CodeSnippet,
  Paragraph,
  Column,
  FileInput,
  FileUploadError,
  MultiSelect,
  TagInput,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { get, isEqual, uniqBy, uniqueId } from "lodash";
import { Controller, useFormContext } from "react-hook-form";
import { useQuery as useReactQuery, UseQueryOptions, UseQueryResult } from "react-query";
import { Flex, Text } from "theme-ui";

import { usePermission } from "src/contexts/permission-context";
import { Box, Row } from "src/ui/box";
// eslint-disable-next-line no-restricted-imports
import { Checkbox } from "src/ui/checkbox";
import { Editor } from "src/ui/editor";
import { Field, FieldError } from "src/ui/field";
import { FileUploader } from "src/ui/file";
import { fileToBase64, fileToString } from "src/ui/file/fileUploader";
import { Input, TextArea } from "src/ui/input";
import { Label } from "src/ui/label";
import { Markdown } from "src/ui/markdown";
import { RadioGroup } from "src/ui/radio";
import { Section } from "src/ui/section";
import { CreatableSelect, Select } from "src/ui/select";
import { SensitiveField } from "src/ui/sensitive-field";
import { fetcher } from "src/utils/fetcher";

import {
  ComponentType,
  FormkitComponent,
  FormkitNode,
  FormkitSection,
  getLiquidEngine,
  getUnaryBooleanValue,
  hasLiquid,
  LayoutType,
  NodeType,
  processValuesWithLiquid,
  ReferenceType,
  SKIP_LIQUID_PARSE_FIELDS,
  toExtendedOption,
} from "../../../formkit";
import { toExtendedAssociationOption } from "../../../formkit/src/api/components/associationOption";
import { KeyValueMapping } from "../components/destinations/key-value-mapping";
import { Button } from "../ui/button";
import { Code } from "../ui/code";
import { Message } from "../ui/message";
import { generateKey, HandlerCache, newLocalStorageHandlerCache } from "./cache";
import { AssociationMappings } from "./components/association-mappings";
import { Collapsible } from "./components/collapsible";
import { Form } from "./components/form";
import { FormkitContextType, useFormkitContext } from "./components/formkit-context";
import { FormkitInput } from "./components/input";
import { Mapper } from "./components/mapper";
import { Mapping } from "./components/mapping";
import { Mappings } from "./components/mappings";
import { Modifier } from "./components/modifier";
import { NestedRadioGroup } from "./components/nested-radio-group";
import { JsonColumnProps } from "./components/types";

type FormComponentProps = any;

export type GraphQLFetchProps = {
  destinationId: number;
  isRefetch?: boolean;
  modelId: number;
  query: any;
  variables: any;
};

const handlerCache: HandlerCache = newLocalStorageHandlerCache();

export const graphQLFetch = async ({ destinationId, isRefetch, modelId, query, variables }: GraphQLFetchProps) => {
  const handler: string = variables?.input?.handler;
  const enableCache: boolean = variables?.input?.variables?.enableCache;
  if (query) {
    const key = generateKey(destinationId, modelId);
    // Access cache when component supports caching and user is not refetching data
    if (enableCache && !isRefetch) {
      const cachedData = await handlerCache.get(handler, key);
      if (cachedData) {
        return cachedData;
      }
    }

    const fetch = fetcher(query, variables);
    const response = await fetch();
    const queryData = response ? Object.values(response as Record<string, unknown>)?.[0] : undefined;
    if (queryData && enableCache) {
      await handlerCache.set(handler, key, queryData);
    }
    return queryData;
  }
};

type UseQueryWrapperOptions<TResult, TError> = Omit<UseQueryOptions<TResult, TError>, "queryFn"> & {
  fetchProps: GraphQLFetchProps;
};

type UseQueryWrapperResult<TResult, TError> = Omit<UseQueryResult<TResult, TError>, "refetch"> & {
  refetch: () => void;
};

export function useQuery<TResult = unknown, TError = unknown>(
  key: string,
  options: UseQueryWrapperOptions<TResult, TError>,
): UseQueryWrapperResult<TResult, TError> {
  const [isRefetch, setIsRefetch] = useState(false);
  const { fetchProps, ...restProps } = options;
  const result = useReactQuery<TResult, TError>(key, {
    ...restProps,
    queryFn: () =>
      graphQLFetch({
        ...fetchProps,
        isRefetch,
      }),
  });

  /**
   * We use handleRefetch and useEffect to make sure that the isRefetch parameter passed to graphQLFetch is updated when the refetch
   * function is called. When refetching the data, we want to bypass the cache and useQuery's refetch does not accept parameters
   */
  useEffect(() => {
    if (isRefetch) {
      result.refetch();
      setIsRefetch(false);
    }
  }, [isRefetch]);

  const handleRefetch = () => {
    setIsRefetch(true);
  };

  return {
    ...result,
    refetch: handleRefetch,
  };
}

const FormComponentMap: Record<ComponentType, FC<FormComponentProps>> = {
  [ComponentType.Collapsible]: ({ label, children, name }) => {
    const { useHightouchUi } = useFormkitContext();

    return (
      <Controller
        name={name}
        render={({ field }) => (
          <Collapsible label={label} useHightouchUi={useHightouchUi} value={field.value} onChange={field.onChange}>
            <Form compact disableBorder>
              {children}
            </Form>
          </Collapsible>
        )}
      />
    );
  },
  [ComponentType.Switch]: ({ name, label, error }) => {
    const permission = usePermission();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <HightouchUiBox alignItems="center" display="flex" gap={3}>
                <Switch
                  isChecked={Boolean(field.value)}
                  isDisabled={Boolean(permission?.unauthorized)}
                  onChange={(value) => field.onChange(value)}
                />
                <Text
                  sx={{
                    fontSize: "13px",
                    fontWeight: "bold",
                  }}
                >
                  {label}
                </Text>
              </HightouchUiBox>
            );
          }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Checkbox]: ({ name, label, error }) => {
    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return useHightouchUi ? (
              <HightouchUiCheckbox
                isChecked={Boolean(field.value)}
                isDisabled={permission.unauthorized}
                label={label}
                onChange={field.onChange}
              />
            ) : (
              <Checkbox label={label} value={field.value} onChange={field.onChange} />
            );
          }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Select]: ({
    isSetup,
    creatable,
    grouped,
    createLabelPrefix = "object",
    error,
    multi,
    name,
    options,
    placeholder,
  }) => {
    const [customOptions, setCustomOptions] = useState<Array<{ label: string; value: string }>>([]);
    const { destination, model, useHightouchUi } = useFormkitContext();
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: !Array.isArray(options),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const permission = usePermission();

    if (useHightouchUi) {
      const useDynamicOptions = !Array.isArray(options);

      // Destinations like Google Cloud Functions have duplicate options with the same label and value,
      // which cause React errors about duplicate `key` props, so they need to be removed
      const uniqueStaticOptions = uniqBy(options, "value");

      // Destinations like `sfmc` can return objects without `label` or `value`, so they need to be removed
      const nonEmptyDynamicOptions = (data ?? []).filter((option) => {
        return "label" in option && "value" in option;
      });

      const staticOrDynamicOptions = useDynamicOptions ? nonEmptyDynamicOptions : uniqueStaticOptions;

      return (
        <>
          <Controller
            name={name}
            render={({ field }) => {
              const combinedOptions = [...staticOrDynamicOptions, ...customOptions];
              const hasValue = multi ? Array.isArray(field.value) && field.value.length > 0 : Boolean(field.value);

              // When user creates a custom option while creating a new destination,
              // this option must be added to a list of static/dynamic options on edit page,
              // otherwise selected option won't be displayed in a combobox
              if (!isSetup && creatable && hasValue) {
                const values = multi ? field.value : [field.value];

                for (const value of values) {
                  const selectedOptionExists = combinedOptions.some((option) => {
                    return option.value === value;
                  });

                  if (!selectedOptionExists) {
                    combinedOptions.push({
                      label: value,
                      value,
                    });
                  }
                }
              }

              const sharedProps = {
                isDisabled: permission.unauthorized,
                isInvalid: Boolean(error),
                isLoading: useDynamicOptions ? isFetching : false,
                placeholder,
              };

              const sharedCreatableProps = {
                supportsCreatableOptions: creatable,
                createOptionMessage: (inputValue: string) => {
                  return `Create ${createLabelPrefix} "${inputValue}"`;
                },
              };

              let component: "Select" | "Combobox" | "GroupedCombobox" | "MultiSelect" | "TagInput" | undefined;

              if (multi) {
                component = creatable || useDynamicOptions ? "TagInput" : "MultiSelect";
              } else {
                component = creatable || useDynamicOptions ? "Combobox" : "Select";
              }

              // Grouped options are only used by `salesforce` sync form, and
              // it's configured to allow single selection and disable creatable options.
              // Until more destinations use grouped options, it's not necessary to
              // implement multi selection and creatable options for grouped options
              if (grouped) {
                component = "GroupedCombobox";
              }

              return (
                <HightouchUiBox alignItems="center" display="flex" gap={3}>
                  {component === "Select" && (
                    <HightouchUiSelect
                      {...sharedProps}
                      isClearable
                      options={combinedOptions}
                      value={field.value ?? undefined}
                      onChange={(newValue) => {
                        field.onChange(newValue ?? null);
                      }}
                    />
                  )}

                  {component === "Combobox" && (
                    <Combobox
                      {...sharedProps}
                      {...sharedCreatableProps}
                      isClearable
                      options={combinedOptions}
                      value={field.value ?? undefined}
                      onChange={(newValue) => {
                        field.onChange(newValue ?? null);
                      }}
                      onCreateOption={async (inputValue) => {
                        const newOption = {
                          label: inputValue,
                          value: inputValue,
                        };

                        setCustomOptions((previousCustomOptions) => {
                          return [...previousCustomOptions, newOption];
                        });

                        field.onChange(inputValue);
                      }}
                    />
                  )}

                  {component === "GroupedCombobox" && (
                    <GroupedCombobox
                      {...sharedProps}
                      optionGroups={data ?? []}
                      value={field.value ?? undefined}
                      onChange={(newValue) => {
                        field.onChange(newValue ?? null);
                      }}
                    />
                  )}

                  {component === "MultiSelect" && (
                    <HightouchUiBox flex="1" minWidth={0}>
                      <MultiSelect
                        {...sharedProps}
                        isClearable
                        options={combinedOptions}
                        value={field.value ?? []}
                        width="100%"
                        onChange={(newValue) => {
                          field.onChange(newValue ?? []);
                        }}
                      />
                    </HightouchUiBox>
                  )}

                  {component === "TagInput" && (
                    <HightouchUiBox flex="1" minWidth={0}>
                      <TagInput
                        {...sharedProps}
                        {...sharedCreatableProps}
                        options={combinedOptions}
                        value={field.value ?? []}
                        width="100%"
                        onChange={(newValue) => {
                          field.onChange(newValue ?? []);
                        }}
                        onCreateOption={async (inputValue) => {
                          const newOption = {
                            label: inputValue,
                            value: inputValue,
                          };

                          setCustomOptions((previousCustomOptions) => {
                            return [...previousCustomOptions, newOption];
                          });

                          field.onChange([...field.value, inputValue]);
                        }}
                      />
                    </HightouchUiBox>
                  )}

                  {useDynamicOptions && (
                    <HightouchUiButton icon={ArrowPathIcon} onClick={() => refetch()}>
                      Refresh
                    </HightouchUiButton>
                  )}
                </HightouchUiBox>
              );
            }}
          />

          <FieldError error={queryError || error} />
        </>
      );
    }

    const selectFieldProps = {
      isClearable: true,
      isError: Boolean(error),
      isLoading: isFetching,
      isMulti: multi,
      options: Array.isArray(options) ? options : data,
      placeholder,
      reload: Array.isArray(options) ? undefined : refetch,
    };

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return creatable ? (
              <CreatableSelect
                {...selectFieldProps}
                formatCreateLabel={(value) => `Create ${createLabelPrefix} "${value}"...`}
                value={field.value}
                onChange={
                  multi
                    ? (options) => field.onChange(options?.map((v) => v.value) || [])
                    : (option) => field.onChange(option?.value ?? null)
                }
              />
            ) : (
              <Select
                {...selectFieldProps}
                value={field.value}
                onChange={
                  multi
                    ? (options) => field.onChange(options?.map((v) => v.value) || [])
                    : (option) => field.onChange(option?.value ?? null)
                }
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Input]: ({ name, error, placeholder, type, disable, style, readOnly, value, prefix, min, max }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const { register, control, setValue } = useFormContext();
    const { data: queriedValue, error: queryError } = useQuery<any>(JSON.stringify({ name, variables: value?.variables }), {
      enabled: value && typeof value !== "string",
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: value?.query,
        variables: value?.variables,
      },
      keepPreviousData: true,
    });
    const hardcodedValue = value ? (typeof value === "string" ? value : queriedValue) : undefined;

    useEffect(() => {
      if (hardcodedValue != null) {
        setValue(name, hardcodedValue);
      }
    }, [hardcodedValue]);

    const permission = usePermission();

    if (useHightouchUi) {
      return (
        <>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <>
                <FormkitInput
                  prefix={prefix}
                  disabled={permission.unauthorized || getUnaryBooleanValue(disable)}
                  invalid={Boolean(error)}
                  readonly={readOnly}
                  min={min}
                  max={max}
                  placeholder={placeholder}
                  type={type}
                  value={hardcodedValue ?? field.value ?? ""}
                  onBlur={field.onBlur}
                  style={style}
                  onChange={(event) => {
                    const value = event.target.value;
                    if (type === "number") {
                      field.onChange(value ? Number(value) : undefined);
                    } else {
                      field.onChange(value || undefined);
                    }
                  }}
                />
              </>
            )}
          />

          <FieldError error={queryError || error} />
        </>
      );
    }

    return (
      <>
        <Input
          {...register(name, {
            setValueAs: (v) => {
              if (type === "number") {
                return v ? Number(v) : undefined;
              } else {
                return v || undefined;
              }
            },
            value: hardcodedValue,
          })}
          disabled={getUnaryBooleanValue(disable)}
          placeholder={placeholder}
          readOnly={readOnly}
          sx={{ borderColor: error ? "red !important" : undefined, fontFamily: style === "editor" ? "monospace" : undefined }}
          type={type}
          min={min}
          max={max}
          value={hardcodedValue}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Secret]: ({ name, error, isSetup, optional, multiline }) => {
    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();
    const [isEditing, setIsEditing] = useState(false);
    const container = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (isEditing) {
        const inputElement = container.current?.querySelector("textarea, input") as HTMLElement | undefined;
        inputElement?.focus();
      }
    }, [isEditing]);

    if (useHightouchUi) {
      return (
        <>
          <Controller
            name={name}
            render={({ field }) => {
              const maskValue = !isSetup && !isEditing;
              const value = maskValue ? "•".repeat(12) : field.value ?? "";

              return (
                <HightouchUiBox ref={container} display="flex" gap={3}>
                  {multiline ? (
                    <Textarea
                      isDisabled={permission.unauthorized || maskValue}
                      resize="vertical"
                      value={value}
                      onChange={field.onChange}
                    />
                  ) : (
                    <TextInput
                      isDisabled={permission.unauthorized || maskValue}
                      type="password"
                      value={value}
                      onChange={field.onChange}
                    />
                  )}

                  {!isSetup && !permission.unauthorized && (
                    <HightouchUiBox
                      alignItems="center"
                      display="flex"
                      flex="none"
                      opacity={isEditing ? 0 : 1}
                      pointerEvents={isEditing ? "none" : undefined}
                    >
                      <HightouchUiButton
                        onClick={() => {
                          if (multiline) {
                            field.onChange("");
                          } else {
                            field.onChange(null);
                          }

                          setIsEditing(true);
                        }}
                      >
                        Edit
                      </HightouchUiButton>
                    </HightouchUiBox>
                  )}
                </HightouchUiBox>
              );
            }}
          />

          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <SensitiveField
              hideSecret={isSetup ? false : true}
              multiline={multiline}
              optional={optional}
              sx={{ borderColor: error ? "red !important" : undefined }}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.KeyValueMapping]: ({ name, error, enableEncryption }) => {
    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <KeyValueMapping enableEncryption={enableEncryption} mapping={field.value} setMapping={field.onChange} />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.File]: ({ name, error, acceptedFileTypes, transformation }) => {
    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();

    if (useHightouchUi) {
      return (
        <>
          <Controller
            name={name}
            render={({ field }) => {
              return (
                <FileInput
                  accept={acceptedFileTypes}
                  isDisabled={permission.unauthorized}
                  onUpload={async (file) => {
                    if (transformation === "base64") {
                      const result = await fileToBase64(file);
                      field.onChange(result);
                    }

                    if (transformation === "string") {
                      const result = await fileToString(file);
                      field.onChange(result);
                    }

                    if (transformation === "JSONParse") {
                      try {
                        const result = JSON.parse(await file.text());
                        field.onChange(result);
                      } catch {
                        throw new FileUploadError("Uploaded file is not a valid JSON");
                      }
                    }

                    if (typeof transformation === "function") {
                      const result = await transformation(file);
                      field.onChange(result);
                    }
                  }}
                />
              );
            }}
          />
          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <FileUploader
              acceptedFileTypes={acceptedFileTypes}
              transformation={transformation}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Textarea]: ({ name, error, placeholder, style }) => {
    const { register, control } = useFormContext();
    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();

    if (useHightouchUi) {
      return (
        <>
          <Controller
            control={control}
            name={name}
            render={({ field }) => (
              <Textarea
                isDisabled={permission.unauthorized}
                isInvalid={Boolean(error)}
                placeholder={placeholder}
                resize="vertical"
                rows={10}
                width="100%"
                value={field.value}
                onBlur={field.onBlur}
                onChange={field.onChange}
              />
            )}
          />
          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <TextArea
          {...register(name, {
            setValueAs: (v) => {
              return typeof v === "string" ? v : "";
            },
          })}
          error={Boolean(error)}
          placeholder={placeholder}
          rows={18}
          sx={{ fontFamily: style === "editor" ? "monospace" : undefined }}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Editor]: ({ beautifyJson, name, error, language, placeholder }) => {
    const beautifyJSON = (body) => {
      const obj = JSON.parse(body);
      return JSON.stringify(obj, null, 4);
    };

    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();

    if (useHightouchUi) {
      return (
        <>
          <Controller
            name={name}
            render={({ field }) => (
              <>
                <HightouchUiBox borderColor="gray.400" borderRadius="md" borderWidth="1px" p="3px 1px 0px">
                  <Editor
                    code={field.value || ""}
                    language={language}
                    placeholder={placeholder}
                    sx={{ resize: "vertical", height: 170, width: "100%" }}
                    theme="sqlserver"
                    onChange={permission.unauthorized ? undefined : field.onChange}
                  />
                </HightouchUiBox>

                {beautifyJson && (
                  <HightouchUiButton
                    isDisabled={permission.unauthorized}
                    mt={2}
                    onClick={() => {
                      field.onChange(beautifyJSON(field.value));
                    }}
                  >
                    Beautify JSON
                  </HightouchUiButton>
                )}
              </>
            )}
          />
          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <>
              <Row sx={{ padding: "3px 1px 0px", border: "1px solid #E9ECF5", marginBottom: "5px" }}>
                <Editor
                  code={field.value || ""}
                  language={language}
                  placeholder={placeholder}
                  sx={{ resize: "vertical", height: 170, width: "100%" }}
                  theme="sqlserver"
                  onChange={(val) => {
                    field.onChange(val);
                  }}
                />
              </Row>
              {beautifyJson && (
                <Button
                  label="Beautify JSON"
                  sx={{ mb: "5px", mr: "5px" }}
                  variant="secondary"
                  onClick={() => {
                    field.onChange(beautifyJSON(field.value));
                  }}
                />
              )}
            </>
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Code]: ({ name, title, content, error }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const { data = [], error: queryError } = useQuery<any>(JSON.stringify({ name, variables: content?.variables }), {
      enabled: !Array.isArray(content),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: content?.query,
        variables: content?.variables,
      },
      keepPreviousData: true,
    });

    return (
      <>
        <Controller
          name={name}
          render={() => {
            if (useHightouchUi) {
              const code = Array.isArray(content) ? content : data;
              return <CodeSnippet code={code.join("\n")} label={title} />;
            }

            return (
              <Code title={title}>
                {(Array.isArray(content) ? content : data).map((content, index) => (
                  <div key={index}>{content}</div>
                ))}
              </Code>
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Message]: ({ message, error, variant }) => {
    const { useHightouchUi } = useFormkitContext();

    if (useHightouchUi && (!variant || variant === "default" || variant === "warning")) {
      return (
        <>
          <Alert
            message={message && <Markdown>{message}</Markdown>}
            title={variant === "warning" ? "Warning" : "Information"}
            variant={variant === "warning" ? "warning" : "info"}
          />
          <FieldError error={error} />
        </>
      );
    }

    return (
      <>
        <Message sx={{ minWidth: "100%" }} variant={variant ?? "default"}>
          {message}
        </Message>
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.Mapping]: ({
    name,
    error,
    options,
    fromOptions,
    fromLabel,
    fromIcon,
    creatable,
    creatableTypes,
    advanced,
    templates,
    mappingTypes,
  }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const {
      data,
      error: toQueryError,
      refetch,
      isFetching,
    } = useQuery<any, any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: !Array.isArray(options),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const customizingFromOptionsWithReference = fromOptions && !Array.isArray(fromOptions);
    const {
      data: fromData,
      error: fromQueryError,
      refetch: fromRefetch,
      isFetching: isFetchingFromData,
    } = useQuery<any, any>(JSON.stringify({ name, variables: fromOptions?.variables }), {
      enabled: customizingFromOptionsWithReference,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: fromOptions?.query,
        variables: fromOptions?.variables,
      },
    });

    return (
      <>
        <Mapping
          advanced={advanced}
          creatable={getUnaryBooleanValue(creatable)}
          creatableTypes={creatableTypes}
          error={error}
          fromType={fromOptions ? "destinationOnlyMapping" : undefined}
          fromIcon={fromIcon}
          fromLabel={fromLabel}
          fromLoadingOptions={customizingFromOptionsWithReference ? isFetchingFromData : undefined}
          fromOptions={fromOptions ? toExtendedOption(Array.isArray(fromOptions) ? fromOptions : fromData || []) : undefined}
          fromReloadOptions={customizingFromOptionsWithReference ? fromRefetch : undefined}
          loading={isFetching}
          mappingTypes={mappingTypes}
          name={name}
          options={toExtendedOption(Array.isArray(options) ? options : data)}
          reload={Array.isArray(options) ? undefined : refetch}
          templates={templates || []}
          useHightouchUi={useHightouchUi}
        />
        {fromQueryError ? (
          <FieldError error={toQueryError} prefix="From options query error:" />
        ) : toQueryError ? (
          <FieldError error={toQueryError} prefix="To options query error:" />
        ) : (
          <FieldError error={error} />
        )}
      </>
    );
  },
  [ComponentType.Mappings]: ({
    name,
    allEnabled,
    autoSyncColumnsDefault,
    allEnabledKey,
    allEnabledLabel,
    creatable,
    creatableTypes,
    options,
    error,
    advanced,
    enableInLineMapper,
    required,
    excludeMappings,
    associationOptions,
    templates,
  }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    return (
      <>
        <Mappings
          advanced={advanced}
          allEnabled={allEnabled}
          allEnabledKey={allEnabledKey}
          allEnabledLabel={allEnabledLabel}
          associationOptions={toExtendedAssociationOption(associationOptions)}
          autoSyncColumnsDefault={autoSyncColumnsDefault}
          creatable={getUnaryBooleanValue(creatable)}
          creatableTypes={creatableTypes}
          enableInLineMapper={enableInLineMapper}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={toExtendedOption(asyncOptions ? data : options)}
          reload={asyncOptions ? refetch : undefined}
          required={required}
          templates={templates}
          useHightouchUi={useHightouchUi}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.AssociationMappings]: ({ name, options, error, excludeMappings, ascOptions }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    return (
      <>
        <AssociationMappings
          ascOptions={toExtendedOption(ascOptions)}
          error={error}
          excludeMappings={excludeMappings}
          loading={isFetching}
          name={name}
          options={toExtendedOption(asyncOptions ? data : options)}
          reload={asyncOptions ? refetch : undefined}
          useHightouchUi={useHightouchUi}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.RadioGroup]: ({ name, options, error }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const {
      data,
      error: queryError,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: !Array.isArray(options),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const permission = usePermission();

    if (useHightouchUi) {
      const staticOrDynamicOptions = Array.isArray(options) ? options : data ?? [];

      return (
        <>
          <Controller
            name={name}
            render={({ field }) => {
              // `RadioGroup` component requires values to be strings, but
              // field options can be either completely missing or be booleans
              // so we're using option indexes as `RadioGroup` value instead
              const selectedIndex = staticOrDynamicOptions.findIndex((option) => option.value === (field.value ?? undefined));

              return (
                <HightouchUiRadioGroup
                  isDisabled={permission.unauthorized}
                  orientation="vertical"
                  value={String(selectedIndex)}
                  onChange={(indexString) => {
                    const option = staticOrDynamicOptions.find((_, index) => String(index) === indexString);
                    field.onChange(option?.value ?? null);
                  }}
                >
                  {staticOrDynamicOptions.map((option, index) => (
                    <Radio
                      key={option.value ?? index}
                      description={option.description && <Markdown>{option.description}</Markdown>}
                      label={option.label}
                      value={String(index)}
                    />
                  ))}
                </HightouchUiRadioGroup>
              );
            }}
          />
          <FieldError error={queryError || error} />
        </>
      );
    }

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <RadioGroup
                loading={isFetching}
                options={Array.isArray(options) ? options : data}
                value={field.value === null ? undefined : field.value}
                onChange={(value) => (value === undefined ? field.onChange(null) : field.onChange(value))}
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Button]: ({ error, label, mode, onClickUrlQuery, url }) => {
    const { destination, model, useHightouchUi } = useFormkitContext();
    const { data: queriedValue = "", error: queryError } = useQuery<any>(JSON.stringify({ name, variables: url?.variables }), {
      enabled: url && typeof url !== "string",
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: url?.query,
        variables: url?.variables,
      },
      keepPreviousData: true,
    });

    const generatedUrl = url ? (typeof url === "string" ? url : queriedValue) : undefined;

    const permission = usePermission();

    if (useHightouchUi && mode === "link" && (generatedUrl || onClickUrlQuery)) {
      return (
        <>
          <HightouchUiButton
            isDisabled={permission.unauthorized}
            variant="primary"
            onClick={async () => {
              let onClickUrl;
              // For the edge case in which the page rerenders and reruns the above GraphQL query, causing credentials to be reset in OAuth
              // i.e. Google Sheets SA
              if (onClickUrlQuery) {
                const data = await graphQLFetch({
                  destinationId: destination?.id,
                  query: onClickUrlQuery?.query,
                  modelId: model?.id,
                  variables: onClickUrlQuery?.variables,
                });
                onClickUrl = data;
              }
              location.href = generatedUrl || onClickUrl;
            }}
          >
            {label}
          </HightouchUiButton>

          <FieldError error={queryError || error} />
        </>
      );
    }

    return (
      <>
        {mode === "link" && <LinkButton href={generatedUrl}>{label}</LinkButton>}
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.Column]: ({ name, error, useStringColumnValue, advanced, templates }) => {
    const { columns, reloadModel, loadingModel } = useFormkitContext();
    const { useHightouchUi } = useFormkitContext();
    const permission = usePermission();
    const [jsonColumnProperties, setJsonColumnProperties] = useState<JsonColumnProps>({
      selectedColumnProps: undefined,
      allColumnsProps: undefined,
    });

    const reloadJsonColumnsProps = () => {
      Sentry.captureException(new Error("reloadJsonColumnProps called for column formkit component"));
    };

    // Items in `columns` have `options` field as optional,
    // but `GroupedCombobox` expects it to be required
    const optionGroups = useMemo(() => {
      return (columns ?? []).map((group) => ({
        ...group,
        options: group.options ?? [],
      }));
    }, [columns]);

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => (
            <>
              {advanced ? (
                <Mapper
                  isDisabled={permission.unauthorized}
                  isError={Boolean(error)}
                  jsonColumnProperties={jsonColumnProperties}
                  placeholder="Select a value..."
                  selectedOption={undefined}
                  templates={templates ?? []}
                  useHightouchUi={useHightouchUi}
                  value={field.value ? field.value : advanced ? { type: "standard" } : undefined}
                  onChange={(value) => {
                    if (!value) {
                      field.onChange({ type: "standard" });
                      return;
                    }
                    field.onChange(value);
                  }}
                  onChangeJsonColumnProperties={setJsonColumnProperties}
                  onReloadEligibleInlineMapperColumns={reloadJsonColumnsProps}
                />
              ) : useHightouchUi ? (
                <HightouchUiBox alignItems="center" display="flex" gap={2}>
                  <GroupedCombobox
                    isDisabled={permission.unauthorized}
                    isInvalid={Boolean(error)}
                    isLoading={loadingModel}
                    optionGroups={optionGroups}
                    placeholder="Select a column..."
                    value={useStringColumnValue ? field.value : field.value?.from}
                    onChange={(value) => {
                      if (!value) {
                        field.onChange(null);
                        return;
                      }

                      field.onChange(useStringColumnValue ? value : { from: value });
                    }}
                  />

                  <HightouchUiButton icon={ArrowPathIcon} onClick={reloadModel}>
                    Refresh
                  </HightouchUiButton>
                </HightouchUiBox>
              ) : (
                <Select
                  isClearable={true}
                  isError={Boolean(error)}
                  isLoading={loadingModel}
                  options={columns}
                  placeholder="Select a column..."
                  reload={reloadModel}
                  value={useStringColumnValue ? field.value : field.value?.from}
                  width={340}
                  onChange={(option) =>
                    field.onChange(option?.value ? (useStringColumnValue ? option?.value : { from: option?.value }) : null)
                  }
                />
              )}
            </>
          )}
        />
        <FieldError error={error} />
      </>
    );
  },
  [ComponentType.ColumnOrConstant]: ({
    constantComponentType,
    creatable,
    createLabelPrefix = "object",
    error,
    name,
    options,
    type,
    multi,
  }) => {
    const { columns, reloadModel, loadingModel } = useFormkitContext();
    const { register } = useFormContext();
    const asyncOptions = !Array.isArray(options) && options !== null && options !== undefined;
    const { destination, model, useHightouchUi } = useFormkitContext();
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: asyncOptions,
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
    });

    const updatePermission = usePermission();

    // Items in `columns` have `options` field as optional,
    // but `GroupedCombobox` expects it to be required
    const optionGroups = useMemo(() => {
      return (columns ?? []).map((group) => ({
        ...group,
        options: group.options ?? [],
      }));
    }, [columns]);

    const isMulti = multi && (constantComponentType === ComponentType.Select || constantComponentType === undefined);

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            const isColumn =
              // Multi select is only avaliable on Select component.
              !(isMulti && Array.isArray(field.value)) &&
              typeof field.value === "object" &&
              field.value !== null &&
              field.value !== undefined;

            // `RadioGroup` component requires values to be strings, but
            // field options can be either completely missing or be booleans
            // so we're using option indexes as `RadioGroup` value instead
            const selectedRadioGroupIndex =
              Array.isArray(options) && constantComponentType === ComponentType.RadioGroup
                ? options.findIndex((option) => option.value === (field.value ?? undefined))
                : -1;

            const sharedSelectProps = {
              isClearable: true,
              isError: Boolean(error),
              isLoading: isFetching,
              onChange: (option) => field.onChange(option?.value || undefined),
              options: asyncOptions ? data : options,
              placeholder: "Select an option...",
              reload: asyncOptions ? refetch : undefined,
              value: field.value,
              width: "320px",
            };

            return (
              <Row sx={{ display: "flex", justifyContent: "space-between" }}>
                {isColumn ? (
                  <>
                    {useHightouchUi ? (
                      <HightouchUiBox alignItems="center" display="flex" gap={2}>
                        <GroupedCombobox
                          isClearable
                          isDisabled={updatePermission.unauthorized}
                          isInvalid={Boolean(error)}
                          isLoading={loadingModel}
                          optionGroups={optionGroups}
                          placeholder="Select a column..."
                          value={field.value?.from}
                          onChange={(value) => {
                            field.onChange({
                              from: value || undefined,
                            });
                          }}
                        />

                        <HightouchUiButton icon={ArrowPathIcon} onClick={reloadModel}>
                          Refresh
                        </HightouchUiButton>
                      </HightouchUiBox>
                    ) : (
                      <Select
                        isClearable={true}
                        isError={Boolean(error)}
                        isLoading={loadingModel}
                        options={columns}
                        placeholder="Select a column..."
                        reload={reloadModel}
                        value={field.value?.from}
                        width={320}
                        onChange={(option) => field.onChange(option?.value ? { from: option?.value } : { from: undefined })}
                      />
                    )}
                  </>
                ) : options && (constantComponentType === ComponentType.Select || constantComponentType === undefined) ? (
                  <>
                    {useHightouchUi ? (
                      <>
                        {creatable ? (
                          <CreatableSelect
                            formatCreateLabel={(value) => `Create ${createLabelPrefix} "${value}"...`}
                            {...sharedSelectProps}
                          />
                        ) : asyncOptions ? (
                          <HightouchUiBox alignItems="center" display="flex" gap={2}>
                            {isMulti ? (
                              <MultiSelect<Record<string, unknown>, unknown>
                                isDisabled={updatePermission.unauthorized}
                                isInvalid={Boolean(error)}
                                isLoading={isFetching}
                                options={data ?? []}
                                placeholder="Select options..."
                                value={Array.isArray(field.value) ? field.value : []}
                                onChange={(value) => {
                                  field.onChange(value || []);
                                }}
                              />
                            ) : (
                              <Combobox
                                isDisabled={updatePermission.unauthorized}
                                isInvalid={Boolean(error)}
                                isLoading={isFetching}
                                options={data ?? []}
                                placeholder="Select an option..."
                                value={field.value}
                                onChange={(value) => {
                                  field.onChange(value || undefined);
                                }}
                              />
                            )}

                            <HightouchUiButton
                              icon={ArrowPathIcon}
                              variant="secondary"
                              onClick={() => {
                                void refetch();
                              }}
                            >
                              Refresh
                            </HightouchUiButton>
                          </HightouchUiBox>
                        ) : isMulti ? (
                          <MultiSelect<Record<string, unknown>, unknown>
                            isClearable
                            isDisabled={updatePermission.unauthorized}
                            isInvalid={Boolean(error)}
                            options={options}
                            value={Array.isArray(field.value) ? field.value : []}
                            placeholder="Select options..."
                            onChange={(newValue) => {
                              field.onChange(newValue ?? []);
                            }}
                          />
                        ) : (
                          <HightouchUiSelect
                            isClearable
                            isDisabled={updatePermission.unauthorized}
                            isInvalid={Boolean(error)}
                            options={options}
                            placeholder="Select an option..."
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value || undefined);
                            }}
                          />
                        )}
                      </>
                    ) : (
                      <Select {...sharedSelectProps} />
                    )}
                  </>
                ) : options && constantComponentType === ComponentType.RadioGroup ? (
                  <>
                    {useHightouchUi ? (
                      <HightouchUiRadioGroup
                        value={String(selectedRadioGroupIndex)}
                        onChange={(indexString) => {
                          const option = options.find((_, index) => String(index) === indexString);
                          field.onChange(option?.value ?? null);
                        }}
                      >
                        {options.map((option, index) => (
                          <Radio
                            key={option.value ?? index}
                            description={option.description}
                            label={option.label}
                            value={String(index)}
                          />
                        ))}
                      </HightouchUiRadioGroup>
                    ) : (
                      <RadioGroup
                        loading={isFetching}
                        options={options}
                        sx={{ width: "70%" }}
                        value={field.value === null ? undefined : field.value}
                        onChange={(value) => (value === undefined ? field.onChange(null) : field.onChange(value))}
                      />
                    )}
                  </>
                ) : (
                  <>
                    {useHightouchUi ? (
                      <TextInput
                        isDisabled={updatePermission.unauthorized}
                        isInvalid={Boolean(error)}
                        placeholder="Enter a value..."
                        value={field.value}
                        onChange={field.onChange}
                        type={type}
                      />
                    ) : (
                      <Input
                        {...register(name)}
                        placeholder="Enter a value..."
                        sx={{ borderColor: error ? "red !important" : undefined, maxWidth: "340px" }}
                        type={type}
                      />
                    )}
                  </>
                )}

                <HightouchUiBox alignItems="center" display="flex" gap={2}>
                  <Text
                    sx={{
                      textTransform: "uppercase",
                      fontSize: "10px",
                      color: "base.4",
                      fontWeight: "bold",
                    }}
                  >
                    Use column
                  </Text>

                  <Switch
                    isChecked={isColumn}
                    isDisabled={Boolean(updatePermission?.unauthorized)}
                    onChange={(value) => {
                      if (constantComponentType === ComponentType.RadioGroup) {
                        value ? field.onChange({ from: undefined }) : field.onChange(options[0].value);
                      } else if (options) {
                        // If options exist and constantComponentType is not equal to RadioGroup, the component is a drop down.
                        value ? field.onChange({ from: undefined }) : field.onChange(isMulti ? [] : undefined);
                      } else {
                        // Component is an input field
                        value ? field.onChange({ from: undefined }) : field.onChange("");
                      }
                    }}
                  />
                </HightouchUiBox>
              </Row>
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
  [ComponentType.NestedRadioGroup]: ({ name, rootKey, listKey, allNestedSelected, options, error }) => {
    const { getValues, setValue } = useFormContext();
    const { destination, model, useHightouchUi } = useFormkitContext();
    const {
      data,
      error: queryError,
      refetch,
      isFetching,
    } = useQuery<any>(JSON.stringify({ name, variables: options?.variables }), {
      enabled: !Array.isArray(options),
      fetchProps: {
        destinationId: destination?.id,
        modelId: model?.id,
        query: options?.query,
        variables: options?.variables,
      },
      keepPreviousData: true,
    });

    return (
      <>
        <Controller
          name={name}
          render={({ field }) => {
            return (
              <NestedRadioGroup
                allNestedSelected={allNestedSelected}
                loading={isFetching}
                options={toExtendedOption(data)}
                reload={refetch}
                useHightouchUi={useHightouchUi}
                value={getValues(listKey)}
                onChange={(value: string[]) => {
                  field.onChange(value[value.length - 1]);
                  setValue(listKey, value);
                  setValue(rootKey, value[0]);
                }}
              />
            );
          }}
        />
        <FieldError error={queryError || error} />
      </>
    );
  },
};

const getComponent = (node: FormkitComponent) => FormComponentMap[node.component];

export const processReferences = (
  value: any,
  context: FormkitContextType & Record<string, unknown>,
  watch,
  skipParse?: boolean,
): unknown => {
  if (Array.isArray(value)) {
    if (!skipParse && hasLiquid(JSON.stringify(value))) {
      const engine = getLiquidEngine();
      return value.map((item) => {
        if (typeof item === "string") {
          return engine.parseAndRenderSync(item, context);
        } else if (typeof item === "object") {
          return processValuesWithLiquid(item, context, engine);
        }
        return item;
      });
    }
    return value;
  } else if (typeof value === "object" && value !== undefined && value !== null) {
    if (value.type) {
      if (value.type === ReferenceType.GraphQL) {
        // Always skip liquid parsing for graphql references -- parsing gql or its variables could cause unwanted errors
        const variables = processReferences(value.variables, context, watch, true);
        return { query: value.document, variables };
      } else if (value.type === ReferenceType.Context) {
        const contextValue = get(context, value.key);
        return contextValue;
      } else if (value.type === ReferenceType.State) {
        return watch(value.key);
      }
    }

    let newObject = {};
    let engine;
    for (const [k, v] of Object.entries(value)) {
      let processedValue = processReferences(v, context, watch, skipParse);
      if (!SKIP_LIQUID_PARSE_FIELDS.includes(k) && hasLiquid(processedValue)) {
        if (!engine) {
          engine = getLiquidEngine();
        }
        if (!skipParse && typeof processedValue === "string") {
          try {
            processedValue = engine.parseAndRenderSync(processedValue, context);
          } catch (err) {
            // do nothing
          }
        } else if (!skipParse && typeof processedValue === "object") {
          processedValue = processValuesWithLiquid(processedValue as Record<string, unknown>, context, engine);
        }
      }
      newObject = { ...newObject, [k]: processedValue };
    }
    return newObject;
  } else {
    return value;
  }
};

const Node: FC<{ node: FormkitComponent; children?: ReactNode }> = ({ node, children }) => {
  const context = useFormkitContext();
  const {
    watch,
    resetField,
    formState: { errors },
    getValues,
  } = useFormContext();

  const props = processReferences(node.props, { ...context, formState: getValues() }, watch) as Record<string, unknown>;
  const Component = getComponent(node);

  const rawError = get(errors, node.key)?.message;
  let error = typeof rawError === "string" ? rawError.replace(node.key, "This") : rawError;
  // Doing this because columns `{ from: string }` gets validate from inside out.
  // For example: a returned validation errors is `errors: { "eventId.from": "eventId.from is required."}`
  if (
    !error &&
    node.type === NodeType.Component &&
    [ComponentType.Column, ComponentType.ColumnOrConstant].includes(node.component)
  ) {
    const key = `${node.key}.from`;

    const rawError = get(errors, key)?.message;
    error = typeof rawError === "string" ? rawError.replace(node.key, "This") : rawError;
  }

  useEffect(() => {
    const value = watch(node.key);
    if (node.props?.default !== undefined && !value) {
      resetField(node.key, { defaultValue: node.props.default });
    }
  }, []);

  const isChangedInDraft =
    !children &&
    context?.draftChanges?.find(({ key }) => {
      const nodePath = node.key.split(".");
      const keyPath = key.split(".");
      return isEqual(nodePath, keyPath.slice(0, nodePath.length));
    })?.op;

  return (
    <Box
      sx={
        isChangedInDraft
          ? {
              position: "relative",
              "::after": {
                content: '""',
                top: 0,
                left: -5,
                display: "block",
                width: "4px",
                height: "100%",
                position: "absolute",
                borderRadius: "2px",
                backgroundColor: isChangedInDraft === "add" ? "green" : "yellow",
              },
            }
          : {}
      }
    >
      <Component {...props} error={error} isSetup={context.isSetup} name={node.key}>
        {children}
      </Component>
    </Box>
  );
};

interface FormNodeProps {
  node: FormkitNode;
  depth: number;
  context: Record<string, unknown>;
}

export const FormNode: FC<FormNodeProps> = ({ node, depth, context }) => {
  if (node.type === NodeType.Layout) {
    if (node.layout === LayoutType.Section) {
      const { heading, subheading } = parseHeadings(node, context);

      if (node.parent) {
        return (
          <HightouchUiBox
            _notFirst={{
              borderTopWidth: "1px",
            }}
            borderColor="gray.300"
            mt={-6}
            mx={-6}
          >
            <HightouchUiBox bg="gray.100" p={6}>
              <SectionHeading>{heading}</SectionHeading>
              {subheading && <Paragraph>{subheading}</Paragraph>}
            </HightouchUiBox>

            <Column
              gap={6}
              p={6}
              sx={{
                "> div:not(:last-of-type)": {
                  pb: 6,
                  borderBottomWidth: "1px",
                  borderColor: "gray.300",
                },
              }}
            >
              {node.children.map((node, index) => (
                <FormNode key={index} context={context} depth={depth + 1} node={node} />
              ))}
            </Column>
          </HightouchUiBox>
        );
      }

      const isSection = !node.component && node.size === "large";

      if (isSection) {
        return (
          <HightouchUiBox pb={depth > 0 ? 3 : undefined}>
            {heading && <SectionHeading>{heading}</SectionHeading>}

            {subheading ? (
              <HightouchUiBox mt={1}>
                <Markdown useHightouchUi>{subheading}</Markdown>
              </HightouchUiBox>
            ) : undefined}

            <HightouchUiBox display="flex" flexDirection="column" gap={6} mt={heading ? 3 : 0}>
              {node.children.map((node, index) => (
                <FormNode key={index} context={context} depth={depth + 1} node={node} />
              ))}
            </HightouchUiBox>
          </HightouchUiBox>
        );
      }

      return (
        <HightouchUiBox>
          <FormField
            description={subheading ? <Markdown useHightouchUi>{subheading}</Markdown> : undefined}
            isOptional={node.optional}
            label={
              heading ? (
                <Markdown disableParagraphs useHightouchUi>
                  {heading}
                </Markdown>
              ) : (
                ""
              )
            }
          >
            <Column gap={6}>
              {node.children.map((node, index) => (
                <FormNode key={index} context={context} depth={depth + 1} node={node} />
              ))}
            </Column>
          </FormField>
        </HightouchUiBox>
      );
    }

    if (node.layout === LayoutType.Form) {
      return (
        <>
          {node.children.map((node, index) => (
            <FormNode key={index} context={context} depth={depth + 1} node={node} />
          ))}
        </>
      );
    }
  }

  if (node.type === NodeType.Component) {
    return (
      <Node node={node}>
        {node.children?.map((node, index) => (
          <FormNode key={index} context={context} depth={depth + 1} node={node} />
        ))}
      </Node>
    );
  }

  if (node.type === NodeType.Modifier) {
    return (
      <Modifier node={node}>
        {node.children.map((node, index) => (
          <FormNode key={index} context={context} depth={depth + 1} node={node} />
        ))}
      </Modifier>
    );
  }

  return null;
};

export const processFormNode = (
  node: FormkitNode,
  depth = 0,
  context: Record<string, unknown> = {},
  { useHightouchUi }: { useHightouchUi: boolean } = { useHightouchUi: false },
) => {
  if (useHightouchUi) {
    return <FormNode context={context} depth={depth} node={node} />;
  }

  if (node.type === NodeType.Layout) {
    if (node.layout === LayoutType.Section) {
      const { heading, subheading } = parseHeadings(node, context);
      if (node.parent) {
        return (
          <Section key={heading}>
            <Flex sx={{ backgroundColor: "base.1", borderTop: "small", px: 6, mx: -6, mt: -6, mb: 6, pt: 6 }}>
              <Label description={subheading} size="large" sx={{ color: "base.6" }}>
                {heading}
              </Label>
            </Flex>
            <Form compact>{node.children.map((node) => processFormNode(node, depth + 1, context))}</Form>
          </Section>
        );
      }

      return (
        <Section key={heading} sx={{ pb: depth > 0 ? 3 : undefined }}>
          <Field
            description={subheading}
            label={heading ?? ""}
            optional={node.optional}
            size={node.size == "small" ? "small" : "large"}
          >
            <Column gap={3}>{node.children.map((node) => processFormNode(node, depth + 1, context))}</Column>
          </Field>
        </Section>
      );
    }

    if (node.layout === LayoutType.Form) {
      return node.children.map((node) => processFormNode(node, depth + 1, context));
    }
  }

  if (node.type === NodeType.Component) {
    return (
      <Node key={uniqueId()} node={node}>
        {node.children?.map((node) => processFormNode(node, depth + 1, context))}
      </Node>
    );
  }

  if (node.type === NodeType.Modifier) {
    return (
      <Modifier key={uniqueId()} node={node}>
        {node.children.map((node) => processFormNode(node, depth + 1, context))}
      </Modifier>
    );
  }

  return null;
};

export const getNestedKeys = (node: FormkitNode) => {
  if (node.type === NodeType.Component) {
    return node.key;
  } else {
    return node.children.map(getNestedKeys);
  }
};

function parseHeadings(node: FormkitSection, context: Record<string, unknown>): { heading: string; subheading: string } {
  const engine = hasLiquid(node.heading) || hasLiquid(node.subheading) ? getLiquidEngine() : null;
  const heading = node.heading && engine ? engine.parseAndRenderSync(node.heading, context) : node.heading ? node.heading : "";
  const subheading =
    node.subheading && engine ? engine.parseAndRenderSync(node.subheading, context) : node.subheading ? node.subheading : "";
  return { heading, subheading };
}
