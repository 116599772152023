import { FC, useEffect, useState } from "react";

import { FolderOpenIcon, PencilIcon, Square2StackIcon, TrashIcon } from "@heroicons/react/24/outline";
import {
  ConfirmationDialog,
  EditableHeading,
  EditableDescription,
  Paragraph,
  useToast,
  SectionHeading,
  Pill,
  Box,
  Button,
  ButtonGroup,
  Text,
  Row,
  Column,
  Menu,
  MenuItem,
  MenuActionsButton,
  MenuList,
  Link,
  FormField,
  Alert,
  Avatar,
} from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useParams, useNavigate } from "react-router-dom";

import { DetailBar } from "src/components/detail-bar";
import { DraftCircle } from "src/components/drafts/draft-circle";
import { EditingDraftWarning } from "src/components/drafts/draft-warning";
import { isQueryDraft } from "src/components/drafts/model-draft";
import { MoveFolder } from "src/components/folders/move-to-folder";
import { useFolder } from "src/components/folders/use-folder";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { EditLabels } from "src/components/labels/edit-labels";
import { Labels } from "src/components/labels/labels";
import { Page } from "src/components/layout";
import { Crumb } from "src/components/layout/header/breadcrumbs";
import { OverageModal } from "src/components/modals/overage-modal";
import { ColumnSelect } from "src/components/models/column-select";
import { ColumnSettings } from "src/components/models/column-settings";
import { Query } from "src/components/models/query";
import { Syncs } from "src/components/models/syncs";
import { Permission } from "src/components/permission";
import { ResourceActivityTimeline } from "src/components/resource-activity/timeline";
import { DisplaySlug } from "src/components/slug/display-slug";
import { Warning } from "src/components/warning";
import { DraftProvider, useDraft } from "src/contexts/draft-context";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ModelDraft,
  ModelQuery,
  ResourcePermissionGrant,
  ResourceToPermission,
  useDeleteModelMutation,
  useModelQuery,
  useUpdateModelMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Tabs } from "src/ui/tabs";
import { useModelState } from "src/utils/models";
import { formatDate } from "src/utils/time";

import { useLabels } from "../../components/labels/use-labels";
import { MatchBoostingSettingsWrapper } from "../audiences/setup/match-boosting";
import { modelActivityMappers, modelColumnIgnoreFields, modelColumnMappers } from "./model-activity";
import { useModelSort } from "./use-model-sort";

enum Tab {
  QUERY = "Query",
  SYNCS = "Syncs",
  COLUMNS = "Columns",
  ACTIVITY = "Activity",
  MATCH_BOOSTING = "Match boosting",
}

export const ModelWrapper: FC = () => {
  const { model_id: id } = useParams<{ model_id?: string }>();
  const { columnsOrderBy, syncsOrderBy } = useModelSort();

  const {
    data: model,
    isLoading,
    refetch,
  } = useModelQuery(
    { id: id ?? "", syncsOrderBy, columnsOrderBy },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  if (!id || isLoading) {
    return <PageSpinner />;
  }

  if (!model) {
    return <Warning subtitle="It may have been deleted" title="Model not found" />;
  }

  return (
    <DraftProvider initialResourceIsDraft={model.draft || false} resourceId={id} resourceType={ResourceToPermission.Model}>
      <Model model={model} refetch={refetch} />
    </DraftProvider>
  );
};

type Model = NonNullable<ModelQuery["segments_by_pk"]>;
interface ModelProps {
  model: Model;
  refetch: () => void;
}

const Model: FC<ModelProps> = ({ model: initialModel, refetch }: ModelProps) => {
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [tab, setTab] = useState<Tab>(Tab.QUERY);
  const { modelState, setPrimaryKey, initModelState } = useModelState();
  const [tableLoading, setTableLoading] = useState(false);
  const [isEditLabelModalOpen, setIsEditLabelModalOpen] = useState(false);
  const [isFolderModalOpen, setIsFolderModalOpen] = useState(false);
  const { appMatchBoosting } = useFlags();
  const {
    setSubmitDraftModalOpen,
    updateResourceOrDraft,
    editingDraft,
    draft,
    editingDraftChanges,
    mergeResourceWithDraft,
    setEditingDraft,
    onViewDraft,
  } = useDraft();
  const [model, setModel] = useState<Model>(initialModel);

  const { columnsOrderBy, curriedOnSort, syncsOrderBy } = useModelSort();

  const id = model.id;

  const { labels } = useLabels();

  const { mutateAsync: updateModel, isLoading: updating } = useUpdateModelMutation();
  const primaryKeyMutation = useUpdateModelMutation({ onSuccess: () => undefined });
  const { mutateAsync: deleteModel } = useDeleteModelMutation();

  const currentLabels = model.tags ?? {};
  const labelKeys = Object.keys(currentLabels);
  const type = model.query_type as QueryType;
  const syncs = model.syncs;
  const source = model.connection;

  const draftResource = draft?.new_resource ? (draft.new_resource as ModelDraft) : undefined;
  const columns = editingDraft ? draftResource?.modelColumns ?? model.columns ?? [] : model.columns ?? [];

  useEffect(() => {
    if (draft && initialModel) {
      const copy = mergeResourceWithDraft(initialModel);
      setModel(copy as Model);
      initModelState(copy);
    } else {
      setModel(initialModel);
      initModelState(initialModel);
    }
  }, [initialModel, editingDraft]);

  const onUpdate = () => {
    analytics.track("Model Updated", {
      model_id: model.id,
      model_type: type,
      model_name: model.name,
      source_id: source?.id,
      source_type: source?.type,
    });

    toast({
      id: "update-model",
      title: "Model was updated",
      variant: "success",
    });

    refetch();
  };

  const [name, setName] = useState(model.name ?? "");

  useEffect(() => {
    setName(model.name ?? "");
  }, [model.name]);

  const updatePermission = useHasPermission(
    workspace?.approvals_required
      ? undefined
      : [{ resource: "model", grants: [ResourcePermissionGrant.Update], resource_id: model.id }],
  );

  const updateName = async () => {
    await updateModel({
      id: model.id,
      input: {
        name,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });
    onUpdate();
  };

  const updateLabels = async (labels: Record<string, string | number>) => {
    try {
      await updateModel({
        id: id,
        input: {
          tags: labels,
        },
      });
      onUpdate();
      setIsEditLabelModalOpen(false);
    } catch (error) {
      toast({
        id: "update-model-labels",
        title: "Couldn't update labels",
        message: error.message,
        variant: "error",
      });
    }
  };

  const [description, setDescription] = useState(model.description ?? "");

  useEffect(() => {
    setDescription(model.description ?? "");
  }, [model.description]);

  const updateDescription = async () => {
    await updateModel({
      id: model.id,
      input: {
        description,
        updated_by: user?.id != null ? String(user?.id) : undefined,
      },
    });
    onUpdate();
  };

  const updatePrimaryKey = async (primary_key: string) => {
    const oldPrimaryKey = model.primary_key ?? "";
    setPrimaryKey(primary_key);

    const updatePayload = {
      primary_key,
      updated_by: user?.id != null ? String(user?.id) : undefined,
      // we null the draft id, it gets added on the backend and we want to be consistent
      // if a workspace turns off approvals again =
      approved_draft_id: null,
    };

    if (updateResourceOrDraft) {
      try {
        await updateResourceOrDraft(
          { _set: updatePayload },
          () => null,
          async () => {
            await primaryKeyMutation.mutateAsync({
              id: model.id,
              input: updatePayload,
            });
          },
          model.draft,
        );
      } catch (e) {
        toast({
          id: "update-primary-key-error",
          title: "Failed to update primary key",
          message: e.message,
          variant: "error",
        });
        setPrimaryKey(oldPrimaryKey);
      }

      toast({
        id: "update-primary-key",
        title: "Primary key updated",
        variant: "success",
      });

      setSubmitDraftModalOpen(true);
    }
  };

  const folder = useFolder({ folderId: model.folder?.id ?? null, folderType: "models", viewType: "models" });

  useEffect(() => {
    setTableLoading(true);
  }, [columnsOrderBy, syncsOrderBy]);

  useEffect(() => {
    setTableLoading(false);
  }, [model]);

  const hasPrimaryKeyIssue = Boolean(model.columns?.length && !model.columns.some((c) => c.name === model.primary_key));

  const matchBoostingTab = appMatchBoosting && model ? [Tab.MATCH_BOOSTING] : [];

  const TABS = [
    editingDraft && draftResource?._set && isQueryDraft(draftResource?._set)
      ? {
          render: () => (
            <Row align="center" gap={2}>
              Configuration <DraftCircle />
            </Row>
          ),
          value: Tab.QUERY,
        }
      : Tab.QUERY,
    {
      render: () => (
        <Box>
          <Text>Syncs</Text>
          {Array.isArray(syncs) && syncs.length > 0 && <Pill ml={2}>{syncs.length}</Pill>}
        </Box>
      ),
      value: Tab.SYNCS,
    },
    {
      render: () => <Text>Activity</Text>,
      value: Tab.ACTIVITY,
    },
    editingDraft && draftResource?.modelColumns
      ? {
          render: () => (
            <Row align="center" gap={2}>
              Columns <DraftCircle />
            </Row>
          ),
          value: Tab.COLUMNS,
        }
      : Tab.COLUMNS,
    ...matchBoostingTab,
  ].filter(Boolean);

  const updatedByUsername = model.updated_by_user?.name || model.created_by_user?.name;

  const crumbs: Crumb[] = [{ label: "Models", link: "/models" }];

  if (folder?.path) {
    folder.path.split("/").forEach((path) => {
      crumbs.push({
        label: path,
        link: `/models?folder=${folder.id}`,
      });
    });
  }

  crumbs.push({
    label: model.name ?? "",
  });

  const { type: sourceDescriptionType, description: sourceDescription } = model?.source_description || {};

  return (
    <>
      <PermissionProvider
        permissions={
          workspace?.approvals_required
            ? []
            : [{ resource: "model", grants: [ResourcePermissionGrant.Update], resource_id: model.id }]
        }
      >
        <Page
          crumbs={crumbs}
          outsideTopbar={
            draft && (
              <EditingDraftWarning
                draft={draft}
                editingDraft={editingDraft}
                resourceType={ResourceToPermission.Model}
                setEditingDraft={setEditingDraft}
                onViewDraft={onViewDraft}
              />
            )
          }
          title={`${model.name ?? "Unnamed model"} - Models`}
        >
          <Column width="100%" height="100%">
            <Column width="100%" mb={6}>
              <Row align="center" justify="space-between">
                <EditableHeading
                  isDisabled={!updatePermission.hasPermission}
                  size="lg"
                  value={name}
                  onChange={setName}
                  onSubmit={updateName}
                />

                <ButtonGroup>
                  <Menu>
                    <MenuActionsButton variant="secondary" />
                    <MenuList>
                      {updatePermission.hasPermission && (
                        <MenuItem
                          icon={FolderOpenIcon}
                          onClick={() => {
                            setIsFolderModalOpen(true);
                          }}
                        >
                          Move to folder
                        </MenuItem>
                      )}
                      {updatePermission.hasPermission && (
                        <MenuItem
                          icon={PencilIcon}
                          onClick={() => {
                            setIsEditLabelModalOpen(true);
                          }}
                        >
                          Edit labels
                        </MenuItem>
                      )}
                      <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Create] }]}>
                        <MenuItem
                          icon={Square2StackIcon}
                          onClick={() => {
                            navigate(`/models/${model.id}/clone`);
                          }}
                        >
                          Clone
                        </MenuItem>
                      </Permission>
                      <Permission permissions={[{ resource: "model", grants: [ResourcePermissionGrant.Delete] }]}>
                        <MenuItem
                          icon={TrashIcon}
                          variant="danger"
                          onClick={() => {
                            setDeleteModal(true);
                          }}
                        >
                          Delete
                        </MenuItem>
                      </Permission>
                    </MenuList>
                  </Menu>
                  <Permission permissions={[{ resource: "sync", grants: [ResourcePermissionGrant.Create] }]}>
                    <Button onClick={() => navigate(`/syncs/new?model=${id}`)}>Add sync</Button>
                  </Permission>
                </ButtonGroup>
              </Row>
              <Row>
                <EditableDescription
                  externalValue={sourceDescription ?? undefined}
                  externalValueSource={sourceDescriptionType}
                  isDisabled={!updatePermission.hasPermission}
                  value={description}
                  onChange={setDescription}
                  onSubmit={updateDescription}
                />
              </Row>
              <DetailBar>
                <Row align="center" gap={2} flexShrink={0}>
                  <IntegrationIcon src={source?.definition?.icon} name={source?.definition?.name} />
                  <Link href={`/sources/${source?.id}`}>
                    <Text fontWeight="medium" color="inherit">
                      {source?.name}
                    </Text>
                  </Link>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Last updated:</Text>
                  <Row gap={1} align="center">
                    {formatDate((model.updated_at || model.created_at)!)}
                    {updatedByUsername && (
                      <>
                        <Text>by</Text>
                        <Avatar size="xs" name={updatedByUsername} />
                      </>
                    )}
                  </Row>
                </Row>
                <Row align="center" gap={2} flexShrink={0}>
                  <Text>Slug:</Text>
                  <DisplaySlug currentSlug={model.slug} />
                </Row>
                {!editingDraftChanges && labelKeys.length > 0 && (
                  <Row>
                    <Labels labels={currentLabels} />
                  </Row>
                )}
              </DetailBar>
            </Column>

            <Tabs setTab={(tab) => setTab(tab as Tab)} sx={{ mb: 8 }} tab={tab} tabs={TABS} />

            {hasPrimaryKeyIssue && !model.connection?.definition?.creatablePrimaryKey && (
              <Alert
                mb={8}
                variant="warning"
                title="Undefined primary key"
                message="Without a primary key your syncs may fail or undefined behavior may occur. Check that your primary key is set to a valid column."
              />
            )}

            {tab === Tab.QUERY && (
              <Column gap={8}>
                <Column maxHeight="40vh" overflow="hidden">
                  <Query
                    model={model}
                    actions={
                      <Permission>
                        <Button
                          onClick={() => navigate(editingDraft ? `/models/${id}/query?editing=true` : `/models/${id}/query`)}
                        >
                          Edit
                        </Button>
                      </Permission>
                    }
                  />
                </Column>

                <Column gap={6}>
                  <SectionHeading>Configuration</SectionHeading>
                  <FormField label="Primary key">
                    <ColumnSelect
                      isLoading={primaryKeyMutation.isLoading}
                      isDisabled={!updatePermission.hasPermission}
                      columns={columns}
                      creatable={Boolean(model.connection?.definition?.creatablePrimaryKey)}
                      model={model}
                      value={modelState?.primaryKey}
                      onChange={updatePrimaryKey}
                    />
                  </FormField>
                </Column>
              </Column>
            )}

            {tab === Tab.SYNCS && (
              <Syncs
                isAudience={false}
                syncs={syncs}
                onAdd={() => {
                  navigate(`/syncs/new?model=${id}`);
                }}
              />
            )}
            {tab === Tab.ACTIVITY && id && (
              <ResourceActivityTimeline
                primaryResource={{ mappers: modelActivityMappers, resource: "Model", resourceId: String(id) }}
                relatedResources={[
                  {
                    mappers: modelColumnMappers,
                    resource: "Model Column",
                    resourceNameOverride: "Model column",
                    resourceId: `model_id:${id}:%`,
                    ignoreColumns: modelColumnIgnoreFields,
                  },
                ]}
              />
            )}
            {tab === Tab.COLUMNS && (
              <ColumnSettings
                columns={columns}
                isDraft={model.draft || false}
                loading={tableLoading}
                modelId={id}
                dbtColumns={model.query_dbt_model?.data?.columns || []}
                orderBy={columnsOrderBy}
                onSort={curriedOnSort("columns")}
              />
            )}
            {tab === Tab.MATCH_BOOSTING && <MatchBoostingSettingsWrapper columns={columns} model={model} segmentType="model" />}
          </Column>
        </Page>
      </PermissionProvider>

      <ConfirmationDialog
        confirmButtonText="Delete model"
        isOpen={deleteModal}
        title="Delete model"
        variant="danger"
        onClose={() => {
          setDeleteModal(false);
        }}
        onConfirm={async () => {
          await deleteModel({
            id,
          });

          analytics.track("Model Deleted", {
            model_id: model.id,
            model_type: type,
            model_name: model.name,
            source_id: source?.id,
            source_type: source?.type,
          });

          toast({
            id: "delete-model",
            title: "Model was deleted",
            variant: "success",
          });

          navigate("/models");
        }}
      >
        <Paragraph>Are you sure you want to delete this model You won't be able to undo this.</Paragraph>
      </ConfirmationDialog>

      <EditLabels
        description="You can label models that have similar properties"
        existingLabelOptions={labels}
        hint="Example keys: team, project, region, env."
        isOpen={isEditLabelModalOpen}
        labels={currentLabels}
        loading={updating}
        saveLabel="Save"
        title="Edit labels"
        onClose={() => setIsEditLabelModalOpen(false)}
        onSave={updateLabels}
      />

      {isFolderModalOpen && (
        <MoveFolder
          folder={model.folder}
          folderType="models"
          modelIds={model.id}
          viewType="models"
          onClose={() => setIsFolderModalOpen(false)}
        />
      )}

      <OverageModal />
    </>
  );
};
