import { createContext, FC, ReactNode, useContext, useEffect, useMemo, useState } from "react";

import { ArrowsPointingOutIcon, MinusIcon, PlusIcon } from "@heroicons/react/24/outline";
import { Text, Row, Box, Column, IconButton } from "@hightouchio/ui";
import { nanoid } from "nanoid";
import { useNavigate } from "react-router-dom";
import ReactFlow, { Edge, EdgeTypes, MiniMap, Node, NodeTypes, useEdgesState, useNodesState, useReactFlow } from "reactflow";

import "reactflow/dist/style.css";

import { NAV_EXPANDED_WIDTH } from "src/components/layout/constants";
import { drawerWidth } from "src/pages/schema";
import { Search } from "src/pages/schema/search";
import { SchemaModelType } from "src/types/schema";
import { UndoIcon } from "src/ui/icons/new-icons";

import { EdgeType, GraphModel, GraphRelationship } from "../types";
import { getConnectedNodes, getGraph, NODE_SPACING, NODE_HEIGHT, NODE_WIDTH, getParams } from "../utils";
import { ConnectionLine, CustomEdge } from "./edges";
import { reposition } from "./layout";
import { EventNode, ParentNode, RelatedNode } from "./nodes";
import css from "./reactflow.module.css";

const nodeTypes: NodeTypes = {
  [SchemaModelType.Parent]: ParentNode,
  [SchemaModelType.Related]: RelatedNode,
  [SchemaModelType.Event]: EventNode,
};

const edgeTypes: EdgeTypes = { [EdgeType.Custom]: CustomEdge };

export const Graph: FC<
  Readonly<{
    selectedId: string | undefined | null;
    type: string | null;
    models: GraphModel[];
    relationships: GraphRelationship[];
  }>
> = ({ selectedId, type, models, relationships }) => {
  const navigate = useNavigate();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const { fitView, setCenter, zoomIn, zoomOut } = useReactFlow();
  const [focusNodeId, setFocusNodeId] = useState("");

  const params = getParams();

  const updateGraph = async (nodes: Node[], edges: Edge[]) => {
    const repositionedNodes = (await reposition(nodes, edges)).map((node) => {
      return {
        ...node,
        data: {
          ...(node.data || {}),
          isSelected: node.id === selectedId,
          isSource: Boolean(edges.find((edge) => edge.source === node.id)),
          isTarget: Boolean(edges.find((edge) => edge.target === node.id)),
        },
      };
    });
    setEdges(edges);
    setNodes(repositionedNodes);
  };

  const startFlow = (nodeId: string, type: SchemaModelType) => {
    const { nodes, edges } = getGraph(models, relationships);
    const ephemeralNodeId = nanoid();
    const ephemeralEdgeId = nanoid();
    const newEdges = [
      ...edges,
      {
        id: ephemeralEdgeId,
        type: EdgeType.Custom,
        source: String(nodeId),
        target: ephemeralNodeId,
        animated: true,
        data: {
          isEphemeral: true,
        },
      },
    ];
    const newNodes = [
      ...nodes,
      {
        id: ephemeralNodeId,
        style: { width: NODE_WIDTH, height: NODE_HEIGHT },
        type,
        data: {
          isEphemeral: true,
        },
        position: { x: 0, y: 0 },
      },
    ];
    setFocusNodeId(ephemeralNodeId);
    updateGraph(newNodes, newEdges);
  };

  const startNewParentFlow = () => {
    const { nodes, edges } = getGraph(models, relationships);
    const ephemeralNodeId = nanoid();
    const newNodes = [
      ...nodes,
      {
        id: ephemeralNodeId,
        style: { width: NODE_WIDTH, height: NODE_HEIGHT },
        type: SchemaModelType.Parent,
        data: {
          isEphemeral: true,
        },
        position: { x: 0, y: 0 },
      },
    ];
    setFocusNodeId(ephemeralNodeId);
    updateGraph(newNodes, edges);
  };

  const removeEphemeralNodes = () => {
    const { nodes, edges } = getGraph(models, relationships);
    setFocusNodeId("");
    updateGraph(nodes, edges);
  };

  useEffect(() => {
    const { nodes, edges } = getGraph(models, relationships);
    updateGraph(nodes, edges);
  }, [relationships, models]);

  useEffect(() => {
    if (focusNodeId) {
      const node = nodes.find((n) => n.id === focusNodeId);
      if (node) {
        const x = node.position.x + Number(node.style?.width) / 2;
        const y = node.position.y + Number(node.style?.height) / 2;
        let offsetX = x + drawerWidth / 2;
        const availableWidth = window.innerWidth - drawerWidth - NAV_EXPANDED_WIDTH;
        const padding = 48;
        if (availableWidth > NODE_WIDTH * 2 + NODE_SPACING + padding) {
          offsetX -= NODE_SPACING;
        }
        setCenter(offsetX, y, {
          zoom: 1,
          duration: 500,
        });
      }
    } else {
      fitView({ padding: 0.25, duration: 500 });
    }
  }, [nodes]);

  useEffect(() => {
    if (type) {
      if (type === SchemaModelType.Parent) {
        startNewParentFlow();
      } else if (selectedId) {
        startFlow(selectedId, type as SchemaModelType);
      }
    } else if (selectedId) {
      setFocusNodeId(selectedId);
      const { nodes, edges } = getGraph(models, relationships);
      updateGraph(
        nodes.filter((node) => !node.data?.isEphemeral),
        edges.filter((edge) => !edge.data?.isEphemeral),
      );
    } else {
      removeEphemeralNodes();
    }
  }, [selectedId, type]);

  return (
    <GraphContextProvider edges={edges} nodes={nodes}>
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        nodesConnectable={false}
        nodesDraggable={false}
        edges={edges}
        onNodesChange={(changes) => {
          onNodesChange(changes);
        }}
        onEdgesChange={onEdgesChange}
        fitView
        maxZoom={1}
        connectionLineComponent={ConnectionLine}
        proOptions={{ hideAttribution: true }}
        className={css.reactflow}
      >
        <Column gap={4} pos="absolute" top={6} left={6} zIndex={5} sx={{ button: { borderColor: "base.border" } }}>
          <Search />

          <Column
            bg="white"
            border="1px"
            borderColor="base.border"
            borderRadius="md"
            overflow="hidden"
            sx={{
              button: {
                width: "38px",
                height: "38px",
                borderRadius: 0,
                ":nth-child(2)": { border: "1px", borderLeft: "none", borderRight: "none", borderColor: "base.border" },
              },
            }}
          >
            <IconButton
              aria-label="Zoom in"
              icon={PlusIcon}
              onClick={() => {
                zoomIn();
              }}
            />
            <IconButton
              aria-label="Zoom out"
              icon={MinusIcon}
              onClick={() => {
                zoomOut();
              }}
            />
            <IconButton
              aria-label="Fit view"
              icon={ArrowsPointingOutIcon}
              onClick={() => fitView({ padding: 0.25, duration: 500 })}
            />
          </Column>
        </Column>
        <MiniMap position="bottom-left" style={{ border: "1px solid var(--chakra-colors-base-border)", borderRadius: "6px" }} />
        <GraphBackground />
        {params.parent && (
          <Row pos="absolute" bottom={6} left={0} width="100%" justify="center">
            <Box
              as="button"
              zIndex={5}
              borderRadius="md"
              boxShadow="md"
              bg="text.primary"
              color="white"
              height="40px"
              flexShrink={0}
              px={4}
              onClick={() => {
                navigate(`/schema-v2?source=${params.source}`);
              }}
              _hover={{ opacity: 0.9 }}
              transition="opacity 0.15s ease-in-out"
            >
              <Row gap={2} align="center">
                <UndoIcon />
                <Text color="inherit" fontWeight="medium">
                  Return to source schema
                </Text>
              </Row>
            </Box>
          </Row>
        )}
      </ReactFlow>
    </GraphContextProvider>
  );
};

export const GraphContext = createContext<{
  highlight: Node | null;
  setHighlight: (node: Node | null) => void;
  highlightedNodes: string[];
}>({
  highlight: null,
  setHighlight: () => {},
  highlightedNodes: [],
});

export const useGraphContext = () => useContext(GraphContext);

export const GraphContextProvider: FC<Readonly<{ children: ReactNode; edges: Edge[]; nodes: Node[] }>> = ({
  children,
  nodes,
  edges,
}) => {
  const [highlight, setHighlight] = useState<Node | null>(null);

  const highlightedNodes = useMemo(() => {
    if (highlight && edges && nodes) {
      const tracedNodes = getConnectedNodes(highlight.id, nodes, edges);
      return [highlight.id, ...tracedNodes];
    }
    return [];
  }, [highlight, edges]);

  return <GraphContext.Provider value={{ highlight, setHighlight, highlightedNodes }}>{children}</GraphContext.Provider>;
};

export const GraphBackground = () => (
  <Box
    pos="absolute"
    width="100%"
    height="100%"
    backgroundImage={`url(
  "data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAzMiAzMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHJlY3Qgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiBmaWxsPSIjRkJGQ0ZEIi8+CjxyZWN0IHg9IjE0IiB5PSIxNCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI0RCRTFFOCIvPgo8cmVjdCB4PSIxNCIgeT0iMTciIHdpZHRoPSIxIiBoZWlnaHQ9IjEiIGZpbGw9IiNEQkUxRTgiLz4KPHJlY3QgeD0iMTciIHk9IjE0IiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSIjREJFMUU4Ii8+CjxyZWN0IHg9IjE3IiB5PSIxNyIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0iI0RCRTFFOCIvPgo8L3N2Zz4=")`}
  />
);
