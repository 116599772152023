import { useState } from "react";

import { Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { Box, Button, Column, Paragraph } from "@hightouchio/ui";

import { Chevron } from "src/ui/icons/new-icons";

export interface DialogProps {
  pages: { src: string; altText: string; description: string }[];
  isOpen: boolean;
  title: string;
  closeOnOverlayClick?: boolean;
  confirmButtonText: string;
  onClose: () => void;
}

export function NewFeatureDialog({
  closeOnOverlayClick = true,
  confirmButtonText,
  isOpen,
  pages,
  title,
  onClose,
}: DialogProps) {
  const [visiblePageIndex, setVisiblePageIndex] = useState(0);

  const previousPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage > 0) {
        return prevPage - 1;
      }

      return prevPage;
    });
  };

  const nextPage = () => {
    setVisiblePageIndex((prevPage) => {
      if (prevPage < pages.length - 1) {
        return prevPage + 1;
      }

      return prevPage;
    });
  };

  return (
    <Modal isOpen={isOpen} closeOnOverlayClick={closeOnOverlayClick} onClose={onClose}>
      <ModalOverlay>
        <ModalContent
          w="lg"
          motionProps={{
            initial: {
              scale: 0.9,
              opacity: 0,
            },
            variants: {
              enter: {
                scale: 1,
                opacity: 1,
                transition: {
                  type: "spring",
                  damping: 30,
                  stiffness: 1000,
                },
              },
              exit: {
                scale: 0.9,
                opacity: 0,
                transition: {
                  type: "spring",
                  duration: 0.15,
                },
              },
            },
          }}
        >
          <Column flex="1" minHeight={0} gap={4}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
              {pages.map(({ src, description, altText }, index) => {
                if (index !== visiblePageIndex) {
                  return null;
                }

                return (
                  <Column key={index} gap={4}>
                    <Box
                      display="grid"
                      gridTemplateColumns="40px 1fr 40px"
                      minHeight="250px"
                      alignItems="center"
                      gap={4}
                      sx={{
                        button: {
                          border: "none",
                        },
                      }}
                    >
                      <Button aria-label="Go to previous page." isDisabled={visiblePageIndex === 0} onClick={previousPage}>
                        <Chevron sx={{ transform: "rotate(90deg)" }} />
                      </Button>

                      {src ? (
                        <Box as="img" alt={altText} src={src} />
                      ) : (
                        <Column bg="base.background" height="100%" width="100%" />
                      )}
                      <Button
                        aria-label="Go to next page."
                        isDisabled={visiblePageIndex >= pages.length - 1}
                        onClick={nextPage}
                      >
                        <Chevron sx={{ transform: "rotate(-90deg)" }} />
                      </Button>
                    </Box>
                    <Paragraph>{description}</Paragraph>
                  </Column>
                );
              })}
            </ModalBody>
          </Column>

          <ModalFooter mt={4}>
            <Button variant="primary" onClick={onClose}>
              {confirmButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  );
}
