import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const UndoIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M5 13.794a.5.5 0 0 0 .5.5h4.293a.5.5 0 0 0 .353-.854l-1.481-1.48c2.62-1.691 6.437-1.159 8.305 1.597.266.393.77.584 1.203.39.452-.203.651-.745.384-1.163-2.44-3.81-7.682-4.5-11.136-2.068L5.854 9.147A.5.5 0 0 0 5 9.501v4.293Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const UndoSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M5 13.794a.5.5 0 0 0 .5.5h4.293a.5.5 0 0 0 .353-.854l-1.481-1.48c2.62-1.691 6.437-1.159 8.305 1.597.266.393.77.584 1.203.39.452-.203.651-.745.384-1.163-2.44-3.81-7.682-4.5-11.136-2.068L5.854 9.147A.5.5 0 0 0 5 9.501v4.293Z"
      fill="currentColor"
    />
  </svg>
);
