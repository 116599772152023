import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const RedoIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        d="M18.677 13.794a.5.5 0 0 1-.5.5h-4.293a.5.5 0 0 1-.353-.854l1.481-1.481c-2.62-1.691-6.437-1.159-8.304 1.597-.267.393-.77.584-1.204.39-.452-.203-.65-.745-.383-1.163 2.44-3.81 7.682-4.5 11.135-2.068l1.568-1.568a.5.5 0 0 1 .853.354v4.293Z"
        fill="currentColor"
      />
    </Icon>
  );
};

export const RedoSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M18.677 13.794a.5.5 0 0 1-.5.5h-4.293a.5.5 0 0 1-.353-.854l1.481-1.481c-2.62-1.691-6.437-1.159-8.304 1.597-.267.393-.77.584-1.204.39-.452-.203-.65-.745-.383-1.163 2.44-3.81 7.682-4.5 11.135-2.068l1.568-1.568a.5.5 0 0 1 .853.354v4.293Z"
      fill="currentColor"
    />
  </svg>
);
