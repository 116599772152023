import { addDays } from "date-fns";
import { random, sortBy } from "lodash";

/**
 * Generates an array of dates between the start and stop date.
 *
 * @param startDate {Date} The starting date.
 * @param stopDate {Date} The stopping date (inclusive).
 * @returns {Date[]} An array of dates between the start and stop date.
 */
function getDates(startDate: Date, stopDate: Date) {
  const dateArray: Date[] = [];
  let currentDate = startDate;

  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }

  return dateArray;
}

export function generateArrayOfRandomNumbers({
  length,
  min,
  max,
  sorted = false,
}: {
  length: number;
  max: number;
  min: number;
  sorted?: boolean;
}): number[] {
  const result: number[] = [];

  for (let i = 0; i < length; i++) {
    result.push(random(min, max));
  }

  // sort so that it's strictly upwards
  if (sorted) {
    return sortBy(result);
  }

  return result;
}

export const allDatesISOFormat = getDates(new Date("August 15 2022"), new Date("March 9 2023")).map((date) =>
  date.toISOString(),
);
