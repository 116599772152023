import { FC } from "react";

import { Box, BoxProps, Button, ButtonProps, IconButton, Row, Tooltip } from "@hightouchio/ui";

import { Condition, ConditionType } from "src/types/visual";
import { BidirectionalArrowIcon, CloseSVG, DeleteSVG, PlusSVG } from "src/ui/icons/new-icons";

import { FilterPopover, FilterPopoverProps } from "../filter-popover/filter-popover";
import { colors } from "./colors";

type AndOrButtonProps = {
  hideIcon?: boolean;
  conditionType: ConditionType.And | ConditionType.Or;
  onClick(): void;
};

export const AndOrToggleButton: FC<Readonly<AndOrButtonProps & BoxProps>> = ({
  hideIcon,
  conditionType,
  onClick,
  ...props
}) => {
  return (
    <Row
      mr={2}
      sx={{
        button: {
          bg: colors.base[conditionType],
          border: "none",
          minWidth: 0,
          p: 2,
          _hover: {
            bg: colors.hover[conditionType],
            ".button-hidden-icon": {
              display: "block",
            },
          },
        },
      }}
      {...props}
    >
      <Button size="md" onClick={onClick}>
        {conditionType === ConditionType.And ? "AND" : "OR"}
        {!hideIcon && (
          <Box className="button-hidden-icon" display="none" ml={1}>
            <BidirectionalArrowIcon size={20} />
          </Box>
        )}
      </Button>
    </Row>
  );
};

interface AddFilterButtonProps<TCondition extends Condition> extends FilterPopoverProps<TCondition> {
  conditionType: ConditionType.And | ConditionType.Or;
}

export function AddFilterButton<TCondition extends Condition>({
  conditionType,
  ...props
}: AddFilterButtonProps<TCondition>): JSX.Element {
  return (
    <FilterPopover
      {...props}
      triggerButton={
        <Row
          alignItems="center"
          sx={{
            button: {
              bg: colors.base[conditionType],
              border: "none",
              _hover: {
                bg: colors.hover[conditionType],
              },
              _active: {
                bg: colors.hover[conditionType],
              },
              svg: { height: "20px", width: "20px" },
            },
          }}
        >
          <Button>
            <Row gap={2}>
              <PlusSVG />
              Add filter
            </Row>
          </Button>
        </Row>
      }
    />
  );
}

export const TextButton: FC<Readonly<ButtonProps>> = (props) => (
  <Box bg="transparent" sx={{ button: { border: "none", bg: "transparent", _hover: { color: "text.secondary" } } }}>
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore icon and imageUrl 'RequireExactlyOne' type not working in spread */}
    <Button color="text.secondary" size="sm" {...props} />
  </Box>
);

export const DetailButton: FC<Readonly<ButtonProps>> = (props) => (
  <Box
    color="text.secondary"
    sx={{
      button: {
        _hover: {
          background: "base.lightBackground",
          border: "none",
        },
        _disabled: {
          _hover: {
            color: "inherit",
          },
        },
        bg: "transparent",
        border: "none",
        color: "text.secondary",
        svg: {
          mr: 1,
        },
        transition: "120ms all ease-in",
      },
      "* > svg": { height: "16px", width: "16px" },
    }}
  >
    {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
    {/* @ts-ignore icon and imageUrl 'RequireExactlyOne' type not working in spread */}
    <Button {...props}></Button>
  </Box>
);

export const XButton: FC<{ onRemove: () => void }> = ({ onRemove }) => (
  <Tooltip message="Delete this condition" placement="bottom">
    <Box color="text.secondary" sx={{ button: { height: "32px" }, svg: { height: "20px", width: "20px" } }}>
      <IconButton aria-label="Remove condition." icon={CloseSVG} onClick={onRemove} />
    </Box>
  </Tooltip>
);

export const DeleteButton: FC<{ onRemove: () => void }> = ({ onRemove }) => (
  <Tooltip message="Delete this filter" placement="bottom">
    <Box
      color="danger.base"
      sx={{
        button: { height: "32px", _hover: { bg: "danger.background" } },
        "* > svg": { height: "16px", width: "16px" },
      }}
    >
      <IconButton aria-label="Remove condition." icon={DeleteSVG} onClick={onRemove} />
    </Box>
  </Tooltip>
);
