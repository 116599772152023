import { useEffect, useMemo, useState } from "react";

import { Column, Row, SearchInput, StatusBadge, Text, Tooltip } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useNavigate } from "react-router-dom";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { PermissionedLinkButton } from "src/components/permissioned-button";
import { GoalsOrderBy, OrderBy, ResourcePermissionGrant, useGoalsQuery } from "src/graphql";
import { Pagination, SortOption, Table, TableColumn, useTableConfig, useTableSort } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { openUrl } from "src/utils/urls";

const initialSort: SortOption<keyof GoalsOrderBy> = {
  key: "updated_at",
  direction: OrderBy.Desc,
  label: "Recently updated",
};
const sortOptions: SortOption<keyof GoalsOrderBy>[] = [
  { key: "name", direction: OrderBy.Asc, label: "Name A -> Z" },
  { key: "name", direction: OrderBy.Desc, label: "Name Z -> A" },
  { key: "parent_model", direction: OrderBy.Asc, label: "Parent model A -> Z" },
  { key: "parent_model", direction: OrderBy.Desc, label: "Parent model Z -> A" },
  { key: "created_at", direction: OrderBy.Desc, label: "Newest" },
  { key: "created_at", direction: OrderBy.Asc, label: "Oldest" },
];

export const Metrics = () => {
  const { appEnableGoals } = useFlags();
  const navigate = useNavigate();

  const [search, setSearch] = useState("");

  const { limit, offset, page, setPage } = useTableConfig<GoalsOrderBy>();
  const orderBy = useTableSort<GoalsOrderBy>(initialSort, sortOptions);

  const goalsQuery = useGoalsQuery(
    {
      filters: { name: { _ilike: `%${search}%` } },
      offset,
      limit,
      orderBy,
    },
    {
      notifyOnChangeProps: "tracked",
      keepPreviousData: true,
    },
  );

  const goals = goalsQuery.data?.goals ?? [];
  const goalsCount = goalsQuery.data?.goals_aggregate.aggregate?.count ?? 0;

  const goalsWithEventNames = useMemo(
    () =>
      goals.map((goal) => {
        const event = goal.parent_model.relationships?.find(
          ({ to_model: { id, event } }) => event && id === goal.config?.eventModelId,
        );
        return { ...goal, eventName: event?.to_model.name };
      }),
    [goals],
  );

  useEffect(() => {
    if (!appEnableGoals) {
      navigate("/schema/parent-models");
    }
  }, [appEnableGoals]);

  const columns: TableColumn[] = [
    {
      name: "Last run",
      cell: ({ total_audiences, total_errors }) => {
        const totalAudiences = total_audiences.aggregate.count;
        const totalErrors = total_errors.aggregate.count;

        if (!totalErrors) {
          return <StatusBadge variant="success">Healthy</StatusBadge>;
        }

        return totalErrors === totalAudiences ? (
          <Tooltip message="All audiences under this metric have errors">
            <StatusBadge variant="error">Failed</StatusBadge>
          </Tooltip>
        ) : (
          <Tooltip message="Some audiences under this metric have errors">
            <StatusBadge variant="warning">Warning</StatusBadge>
          </Tooltip>
        );
      },
    },
    {
      name: "Name",
      cell: ({ name }) => (
        <Text isTruncated fontWeight="medium">
          {name}
        </Text>
      ),
    },
    {
      name: "Parent model",
      cell: ({ parent_model }) => (
        <Row align="center" gap={2} overflow="hidden">
          <IntegrationIcon src={parent_model?.connection?.definition?.icon} name={parent_model?.connection?.definition?.name} />
          <Text isTruncated>{parent_model?.name ?? "Private model"}</Text>
        </Row>
      ),
    },
    {
      name: "Event",
      cell: ({ eventName }) => <Text isTruncated>{eventName}</Text>,
    },
    { ...LastUpdatedColumn, breakpoint: "md" },
  ];

  return (
    <Column>
      <Row justify="space-between" my={8}>
        <SearchInput placeholder="Search by name..." value={search} onChange={(event) => setSearch(event.target.value)} />
        <PermissionedLinkButton
          href="/schema/metrics/new"
          permissions={[{ resource: "audience_schema", grants: [ResourcePermissionGrant.Create] }]}
          variant="primary"
        >
          Add metric
        </PermissionedLinkButton>
      </Row>

      <Table
        columns={columns}
        data={goalsWithEventNames}
        loading={goalsQuery.isLoading || goalsQuery.isPreviousData}
        placeholder={{
          image: searchPlaceholder,
          title: "No metrics",
          error: "Metrics failed to load, please try again",
        }}
        sortOptions={sortOptions}
        onRowClick={({ id }, event) => openUrl(`/schema/metrics/${id}`, navigate, event)}
      />
      <Row justify="flex-end" width="100%" mt={4} px={4}>
        <Pagination count={goalsCount} page={page} rowsPerPage={limit} setPage={setPage} />
      </Row>
    </Column>
  );
};
