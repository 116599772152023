import { Heading, Tabs, Tab, TabList } from "@hightouchio/ui";
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from "react-router-dom";

import { Page } from "src/components/layout";
import { Metrics } from "src/pages/audiences/metrics/metrics";
import { SyncTemplates } from "src/pages/audiences/setup/sync-templates";

export const SchemaSettings = () => {
  return (
    <Routes>
      <Route element={<Layout />}>
        <Route path="/" index element={<Navigate to="sync-templates" replace />} />
        <Route path="/sync-templates" element={<SyncTemplates />} />
        <Route path="/metrics" element={<Metrics />} />
      </Route>
    </Routes>
  );
};

const Layout = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tabRoutes = ["/schema-v2/settings/sync-templates", "/schema-v2/settings/metrics"];

  return (
    <Page title="Schema - Settings" crumbs={[{ label: "Schema", link: "/schema-v2" }, { label: "Settings" }]}>
      <Heading size="xl" mb={4}>
        Settings
      </Heading>
      <Tabs onChange={(index) => navigate(tabRoutes[index]!)} index={tabRoutes.indexOf(location.pathname)}>
        <TabList>
          <Tab>Sync templates</Tab>
          <Tab>Metrics</Tab>
        </TabList>
      </Tabs>
      <Outlet />
    </Page>
  );
};
