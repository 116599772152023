import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const CopyIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3333 5C9.90378 5 9.55556 5.34822 9.55556 5.77778V14.6667C9.55556 15.0962 9.90378 15.4444 10.3333 15.4444H17.4444C17.874 15.4444 18.2222 15.0962 18.2222 14.6667V8.85866L14.3636 5H10.3333ZM7.55556 5.77778C7.55556 4.24365 8.79921 3 10.3333 3H14.4096C14.9106 3 15.391 3.19901 15.7452 3.55324L19.669 7.47699C20.0232 7.83122 20.2222 8.31167 20.2222 8.81263V14.6667C20.2222 16.2008 18.9786 17.4444 17.4444 17.4444H16.6667V18C16.6667 19.6569 15.3235 21 13.6667 21H6.77778C5.24365 21 4 19.7563 4 18.2222V9.55556C4 7.8987 5.34315 6.55556 7 6.55556H7.55556V5.77778ZM7.55556 8.55556H7C6.44772 8.55556 6 9.00327 6 9.55556V18.2222C6 18.6518 6.34822 19 6.77778 19H13.6667C14.219 19 14.6667 18.5523 14.6667 18V17.4444H10.3333C8.79921 17.4444 7.55556 16.2008 7.55556 14.6667V8.55556Z"
      />
    </Icon>
  );
};

export const CopySVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3333 5C9.90378 5 9.55556 5.34822 9.55556 5.77778V14.6667C9.55556 15.0962 9.90378 15.4444 10.3333 15.4444H17.4444C17.874 15.4444 18.2222 15.0962 18.2222 14.6667V8.85866L14.3636 5H10.3333ZM7.55556 5.77778C7.55556 4.24365 8.79921 3 10.3333 3H14.4096C14.9106 3 15.391 3.19901 15.7452 3.55324L19.669 7.47699C20.0232 7.83122 20.2222 8.31167 20.2222 8.81263V14.6667C20.2222 16.2008 18.9786 17.4444 17.4444 17.4444H16.6667V18C16.6667 19.6569 15.3235 21 13.6667 21H6.77778C5.24365 21 4 19.7563 4 18.2222V9.55556C4 7.8987 5.34315 6.55556 7 6.55556H7.55556V5.77778ZM7.55556 8.55556H7C6.44772 8.55556 6 9.00327 6 9.55556V18.2222C6 18.6518 6.34822 19 6.77778 19H13.6667C14.219 19 14.6667 18.5523 14.6667 18V17.4444H10.3333C8.79921 17.4444 7.55556 16.2008 7.55556 14.6667V8.55556Z"
    />
  </svg>
);
