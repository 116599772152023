import { FC, useEffect, useState } from "react";

import { XMarkIcon } from "@heroicons/react/24/outline";
import {
  Spinner,
  Switch,
  useToast,
  SectionHeading,
  Button,
  Column,
  Row,
  Select,
  Combobox,
  Text,
  TextInput,
  IconButton,
  ButtonGroup,
} from "@hightouchio/ui";
import { yupResolver } from "@hookform/resolvers/yup";
import { captureException } from "@sentry/react";
import { merge, set } from "lodash";
import pluralize from "pluralize";
import {
  useForm,
  useFieldArray,
  Controller,
  Control,
  SubmitHandler,
  UseFormWatch,
  UseFormSetValue,
  FieldErrors,
} from "react-hook-form";
import * as y from "yup";

import { Relationship, useRelationshipModelsQuery, useRelationshipsQuery, useUpdateRelationshipsMutation } from "src/graphql";
import { FieldError } from "src/ui/field";
import { BidirectionalArrowIcon } from "src/ui/icons/new-icons";

type Props = {
  sourceId: string;
  modelId: string;
};

export const getDefaultRelationship = (id: string): Relationship => ({
  from: id,
  to: "",
  cardinality: "one-to-many",
  joinMappings: [{ from: "", to: "" }],
  isMergingIntoFrom: false,
  isMergingIntoTo: false,
  fromName: null,
  toName: null,
});

export const RelationshipForm: FC<Readonly<Props>> = ({ modelId, sourceId }) => {
  const { toast } = useToast();
  const { data: relationships } = useRelationshipsQuery(
    { id: String(modelId) },
    { enabled: Boolean(modelId), select: (data) => data.listRelationships.relationships },
  );

  const { data: models } = useRelationshipModelsQuery({ sourceId }, { select: (data) => data.segments });
  const fromModel = models?.find((m) => String(m.id) === String(modelId));

  const updateRelationshipsMutation = useUpdateRelationshipsMutation();

  const {
    control,
    watch,
    setValue,
    reset,
    handleSubmit,
    formState: { isDirty, errors },
  } = useForm<RelationshipFormValues>({
    resolver: validationResolver(formSchema),
    context: models,
    defaultValues: {
      relationships: [getDefaultRelationship(modelId)] as Relationship[],
    },
  });

  const submit: SubmitHandler<RelationshipFormValues> = async (data) => {
    try {
      await updateRelationshipsMutation.mutateAsync({
        modelId: String(modelId),
        relationships: data.relationships,
      });
      toast({
        id: "update-relationships",
        title: "Relationships updated",
        variant: "success",
      });
    } catch (e) {
      toast({
        id: "update-relationships",
        title: "Relationships failed to update",
        variant: "error",
      });
      captureException(e);
    }
  };

  useEffect(() => {
    if (relationships) {
      reset({ relationships: (relationships?.length ? relationships : [getDefaultRelationship(modelId)]) as Relationship[] });
    }
  }, [relationships]);

  return (
    <Column height="100%">
      <Column flex={1} p={6} overflow="auto">
        <Relationships
          sourceId={sourceId}
          fromModel={fromModel}
          control={control}
          watch={watch}
          setValue={setValue}
          errors={errors}
        />
      </Column>
      <Row bg="white" borderTop="1px" borderColor="base.border" p={4}>
        <ButtonGroup>
          <Button
            size="lg"
            variant="primary"
            isLoading={updateRelationshipsMutation.isLoading}
            isDisabled={!isDirty}
            onClick={handleSubmit(submit)}
          >
            Save relationships
          </Button>
          <Button
            size="lg"
            isDisabled={!isDirty}
            onClick={() => {
              reset();
            }}
          >
            Discard changes
          </Button>
        </ButtonGroup>
      </Row>
    </Column>
  );
};

type Column = {
  name: string;
  alias: string | null;
  type: string;
  custom_type: string | null;
};

export const Relationships: FC<
  Readonly<{
    errors: FieldErrors<RelationshipFormValues>;
    sourceId: string;
    control: Control<RelationshipFormValues>;
    setValue: UseFormSetValue<RelationshipFormValues>;
    watch: UseFormWatch<RelationshipFormValues>;
    fromModel?: {
      id: string;
      name: string;
      columns: Column[];
    };
    // toModel is used for creation flows where there is only a single relationship being created
    toModel?: { name: string; columns: Column[] };
  }>
> = ({ fromModel, sourceId, control, watch, setValue, errors, ...props }) => {
  const { data: models } = useRelationshipModelsQuery({ sourceId }, { select: (data) => data.segments });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "relationships",
  });

  const currentRelationships = watch("relationships");

  const hasMergableRelationships = currentRelationships.some(
    (r) => (r.cardinality === "one-to-one" || r.cardinality === "many-to-one") && r.to,
  );

  const header = (
    <Row align="center" justify="space-between">
      <SectionHeading>{pluralize("Relationship", props.toModel ? 1 : 2)}</SectionHeading>
    </Row>
  );

  if (!fromModel) {
    return (
      <Column gap={8} flex={1}>
        {header}
        <Spinner size="lg" m="auto" />
      </Column>
    );
  }

  return (
    <Column gap={8} flex={1} pb={8}>
      <Column gap={4}>
        {header}
        {fields.map(({ id }, index) => {
          return (
            <Column key={id} border="1px" borderColor="base.border" borderRadius="md" p={4} pos="relative">
              <Controller
                name={`relationships.${index}`}
                control={control}
                render={({ field }) => {
                  const otherRelationships = currentRelationships.filter((_, i) => i !== index);
                  const toModels = models?.filter(
                    (m) =>
                      String(m.id) !== String(fromModel?.id) && !otherRelationships.some((r) => String(r.to) === String(m.id)),
                  );
                  const toModel = props.toModel ?? models?.find((m) => String(m.id) === String(field.value.to));
                  return (
                    <>
                      <Row justify="space-between" align="center" width="100%" mb={2}>
                        <Text fontWeight="medium">Relate models</Text>
                        {!props.toModel && (
                          <IconButton
                            aria-label="Remove relationship"
                            icon={XMarkIcon}
                            onClick={() => {
                              remove(index);
                            }}
                          />
                        )}
                      </Row>
                      <Row gap={2} mb={6}>
                        <Row flex={1}>
                          <TextInput isReadOnly value={fromModel.name} width="100%" />
                        </Row>
                        <Controller
                          control={control}
                          name={`relationships.${index}.cardinality`}
                          render={({ field, fieldState: { error } }) => (
                            <Select
                              width="100%"
                              isInvalid={Boolean(error)}
                              options={cardinalityOptions}
                              value={field.value}
                              onChange={field.onChange}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name={`relationships.${index}.to`}
                          render={({ field, fieldState: { error } }) => (
                            <Column flex={1}>
                              {props.toModel ? (
                                <TextInput
                                  width="100%"
                                  isReadOnly
                                  value={toModel?.name ?? ""}
                                  placeholder="Name your model..."
                                />
                              ) : (
                                <Combobox
                                  width="100%"
                                  isInvalid={Boolean(error)}
                                  placeholder="Select a model..."
                                  options={toModels ?? []}
                                  value={field.value}
                                  onChange={field.onChange}
                                  optionLabel={(option) => option.name}
                                  optionValue={(option) => String(option.id)}
                                />
                              )}
                              <FieldError error={error?.message} />
                            </Column>
                          )}
                        />
                      </Row>
                      <Mapper
                        control={control}
                        watch={watch}
                        errors={errors}
                        index={index}
                        toModel={toModel}
                        fromModel={fromModel}
                      />
                    </>
                  );
                }}
              />
            </Column>
          );
        })}
        {!props.toModel && (
          <Row>
            <Button onClick={() => append(getDefaultRelationship(fromModel.id))}>Add relationship</Button>
          </Row>
        )}
      </Column>
      {hasMergableRelationships && (
        <Column gap={4}>
          <SectionHeading>Merge columns</SectionHeading>
          <Column gap={4}>
            {currentRelationships.map((relationship, index) => {
              const toModel = models?.find((m) => m.id == relationship.to);
              if ((relationship.cardinality === "many-to-one" || relationship.cardinality === "one-to-one") && toModel) {
                return (
                  <Row gap={2} align="center" key={index}>
                    <Switch
                      isChecked={relationship.isMergingIntoFrom}
                      onChange={(value) => setValue(`relationships.${index}.isMergingIntoFrom`, value)}
                    />
                    <Text fontWeight="medium">
                      Merge {toModel?.name} columns into {fromModel.name}
                    </Text>
                  </Row>
                );
              }
              return null;
            })}
          </Column>
        </Column>
      )}
    </Column>
  );
};

const Mapper: FC<
  Readonly<{
    errors: FieldErrors<RelationshipFormValues>;
    control: Control<RelationshipFormValues>;
    watch: UseFormWatch<RelationshipFormValues>;
    index: number;
    toModel: { name: string; columns: Column[] } | undefined;
    fromModel: { name: string; columns: Column[] };
  }>
> = ({ control, watch, errors, index, toModel, fromModel }) => {
  const [multipleJoinKeys, setMultipleJoinKeys] = useState(false);
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: `relationships.${index}.joinMappings`,
  });

  const currentJoinMappings = watch(`relationships.${index}.joinMappings`);

  return (
    <Column gap={4}>
      {fields.map((field, nestedIndex) => {
        const mappingError = errors?.relationships?.[index]?.joinMappings?.[nestedIndex];
        const isRemovable = multipleJoinKeys && fields.length > 2 && nestedIndex !== fields.length - 1;
        const otherJoinMappings = currentJoinMappings.filter((_, i) => i !== nestedIndex);
        const fromOptions = fromModel.columns.filter((c) => !otherJoinMappings.some((j) => j.from === c.name));
        const toOptions = toModel?.columns.filter((c) => !otherJoinMappings.some((j) => j.to === c.name)) ?? [];
        return (
          <Column key={field.id}>
            <Row align="flex-start" gap={2} flex={1}>
              <Controller
                control={control}
                name={`relationships.${index}.joinMappings.${nestedIndex}.from`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(error || mappingError)}
                      placeholder={`Select a column from ${fromModel.name}...`}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && nestedIndex === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={fromOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row height="32px" align="center" color="text.secondary">
                <BidirectionalArrowIcon />
              </Row>
              <Controller
                control={control}
                name={`relationships.${index}.joinMappings.${nestedIndex}.to`}
                render={({ field, fieldState: { error } }) => (
                  <Column flex={1}>
                    <Combobox
                      width="100%"
                      isInvalid={Boolean(error || mappingError)}
                      placeholder={toModel?.name ? `Select a column from ${toModel.name}...` : "Select a column..."}
                      isDisabled={!toModel?.name}
                      value={field.value}
                      onChange={(value) => {
                        if (multipleJoinKeys && nestedIndex === fields.length - 1) {
                          append({ from: "", to: "" });
                        }
                        field.onChange(value);
                      }}
                      options={toOptions}
                      optionLabel={(column) => column.alias || column.name}
                      optionValue={(column) => column.name}
                    />
                    <FieldError error={error?.message} />
                  </Column>
                )}
              />
              <Row
                pointerEvents={isRemovable ? "auto" : "none"}
                visibility={isRemovable ? "visible" : "hidden"}
                display={multipleJoinKeys && currentJoinMappings.length > 2 ? "flex" : "none"}
              >
                <IconButton aria-label="Remove join key" icon={XMarkIcon} onClick={() => remove(nestedIndex)} />
              </Row>
            </Row>
            <FieldError error={mappingError?.message} />
          </Column>
        );
      })}

      <Row align="center" gap={2}>
        <Switch
          isChecked={multipleJoinKeys}
          onChange={(value) => {
            if (value) {
              setMultipleJoinKeys(true);
              append({ from: "", to: "" });
            } else {
              setMultipleJoinKeys(false);
              const firstMapping = currentJoinMappings[0];
              if (firstMapping) {
                replace(firstMapping);
              }
            }
          }}
        />
        <Text fontWeight="medium">Multiple join keys</Text>
      </Row>
    </Column>
  );
};

export type RelationshipFormValues = {
  relationships: Relationship[];
};

export const relationshipFormSchema = y.array().of(
  y.object().shape({
    from: y.string().required("Select a model"),
    to: y.string().required("Select a model"),
    cardinality: y.string().required("Select a cardinality"),
    joinMappings: y
      .array()
      .of(
        y.object().shape({
          to: y.string().required("Select a column"),
          from: y.string().required("Select a column"),
        }),
      )
      .required(),
    isMergingIntoFrom: y.boolean(),
    isMergingIntoTo: y.boolean(),
  }),
);

const formSchema = y.object().shape({
  relationships: relationshipFormSchema,
});

const cardinalityOptions = [
  {
    value: "one-to-one",
    label: "1:1",
  },
  { value: "one-to-many", label: "1:many" },
  { value: "many-to-one", label: "many:1" },
];

export const validationResolver = (schema: y.ObjectSchema) => async (data, context, options) => {
  // remove emtpy join mappings
  const filteredData = {
    ...data,
    relationships: data.relationships.map((r) => {
      return { ...r, joinMappings: r.joinMappings.filter((j) => j.from && j.to) };
    }),
  };
  const joinMappingErrors = {};
  for (const [index, relationship] of filteredData.relationships.entries()) {
    const toModel = context.toModel ?? context.models?.find((m) => String(m.id) === String(relationship.to));
    const fromModel = context.models?.find((m) => String(m.id) === String(relationship.from));
    for (const [nestedIndex, mapping] of relationship.joinMappings.entries()) {
      const fromColumn = fromModel?.columns.find((c) => c.name === mapping.from);
      const toColumn = toModel?.columns.find((c) => c.name === mapping.to);
      if (fromColumn && toColumn) {
        const fromType = fromColumn?.custom_type ?? fromColumn?.type;
        const toType = toColumn?.custom_type ?? toColumn?.type;
        if (fromType !== toType) {
          set(joinMappingErrors, `relationships.${index}.joinMappings.${nestedIndex}`, {
            message: `Types must match between columns. Column ${fromColumn.name} is a ${fromType} whereas ${toColumn.name} is a ${toType}.`,
          });
        }
      }
    }
  }
  const hasJoinMappingErrors = Object.keys(joinMappingErrors).length > 0;
  const { values, errors } = await yupResolver(schema)(filteredData, context, options);
  return {
    values: hasJoinMappingErrors ? {} : values,
    errors: merge(errors, joinMappingErrors),
  };
};
