import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const OverflowMenuIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M7.8 12c0 1.105-.85 2-1.9 2-1.05 0-1.9-.895-1.9-2s.85-2 1.9-2c1.05 0 1.9.895 1.9 2Zm5.7 0c0 1.105-.85 2-1.9 2-1.05 0-1.9-.895-1.9-2s.85-2 1.9-2c1.05 0 1.9.895 1.9 2Zm3.8 2c1.05 0 1.9-.895 1.9-2s-.85-2-1.9-2c-1.05 0-1.9.895-1.9 2s.85 2 1.9 2Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  );
};
