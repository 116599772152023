import { isEqual } from "lodash";
import { isPresent } from "ts-extras";

import {
  Condition,
  ConditionType,
  DefaultOperators,
  EventCondition,
  initialEventCondition,
  initialNumberOfCondition,
  initialPropertyCondition,
  initialSetCondition,
  isRelatedColumn,
  isTraitColumn,
  NumberOfCondition,
  PropertyCondition,
  SegmentSetCondition,
} from "src/types/visual";

import { getPropertyNameFromColumn } from "../visual/utils";
import {
  AudienceColumn,
  EventColumn,
  FilterOption,
  GlobalColumn,
  MetadataType,
  PropertyColumn,
  RelationColumn,
  TraitColumn,
} from "./constants";
import { FilterColumnOption } from "./types";

export const getIconAndBackground = (type: FilterOption | null | undefined) => {
  if (!type) {
    return undefined;
  }

  switch (type) {
    case FilterOption.All:
      return { bg: GlobalColumn.bg, icon: GlobalColumn.icon };
    case FilterOption.Property:
      return { bg: PropertyColumn.bg, icon: PropertyColumn.icon };
    case FilterOption.Relation:
      return { bg: RelationColumn.bg, icon: RelationColumn.icon };
    case FilterOption.Event:
      return { bg: EventColumn.bg, icon: EventColumn.icon };
    case FilterOption.Audience:
      return { bg: AudienceColumn.bg, icon: AudienceColumn.icon };
    case FilterOption.Trait:
      return { bg: TraitColumn.bg, icon: TraitColumn.icon };
  }
};

export const getSearchPlaceholderText = (filterType: FilterOption) => {
  switch (filterType) {
    case FilterOption.All:
      return "Search all filters...";
    case FilterOption.Property:
      return "Search properties...";
    case FilterOption.Relation:
      return "Search relations...";
    case FilterOption.Event:
      return "Search events...";
    case FilterOption.Audience:
      return "Search audiences...";
    case FilterOption.Trait:
      return "Search traits...";
  }
};

export const isColumnSelected = <TCondition extends Condition>(
  condition: TCondition | undefined,
  column: FilterColumnOption,
) => {
  if (!condition) {
    return false;
  }

  switch (column.type) {
    case FilterOption.Property:
      return condition.type === ConditionType.Property && isEqual(condition.property, column.value);
    case FilterOption.Relation:
      return condition.type === ConditionType.NumberOf && condition.relationshipId === column.value.relationshipId;
    case FilterOption.Event:
      return (
        condition.type === ConditionType.Event &&
        condition.eventModelId === column.value.eventModelId &&
        condition.relationshipId === column.value.relationshipId
      );
    case FilterOption.Audience:
      return condition.type === ConditionType.SegmentSet && condition.modelId === column.value.modelId;
    case FilterOption.Trait:
      return (
        condition.type === ConditionType.Property &&
        isRelatedColumn(condition.property) &&
        isTraitColumn(condition.property.column) &&
        column.value.column.type === "trait" &&
        condition.property.column.traitDefinitionId === column.value.column.traitDefinitionId
      );
    default:
      return false;
  }
};

export const buildConditionFromColumn = (
  column: FilterColumnOption,
): PropertyCondition | NumberOfCondition | EventCondition | SegmentSetCondition => {
  switch (column.type) {
    case FilterOption.Property:
    case FilterOption.Trait:
      return {
        ...initialPropertyCondition,
        type: ConditionType.Property,
        propertyType: column.propertyType ?? null,
        property: column.value,
        propertyOptions: {
          caseSensitive: column.type === FilterOption.Trait ? undefined : column.case_sensitive ?? undefined,
        },
        operator: column.propertyType ? DefaultOperators[column.propertyType] : null,
        value: null,
      };
    case FilterOption.Relation:
      return {
        ...initialNumberOfCondition,
        relationshipId: column.value.relationshipId,
      };
    case FilterOption.Event:
      return {
        ...initialEventCondition,
        relationshipId: column.value.relationshipId,
        eventModelId: column.value.eventModelId,
      };
    case FilterOption.Audience:
      return {
        ...initialSetCondition,
        modelId: column.value.modelId,
      };
  }
};

export const getSelectedColumn = (condition: Condition | undefined, columnOptions: FilterColumnOption[]) => {
  if (!condition) {
    return null;
  }

  switch (condition.type) {
    case ConditionType.Property:
      // eslint-disable-next-line no-case-declarations
      const traitId =
        isRelatedColumn(condition.property) && isTraitColumn(condition.property.column)
          ? condition.property.column.traitDefinitionId
          : null;

      if (traitId) {
        return columnOptions.find(
          ({ type, value }) =>
            type === FilterOption.Trait && isTraitColumn(value.column) && value.column.traitDefinitionId === traitId,
        );
      }

      return columnOptions.find(({ type, value }) => type === FilterOption.Property && isEqual(value, condition.property));
    case ConditionType.NumberOf:
      return columnOptions.find(
        ({ type, value }) => type === FilterOption.Relation && condition.relationshipId === value.relationshipId,
      );
    case ConditionType.Event:
      return columnOptions.find(
        ({ type, value }) =>
          type === FilterOption.Event &&
          value.eventModelId === condition.eventModelId &&
          value.relationshipId === condition.relationshipId,
      );
    case ConditionType.SegmentSet:
      return columnOptions.find(({ type, value }) => type === FilterOption.Audience && value.modelId === condition.modelId);

    default:
      return null;
  }
};

export const getValueText = (condition: Condition) => {
  switch (condition.type) {
    case ConditionType.Property:
      if (typeof condition.property === "string") {
        return condition.property;
      }

      return getPropertyNameFromColumn(condition.property);
  }
};

export const getSetupLink = (type: FilterOption, parentModelId?: number) => {
  switch (type) {
    case FilterOption.All:
    case FilterOption.Property:
    case FilterOption.Trait:
      return isPresent(parentModelId) ? `/schema/parent-models/${parentModelId}` : "/schema/parent-models";
    case FilterOption.Relation:
      return "/schema/related-models/new";
    case FilterOption.Event:
      return "/schema/events/new";
    case FilterOption.Audience:
      return "/audiences/new";
  }
};

export const getMetadata = (column: FilterColumnOption | undefined | null) => {
  if (!column) {
    return null;
  }

  if (column.type === FilterOption.Trait) {
    // trait column
    return {
      modelName: column.relatedModel?.name ?? "",
      type: column.relatedModel?.event ? MetadataType.Event : MetadataType.Relation,
    };
  } else if (column.type === FilterOption.Property && isRelatedColumn(column.value)) {
    // merged column
    return {
      modelName: column.modelName,
      type: MetadataType.MergedColumn,
    };
  }

  return null;
};
