import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Avatar,
  Button,
  Column,
  EditableHeading,
  Heading,
  Link,
  Row,
  Spinner,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useToast,
} from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { isPresent } from "ts-extras";

import { DetailBar } from "src/components/detail-bar";
import { IntegrationIcon } from "src/components/integrations/integration-icon";
import { Page } from "src/components/layout";
import { DeleteConfirmationModal } from "src/components/modals/delete-confirmation-modal";
import { SidebarForm } from "src/components/page";
import { Permission } from "src/components/permission";
import { PermissionProvider } from "src/contexts/permission-context";
import {
  AudiencesForPriorityListsQuery,
  ResourcePermissionGrant,
  SegmentsBoolExp,
  SegmentsOrderBy,
  useAudiencesForPriorityListsQuery,
  useDeletePriorityListMutation,
  useObjectQuery,
  usePriorityListQuery,
  useUpdatePriorityListMutation,
} from "src/graphql";
import useHasPermission from "src/hooks/use-has-permission";
import * as analytics from "src/lib/analytics";
import { useTableConfig } from "src/ui/table";
import { formatDate } from "src/utils/time";

import { AudienceSelector } from "./audience-selector";
import { DragAndDropEditor } from "./drag-and-drop-editor";

type FormData = { audiences: AudiencesForPriorityListsQuery["segments"][0][] };

export const PriorityList = () => {
  const { appPriorityLists } = useFlags();
  const { priority_list_id } = useParams();
  const navigate = useNavigate();
  const { toast } = useToast();
  const { hasPermission: userCanEdit } = useHasPermission([{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]);

  const [showAddAudiences, setShowAddAudiences] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const updatePriorityListMutation = useUpdatePriorityListMutation();
  const deletePriorityListMutation = useDeletePriorityListMutation();

  const priorityListQuery = usePriorityListQuery({ id: priority_list_id ?? "" }, { enabled: Boolean(priority_list_id) });

  const priorityList = priorityListQuery.data?.priority_lists_by_pk;
  const parentModelId = priorityList?.parent_model.id;
  const priorityListAudienceIds = priorityList?.priority_list_memberships?.map(({ segment }) => segment.id.toString());

  const { limit, offset, orderBy } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 100_000,
  });

  const filters: SegmentsBoolExp = useMemo(() => {
    const hasuraFilters: SegmentsBoolExp = {
      id: { _in: priorityListAudienceIds },
      query_type: { _eq: "visual" },
      visual_query_parent_id: { _eq: parentModelId },
    };

    return hasuraFilters;
  }, [parentModelId, priorityListAudienceIds]);

  // Fetch audiences data to display in priority list
  const audiencesForPriorityListsQuery = useAudiencesForPriorityListsQuery(
    {
      filters,
      limit,
      offset,
      orderBy,
    },
    {
      enabled: isPresent(parentModelId),
    },
  );

  // Get parent model information
  const { data: parentModel, isLoading: parentModelLoading } = useObjectQuery(
    {
      id: String(parentModelId),
    },
    {
      enabled: Boolean(parentModelId),
      select: (data) => data.segments_by_pk,
    },
  );

  const parentModelSource = parentModel?.connection;
  const initialAudiences = useMemo(() => {
    const result: AudiencesForPriorityListsQuery["segments"] = [];

    // preserve rank order sent from server
    priorityList?.priority_list_memberships?.forEach(({ segment: { id } }) => {
      const audience = audiencesForPriorityListsQuery.data?.segments.find(({ id: audienceId }) => id === audienceId);
      if (audience) {
        result.push(audience);
      }
    });

    return result;
  }, [priorityList?.priority_list_memberships, audiencesForPriorityListsQuery.data?.segments]);

  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { isDirty },
  } = useForm<FormData>({
    defaultValues: {
      audiences: initialAudiences,
    },
  });

  const { remove, replace } = useFieldArray({
    control: control,
    name: "audiences",
  });

  const audiences = watch("audiences");

  const trackPriorityListUpdates = () => {
    analytics.track("Priority List Updated", {
      priority_list_id: priority_list_id,
      parent_model_id: parentModelId,
    });
  };

  const save = async (data: FormData) => {
    if (!userCanEdit) {
      toast({
        id: "update-priority-list-toast",
        title: "You don't have permission to edit this priority list",
        variant: "error",
      });

      return;
    }

    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        audienceIds: data.audiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const [name, setName] = useState(priorityList?.name ?? "");

  useEffect(() => {
    setName(priorityList?.name ?? "");
  }, [priorityList?.name]);

  const saveName = async () => {
    if (!userCanEdit) {
      toast({
        id: "update-priority-list-toast",
        title: "You don't have permission to edit this priority list",
        variant: "error",
      });

      return;
    }

    try {
      await updatePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
        name,
        audienceIds: initialAudiences.map(({ id }) => id.toString()),
      });

      trackPriorityListUpdates();

      toast({
        id: "update-priority-list-toast",
        title: "Priority list updated",
        variant: "success",
      });

      priorityListQuery.refetch();
    } catch (error) {
      toast({
        id: "update-priority-list-toast",
        title: "Failed to update this priority list",
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  const deletePriorityList = async () => {
    try {
      await deletePriorityListMutation.mutateAsync({
        id: priority_list_id ?? "",
      });

      analytics.track("Priority List Deleted", {
        priority_list_id,
        parent_model_id: priorityList?.parent_model?.id,
        priority_list_name: priorityList?.name,
      });

      // success toast shown in delete confirmation modal

      navigate("/priority-lists");
    } catch (error) {
      toast({
        id: "delete-model-error",
        title: "Failed to delete this priority list",
        variant: "error",
      });
      Sentry.captureException(error);
    }
  };

  const resetForm = () => {
    reset({
      audiences: initialAudiences,
    });
  };

  useEffect(() => {
    // update form when priority list from db changes
    reset({
      audiences: initialAudiences,
    });
  }, [priorityList, initialAudiences?.length]);

  const priorityListLoading = priorityListQuery.isLoading || audiencesForPriorityListsQuery.isLoading;

  useEffect(() => {
    if (!appPriorityLists) {
      navigate("/audiences");
    }
  }, [appPriorityLists, navigate]);

  const updatedByUsername = priorityList?.updated_by_user?.name || priorityList?.created_by_user?.name;

  return (
    <PermissionProvider permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Preview] }]}>
      <Page
        crumbs={[{ label: "Priority lists", link: "/priority-lists" }, { label: priorityList?.name ?? "Priority list" }]}
        title={`${priorityList?.name ?? "Unnamed priority list"} - Priority lists - Audiences`}
      >
        {priorityListLoading || parentModelLoading ? (
          <Row align="center" flex={1} height="100%" justify="center">
            <Spinner />
          </Row>
        ) : (
          <>
            <Row align="flex-end" justify="space-between" mb={2}>
              <EditableHeading isDisabled={!userCanEdit} size="lg" value={name} onChange={setName} onSubmit={saveName} />

              <PermissionProvider
                fallback={<Spinner />}
                permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Delete] }]}
              >
                <Button isLoading={deletePriorityListMutation.isLoading} onClick={() => setShowDeleteModal(true)}>
                  Delete
                </Button>
              </PermissionProvider>
            </Row>
            <DetailBar>
              <Row align="center" gap={2} flexShrink={0}>
                <IntegrationIcon src={parentModelSource?.definition?.icon} name={parentModelSource?.definition?.name} />
                <Link href={parentModel?.id ? `/schema/parent-models/${parentModel.id}` : ""}>
                  <Text isTruncated color="inherit">
                    {parentModel?.name ?? "Private model"}
                  </Text>
                </Link>
              </Row>
              <Row align="center" gap={2} flexShrink={0}>
                <Text>Last updated:</Text>
                <Row gap={1} align="center">
                  {formatDate((priorityList?.updated_at || priorityList?.created_at)!)}
                  {updatedByUsername && (
                    <>
                      <Text>by</Text>
                      <Avatar size="xs" name={updatedByUsername} />
                    </>
                  )}
                </Row>
              </Row>
            </DetailBar>

            <Tabs>
              <TabList>
                <Tab>Audiences</Tab>
              </TabList>

              <TabPanels>
                <TabPanel>
                  <Row flex={1} justify="space-between" minHeight={0} mt={6} gap={8}>
                    <Column flex={1} minHeight={0}>
                      {priorityListQuery.error || audiencesForPriorityListsQuery.error ? (
                        <Column flex={1} minHeight={0}>
                          <Alert
                            message="There was a problem fetching this priority list"
                            title="There was a problem"
                            variant="error"
                          />
                        </Column>
                      ) : (
                        <>
                          <Row align="center" justify="space-between">
                            <Heading>Set priority</Heading>
                            <Button onClick={() => setShowAddAudiences(true)}>Add audiences</Button>
                          </Row>
                          <DragAndDropEditor
                            data={audiences}
                            isLoading={audiencesForPriorityListsQuery.isLoading || priorityListQuery.isRefetching}
                            readOnly={!userCanEdit}
                            onRemove={remove}
                            onUpdate={replace}
                          />
                        </>
                      )}
                    </Column>

                    <SidebarForm
                      hideInviteTeammate
                      hideSendMessage
                      buttons={
                        <>
                          <Permission permissions={[{ resource: "audience", grants: [ResourcePermissionGrant.Update] }]}>
                            <Button
                              isDisabled={!isDirty}
                              isLoading={updatePriorityListMutation.isLoading}
                              variant="primary"
                              onClick={handleSubmit(save)}
                            >
                              Save
                            </Button>
                          </Permission>
                          <Button isDisabled={!isDirty} onClick={resetForm}>
                            Cancel
                          </Button>
                        </>
                      }
                      docsUrl={`${import.meta.env.VITE_DOCS_URL}/audiences/priority-lists`}
                      name="adding a priority list"
                    />
                  </Row>
                </TabPanel>
              </TabPanels>
            </Tabs>

            <AudienceSelector
              isOpen={showAddAudiences}
              parentModelId={parentModelId}
              selectedData={audiences}
              onClose={() => setShowAddAudiences(false)}
              onSubmit={(data) => replace(data)}
            />

            <DeleteConfirmationModal
              isOpen={showDeleteModal}
              label="priority list"
              onClose={() => {
                setShowDeleteModal(false);
              }}
              onDelete={deletePriorityList}
            />
          </>
        )}
      </Page>
    </PermissionProvider>
  );
};
