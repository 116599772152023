import { FC } from "react";

import { IconProps, Icon } from "src/ui/icons/icon";

export const BidirectionalArrowIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M8.75 7.00001C8.19772 7.00001 7.75 7.44772 7.75 8.00001C7.75 8.55229 8.19772 9.00001 8.75 9.00001H16.7035L15.0696 10.5172C14.6648 10.893 14.6414 11.5257 15.0172 11.9305C15.393 12.3352 16.0257 12.3586 16.4305 11.9828L19.928 8.73505C19.9358 8.72785 19.9435 8.72053 19.9511 8.71309C19.9593 8.70504 19.9673 8.69685 19.9752 8.68852C20.1407 8.5143 20.244 8.28053 20.2497 8.02268C20.2499 8.01514 20.25 8.00759 20.25 8.00001C20.25 7.74436 20.1541 7.51112 19.9962 7.33432C19.9755 7.31103 19.9535 7.28863 19.9305 7.26721L16.4305 4.01721C16.0257 3.64141 15.393 3.66484 15.0172 4.06955C14.6414 4.47426 14.6648 5.107 15.0696 5.4828L16.7035 7.00001H8.75Z" />
      <path d="M3.75 16C3.75 15.7099 3.87354 15.4486 4.07088 15.266L7.56955 12.0172C7.97426 11.6414 8.60699 11.6648 8.98279 12.0696C9.3586 12.4743 9.33516 13.107 8.93045 13.4828L7.29653 15H15.25C15.8023 15 16.25 15.4477 16.25 16C16.25 16.5523 15.8023 17 15.25 17H7.29654L8.93045 18.5172C9.33516 18.893 9.3586 19.5257 8.98279 19.9305C8.60699 20.3352 7.97426 20.3586 7.56955 19.9828L4.07254 16.7356C3.87423 16.5528 3.75 16.2909 3.75 16Z" />
    </Icon>
  );
};

export const BidirectionalArrowSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.75 7.00001C8.19772 7.00001 7.75 7.44772 7.75 8.00001C7.75 8.55229 8.19772 9.00001 8.75 9.00001H16.7035L15.0696 10.5172C14.6648 10.893 14.6414 11.5257 15.0172 11.9305C15.393 12.3352 16.0257 12.3586 16.4305 11.9828L19.928 8.73505C19.9358 8.72785 19.9435 8.72053 19.9511 8.71309C19.9593 8.70504 19.9673 8.69685 19.9752 8.68852C20.1407 8.5143 20.244 8.28053 20.2497 8.02268C20.2499 8.01514 20.25 8.00759 20.25 8.00001C20.25 7.74436 20.1541 7.51112 19.9962 7.33432C19.9755 7.31103 19.9535 7.28863 19.9305 7.26721L16.4305 4.01721C16.0257 3.64141 15.393 3.66484 15.0172 4.06955C14.6414 4.47426 14.6648 5.107 15.0696 5.4828L16.7035 7.00001H8.75Z" />
    <path d="M3.75 16C3.75 15.7099 3.87354 15.4486 4.07088 15.266L7.56955 12.0172C7.97426 11.6414 8.60699 11.6648 8.98279 12.0696C9.3586 12.4743 9.33516 13.107 8.93045 13.4828L7.29653 15H15.25C15.8023 15 16.25 15.4477 16.25 16C16.25 16.5523 15.8023 17 15.25 17H7.29654L8.93045 18.5172C9.33516 18.893 9.3586 19.5257 8.98279 19.9305C8.60699 20.3352 7.97426 20.3586 7.56955 19.9828L4.07254 16.7356C3.87423 16.5528 3.75 16.2909 3.75 16Z" />
  </svg>
);
