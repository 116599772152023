import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const FilterIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path d="M11.222 16.333h1.556a.78.78 0 0 0 .778-.777.78.78 0 0 0-.778-.778h-1.556a.78.78 0 0 0-.778.778c0 .427.35.777.778.777ZM5 7.778c0 .428.35.778.778.778h12.444A.78.78 0 0 0 19 7.778.78.78 0 0 0 18.222 7H5.778A.78.78 0 0 0 5 7.778Zm3.111 4.666h7.778a.78.78 0 0 0 .778-.777.78.78 0 0 0-.778-.778H8.11a.78.78 0 0 0-.778.778c0 .427.35.777.778.777Z" />
      <path
        fillRule="evenodd"
        d="M4.825 7.778c0-.525.428-.953.953-.953h12.444c.525 0 .953.428.953.953a.955.955 0 0 1-.953.953H5.778a.955.955 0 0 1-.953-.953Zm.953-.603a.605.605 0 0 0-.603.603c0 .33.272.603.603.603h12.444a.605.605 0 0 0 .603-.603.605.605 0 0 0-.603-.603H5.778Zm1.38 4.492c0-.525.429-.953.953-.953h7.778c.524 0 .953.428.953.953a.955.955 0 0 1-.953.952H8.11a.955.955 0 0 1-.953-.952Zm.953-.603a.605.605 0 0 0-.603.603c0 .33.272.602.603.602h7.778a.605.605 0 0 0 .603-.602.605.605 0 0 0-.603-.603H8.11Zm2.159 4.492c0-.525.428-.953.952-.953h1.556c.524 0 .953.428.953.953a.955.955 0 0 1-.953.952h-1.556a.955.955 0 0 1-.952-.952Zm.952-.603a.605.605 0 0 0-.602.603c0 .33.271.602.602.602h1.556a.605.605 0 0 0 .603-.603.605.605 0 0 0-.603-.602h-1.556Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};

export const FilterSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path d="M11.222 16.333h1.556a.78.78 0 0 0 .778-.777.78.78 0 0 0-.778-.778h-1.556a.78.78 0 0 0-.778.778c0 .427.35.777.778.777ZM5 7.778c0 .428.35.778.778.778h12.444A.78.78 0 0 0 19 7.778.78.78 0 0 0 18.222 7H5.778A.78.78 0 0 0 5 7.778Zm3.111 4.666h7.778a.78.78 0 0 0 .778-.777.78.78 0 0 0-.778-.778H8.11a.78.78 0 0 0-.778.778c0 .427.35.777.778.777Z" />
    <path
      fillRule="evenodd"
      d="M4.825 7.778c0-.525.428-.953.953-.953h12.444c.525 0 .953.428.953.953a.955.955 0 0 1-.953.953H5.778a.955.955 0 0 1-.953-.953Zm.953-.603a.605.605 0 0 0-.603.603c0 .33.272.603.603.603h12.444a.605.605 0 0 0 .603-.603.605.605 0 0 0-.603-.603H5.778Zm1.38 4.492c0-.525.429-.953.953-.953h7.778c.524 0 .953.428.953.953a.955.955 0 0 1-.953.952H8.11a.955.955 0 0 1-.953-.952Zm.953-.603a.605.605 0 0 0-.603.603c0 .33.272.602.603.602h7.778a.605.605 0 0 0 .603-.602.605.605 0 0 0-.603-.603H8.11Zm2.159 4.492c0-.525.428-.953.952-.953h1.556c.524 0 .953.428.953.953a.955.955 0 0 1-.953.952h-1.556a.955.955 0 0 1-.952-.952Zm.952-.603a.605.605 0 0 0-.602.603c0 .33.271.602.602.602h1.556a.605.605 0 0 0 .603-.603.605.605 0 0 0-.603-.602h-1.556Z"
      clipRule="evenodd"
    />
  </svg>
);
