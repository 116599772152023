import { FC, useEffect, useState } from "react";

import { Button, Box, Column, Row } from "@hightouchio/ui";
import { Text } from "theme-ui";

import { AudienceComparisonResult } from "src/graphql";
import { InfoIcon, XIcon } from "src/ui/icons";
import { PlusSVG } from "src/ui/icons/new-icons";
import { OverlaySpinner } from "src/ui/loading";
import { NewSelect } from "src/ui/new-select";
import { TextWithTooltip } from "src/ui/text";
import { Tooltip } from "src/ui/tooltip";
import { commaNumber } from "src/utils/numbers";

import { leftCircleBaseColor, overlapBaseColor, rightCircleBaseColor, VennDiagram } from "./venn-diagram";

const columnWidth = 160;

type Props = {
  audienceId: number;
  audienceName: string;
  audiences: { id: number; name: string; parent: { id: number } | null }[];
  comparedAudienceIds: string[];
  comparisonData?: AudienceComparisonResult;
  loading?: boolean;
  parentModelId: number;
  onAddComparison(audienceId: string[]): void;
  onRemoveComparison(id: string): void;
  onClearComparisons(): void;
};

export const AudienceOverlap: FC<Readonly<Props>> = ({
  audienceId,
  audienceName,
  audiences,
  comparedAudienceIds,
  comparisonData,
  loading,
  parentModelId,
  onAddComparison,
  onRemoveComparison,
  onClearComparisons,
}) => {
  const [showCompareList, setShowCompareList] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [toCompare, setToCompare] = useState<number | null>(null);

  const { audienceSize, allAudiencesOverlapSize, comparisons } = comparisonData ?? {};

  const filteredAudienceOptions = audiences.filter(
    (option) =>
      audienceId !== option.id && !comparedAudienceIds.includes(option.id.toString()) && parentModelId === option?.parent?.id,
  );
  const dropdownOptions = filteredAudienceOptions.map(({ name, id }) => ({
    label: name,
    value: id,
    sx: { maxWidth: "350px" },
  }));

  const audienceDictionary = {};
  for (const entry of audiences) {
    audienceDictionary[entry.id] = entry;
  }

  const resetForm = () => {
    setShowForm(false);
    setToCompare(null);
  };

  const clearForm = () => {
    resetForm();
    setShowCompareList(false);
    onClearComparisons();
  };

  const submitCompare = () => {
    if (toCompare) {
      onAddComparison([...comparedAudienceIds, toCompare.toString()]);
      setToCompare(null);
    }
  };

  useEffect(() => {
    resetForm();
  }, [comparisons?.length]);

  return loading || showCompareList || comparedAudienceIds.length > 0 ? (
    <Column sx={{ position: "relative", flex: 1, pb: 5, minHeight: 0, overflowY: "auto" }}>
      {comparisons && (
        <Box sx={{ position: "relative" }}>
          {loading && comparisons.length > 0 && <OverlaySpinner sx={{ zIndex: 3 }} />}
          <Box as="ul" sx={{ p: 0 }}>
            {comparisons.map(({ audienceId, audienceSize: comparedAudienceSize, overlapSize }, index) => {
              const overlapPercentage = audienceSize && audienceSize > 0 ? overlapSize / audienceSize : 0;

              return (
                <Box
                  key={index}
                  as="li"
                  sx={{
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    mb: 4,
                    mx: 4,
                    borderBottom: "small",
                    listStyle: "none",
                    ":last-child": {
                      mb: 0,
                      borderBottom: 0,
                    },
                  }}
                >
                  <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                    <TextWithTooltip color="gray.900" fontWeight="medium" message={audienceDictionary[audienceId]?.name}>
                      {audienceDictionary[audienceId]?.name}
                    </TextWithTooltip>

                    <Box as={Button} aria-label="Remove overlap" border="none" onClick={() => onRemoveComparison(audienceId)}>
                      <XIcon color="base.4" size={16} />
                    </Box>
                  </Row>

                  <Row sx={{ alignItems: "center", justifyContent: "center", py: 4 }}>
                    <VennDiagram
                      baseCircleSize={85}
                      comparedAudienceSize={comparedAudienceSize}
                      currentAudienceSize={audienceSize}
                      sharedMembers={overlapSize}
                    />
                  </Row>

                  <Column sx={{ mb: 4 }}>
                    <Row gap={2} sx={{ alignItems: "center", justifyContent: "space-between" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <Box
                          sx={{
                            flexShrink: 0,
                            height: "12px",
                            width: "12px",
                            borderRadius: "50%",
                            bg: leftCircleBaseColor,
                            mr: 2,
                          }}
                        />

                        <TextWithTooltip color="gray.500" message={audienceName}>
                          {audienceName}
                        </TextWithTooltip>
                      </Row>

                      <Text
                        sx={{
                          display: "flex",
                          width: `${columnWidth}px`,
                          flexShrink: 0,
                          flexWrap: "nowrap",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {typeof audienceSize === "number" ? commaNumber(audienceSize) : "unknown"}
                      </Text>
                    </Row>

                    <Row sx={{ alignItems: "center", justifyContent: "space-between" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <Box
                          sx={{
                            flexShrink: 0,
                            height: "12px",
                            width: "12px",
                            borderRadius: "50%",
                            bg: rightCircleBaseColor,
                            mr: 2,
                          }}
                        />

                        <TextWithTooltip color="gray.500" message={audienceDictionary[audienceId]?.name}>
                          {audienceDictionary[audienceId]?.name}
                        </TextWithTooltip>
                      </Row>

                      <Text
                        sx={{
                          display: "flex",
                          width: `${columnWidth}px`,
                          flexShrink: 0,
                          flexWrap: "nowrap",
                          fontWeight: 500,
                          whiteSpace: "nowrap",
                        }}
                      >
                        {commaNumber(comparedAudienceSize)}
                      </Text>
                    </Row>

                    <Row sx={{ alignItems: "baseline", justifyContent: "space-between" }}>
                      <Row sx={{ alignItems: "center" }}>
                        <Box
                          sx={{
                            flexShrink: 0,
                            height: "12px",
                            width: "12px",
                            borderRadius: "50%",
                            bg: overlapBaseColor,
                            mr: 2,
                          }}
                        />

                        <TextWithTooltip color="gray.500" message="Member overlap">
                          Member overlap
                        </TextWithTooltip>
                      </Row>

                      <Row sx={{ width: `${columnWidth}px`, flexShrink: 0 }}>
                        <Text
                          sx={{
                            display: "flex",
                            flexShrink: 0,
                            flexWrap: "nowrap",
                            whiteSpace: "nowrap",
                            mr: 1,
                            fontWeight: 500,
                          }}
                        >
                          {commaNumber(overlapSize)}
                        </Text>
                        <Text sx={{ fontWeight: 400, whiteSpace: "nowrap" }}>or {(overlapPercentage * 100).toFixed(2)}%</Text>
                      </Row>
                    </Row>
                  </Column>
                </Box>
              );
            })}
          </Box>

          {(comparisons ?? []).length > 1 && (
            <Row
              sx={{
                alignItems: "center",
                justifyContent: "space-between",
                py: 4,
                my: 2,
                mx: 4,
                borderTop: "small",
              }}
            >
              <Row sx={{ alignItems: "center" }}>
                <TextWithTooltip mr={3} color="gray.600" fontWeight="medium" message="Combined overlap">
                  Combined overlap
                </TextWithTooltip>
                <Tooltip
                  popoutSx={{ flexShrink: 0 }}
                  text="This is the total amount of overlap between members of all currently compared audiences."
                >
                  <InfoIcon sx={{ color: "base.4", flexShrink: 0 }} />
                </Tooltip>
              </Row>
              <Text sx={{ color: "base.6", flexShrink: 0, width: `${columnWidth}px`, fontWeight: 500, whiteSpace: "nowrap" }}>
                {commaNumber(allAudiencesOverlapSize ?? 0)} members
              </Text>
            </Row>
          )}
        </Box>
      )}

      <Column sx={{ mx: 4 }}>
        {showForm || loading ? (
          <>
            <NewSelect
              searchable
              loading={loading}
              options={dropdownOptions}
              placeholder="Select an audience to compare"
              sx={{ mb: 4 }}
              value={toCompare}
              onChange={setToCompare}
            />

            <Row>
              <Button isDisabled={!toCompare} isLoading={loading} mr={3} onClick={submitCompare}>
                Continue
              </Button>
              <Button isDisabled={loading} variant="secondary" onClick={resetForm}>
                Cancel
              </Button>
            </Row>
          </>
        ) : (
          <Row sx={{ flexWrap: "wrap", mt: -2 }}>
            <Button icon={PlusSVG} mr={3} mt={2} variant="secondary" onClick={() => setShowForm(true)}>
              Audience
            </Button>
            <Button mt={2} variant="secondary" onClick={clearForm}>
              Clear
            </Button>
          </Row>
        )}
      </Column>
    </Column>
  ) : (
    <Column sx={{ alignItems: "center", justifyContent: "center", height: "100%", px: 2, overflowY: "auto" }}>
      <VennDiagram disableHover baseCircleSize={100} sx={{ my: 8 }} />
      <Text sx={{ color: "base.5", textAlign: "center", mb: 4 }}>
        Compare member overlap between this audience and others you select
      </Text>
      <Row sx={{ justifyContent: "center" }}>
        <Button
          icon={PlusSVG}
          variant="secondary"
          onClick={() => {
            setShowCompareList(true);
            setShowForm(true);
          }}
        >
          Add audience
        </Button>
      </Row>
    </Column>
  );
};
