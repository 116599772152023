import { useMemo, useState } from "react";

import { Box, Column, Heading, Pill, Row, SearchInput, Select, Text } from "@hightouchio/ui";
import { useFlags } from "launchdarkly-react-client-sdk";
import { noop, sortBy } from "lodash";
import { Navigate } from "react-router-dom";

import searchPlaceholder from "src/assets/placeholders/search.svg";
import { Filters } from "src/components/folders/filters";
import { IndividualFolder } from "src/components/folders/folder";
import { Page } from "src/components/layout";
import { SyncsCell } from "src/components/syncs/syncs-cell";
import { SegmentsOrderBy } from "src/graphql";
import { QueryType } from "src/types/models";
import { PageTable, TableColumn, useTableConfig } from "src/ui/table";
import { LastUpdatedColumn } from "src/ui/table/columns/last-updated";
import { useRowSelect } from "src/ui/table/use-row-select";
import { QueryTypeIcon } from "src/utils/models";
import { abbreviateNumber } from "src/utils/numbers";

import { performanceAudiences } from "./audience-data";
import { GoalColumn, PerformanceGraph } from "./performance-graph";

const goalColumns: Record<GoalColumn, string> = {
  revenue: "Revenue",
  averageOrderSize: "Average order size",
  numSignUps: "Number of sign ups",
};

enum SortKeys {
  name = "name",
  numSyncs = "syncs_aggregate.count",
  updatedAt = "updated_at",
  revenue = "totalRevenue",
  averageOrderSize = "averageOrderSizePerMonth",
  numSignUps = "totalSignUps",
}

export const Performance = () => {
  const { appPerformancePage } = useFlags();
  const [goalColumn, setGoalColumn] = useState<GoalColumn>(GoalColumn.Revenue);
  const [search, setSearch] = useState("");

  const { selectedRows, onRowSelect } = useRowSelect();
  const onRowClick = ({ id }) => onRowSelect(id);
  const { orderBy, sortKey, sortDirection, onSort } = useTableConfig<SegmentsOrderBy>({
    defaultSortKey: "updated_at",
    limit: 5,
    sortOptions: Object.values(SortKeys),
  });

  const audiences = performanceAudiences;
  const selectedAudiences = audiences?.filter(({ id }) => selectedRows.includes(id));

  const getGoalSortignColumnName = (selectedColumn: GoalColumn) => {
    switch (selectedColumn) {
      case GoalColumn.Revenue:
        return "totalRevenue";
      case GoalColumn.AverageOrderSize:
        return "averageOrderSizePerMonth";
      case GoalColumn.NumberOfSignUps:
        return "totalSignUps";
      default:
        return "";
    }
  };

  const columns = useMemo(
    (): TableColumn[] => [
      {
        name: "Name",
        sortDirection: orderBy?.name,
        onClick: () => onSort(SortKeys.name),
        cell: ({ name, query_runs }) => (
          <Column gap={1} overflow="hidden">
            <Text isTruncated fontWeight="medium">
              {name}
            </Text>
            <Row>
              <Pill>
                <Row gap={2} align="center" whiteSpace="nowrap">
                  <QueryTypeIcon type={QueryType.Visual} />
                  <Text color="text.secondary" size="sm">
                    {query_runs[0]?.size ? abbreviateNumber(query_runs[0].size) : "Unknown size"}
                  </Text>
                </Row>
              </Pill>
            </Row>
          </Column>
        ),
      },
      {
        name: goalColumns[goalColumn],
        sortDirection: orderBy?.[getGoalSortignColumnName(goalColumn)],
        onClick: () => onSort(SortKeys[goalColumn]),
        max: ".5fr",
        cell: ({ totalRevenue, totalSignUps, averageOrderSizePerMonth }) => (
          <>
            {goalColumn === GoalColumn.Revenue && <Text>{abbreviateNumber(totalRevenue)}</Text>}
            {goalColumn === GoalColumn.AverageOrderSize && <Text>{abbreviateNumber(averageOrderSizePerMonth)}</Text>}
            {goalColumn === GoalColumn.NumberOfSignUps && <Text>{abbreviateNumber(totalSignUps)}</Text>}
          </>
        ),
      },
      {
        name: "Syncs",
        sortDirection: orderBy?.syncs_aggregate?.count,
        onClick: () => onSort(SortKeys.numSyncs),
        max: "max-content",
        min: "232px",
        disabled: ({ syncs }) => Boolean(syncs?.length),
        cell: ({ syncs }) => {
          return <SyncsCell syncs={syncs} />;
        },
      },
      {
        ...LastUpdatedColumn,
        sortDirection: orderBy?.updated_at,
        onClick: () => onSort(SortKeys.updatedAt),
        breakpoint: "lg",
      },
    ],
    [orderBy, goalColumn, onSort],
  );

  const placeholder = {
    image: searchPlaceholder,
    title: "No audiences found",
    error: "Audiences failed to load, please try again.",
  };

  const sortedAudiences = useMemo(() => {
    if (!sortKey) {
      return audiences;
    }

    // filter on search too
    const sortedData = sortBy(audiences, sortKey).filter(({ name }) =>
      name.toLowerCase().includes(search.trim().toLowerCase()),
    );

    // need to reverse for number columns
    if (![SortKeys.name, SortKeys.updatedAt].includes(sortKey as SortKeys)) {
      sortedData.reverse();
    }

    if (sortDirection === "desc") {
      return sortedData;
    }

    sortedData.reverse();

    return sortedData;
  }, [audiences, search, sortKey, sortDirection]);

  if (!appPerformancePage) {
    return <Navigate to="/" />;
  }

  return (
    <Page
      sidebar={
        <>
          <Row pr={6} pl={2}>
            <SearchInput
              placeholder="Search all audiences..."
              value={search ?? ""}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </Row>
          <Column px={2} overflow="auto">
            <IndividualFolder
              count={600}
              depth={-1}
              isOpen
              isSelected
              name="All audiences"
              setSelectedFolder={noop}
              onClick={noop}
            />
            <IndividualFolder count={4} depth={0} isSelected={false} name="Folder 1" setSelectedFolder={noop} onClick={noop} />
            <IndividualFolder count={3} depth={0} isSelected={false} name="Folder 2" setSelectedFolder={noop} onClick={noop} />

            <Box borderBottom="1px solid" borderColor="base.divider" my={4} />

            <Filters
              clearFilters={noop}
              createView={() => Promise.resolve()}
              creatingView={false}
              deleteView={noop}
              filters={[
                {
                  title: "Created by",
                  options: [
                    {
                      id: "65093",
                      label: "Andrew Jesien",
                    },
                    {
                      id: "75639",
                      label: "Leonie van der Sleen",
                    },
                    {
                      id: "68336",
                      label: "John Lopus",
                    },
                    {
                      id: "68141",
                      label: "Kevin Tran",
                    },
                  ],
                  selectedOptions: [
                    {
                      id: "65093",
                      label: "Andrew Jesien",
                    },
                    {
                      id: "75639",
                      label: "Leonie van der Sleen",
                    },
                    {
                      id: "68336",
                      label: "John Lopus",
                    },
                  ],
                  setSelectedOptions: noop,
                },
                {
                  title: "Labels",
                  options: [],
                  selectedOptions: [],
                  setSelectedOptions: noop,
                },
              ]}
              resetFilters={noop}
              resource="audience"
              selectView={noop}
              selectedView={null}
              updateCurrentView={noop}
              updatingView={false}
              viewNotSaved={false}
              views={[]}
            />
          </Column>
        </>
      }
      title="Audiences"
    >
      <PageTable
        header={
          <>
            <Heading size="xl">Performance</Heading>
            <Box>
              <Select
                options={[
                  { label: "Revenue", value: GoalColumn.Revenue },
                  { label: "Average order size", value: GoalColumn.AverageOrderSize },
                  { label: "Number of sign ups", value: GoalColumn.NumberOfSignUps },
                ]}
                value={goalColumn}
                width="auto"
                onChange={(value) => setGoalColumn(value ?? GoalColumn.Revenue)}
              />
            </Box>
          </>
        }
        columns={columns}
        data={sortedAudiences}
        placeholder={placeholder}
        selectedRows={selectedRows}
        onRowClick={onRowClick}
        onSelect={onRowSelect}
      />

      {(selectedAudiences ?? [])?.length > 0 && (
        <Column mt={10}>
          <Heading mb={5} ml={8}>
            {goalColumns[goalColumn]}
          </Heading>
          <Box height="50vh">
            <PerformanceGraph audiences={selectedAudiences} graphKey={goalColumn} onRemove={onRowSelect} />
          </Box>
        </Column>
      )}
    </Page>
  );
};
