import { FC, useEffect, useState } from "react";

import { Column, FormField, Heading, Textarea, TextInput, useToast } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useFlags } from "launchdarkly-react-client-sdk";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import { ParentModel, ParentModelSelect } from "src/components/models/parent-model-select";
import { useUser } from "src/contexts/user-context";
import { useAudiencesForGoalsQuery, useCreateGoalMutation } from "src/graphql";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";

import { MetricForm } from "./metric-form";
import { MetricFormData, useMetricForm } from "./use-metric-form";

export const CreateMetric: FC = () => {
  const { appEnableGoals } = useFlags();

  const { toast } = useToast();
  const navigate = useNavigate();
  const { user } = useUser();

  const [step, setStep] = useState(0);
  const [parentModel, setParentModel] = useState<ParentModel | undefined>();
  const [metricName, setMetricName] = useState("");
  const [metricDescription, setMetricDescription] = useState("");
  const [metricFormData, setMetricFormData] = useState<MetricFormData | undefined>();

  const { data: audiences } = useAudiencesForGoalsQuery(
    { parentModelId: parentModel?.id || "" },
    { enabled: Boolean(parentModel?.id), select: (data) => data.segments },
  );

  const { mutateAsync: createGoal } = useCreateGoalMutation();
  const { handleSubmit, ...metricFormProps } = useMetricForm();

  useEffect(() => {
    if (!appEnableGoals) {
      navigate("/schema/parent-models");
    }
  }, [appEnableGoals]);

  const submit = async (metricData: MetricFormData) => {
    if (parentModel?.id === undefined) {
      Sentry.captureException(new Error("[Metrics form]: Parent model not selected"));

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "No parent model selected.",
        variant: "error",
      });

      return;
    }

    if (!metricData) {
      Sentry.captureException(new Error("[Metrics form]: No metric data"));

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Metric configured incorrectly.",
        variant: "error",
      });

      return;
    }

    try {
      const { aggregationType, config, attributionWindow, column } = metricData || {};

      const result = await createGoal({
        input: {
          name: metricName,
          description: metricDescription,
          parent_model_id: parentModel.id,
          aggregation: aggregationType,
          config: { ...config, column },
          attribution_window: attributionWindow,
          enabled: true,
          audience_goals: { data: audiences?.map((audience) => ({ segment_id: audience.id, enabled: true })) ?? [] },
          created_by: user?.id.toString(),
        },
      });

      toast({
        id: "metrics-form-toast",
        title: "Metric created!",
        variant: "success",
      });

      navigate(`/schema/metrics/${result.insert_goals_one?.id}`);
    } catch (error) {
      Sentry.captureException(error);

      toast({
        id: "metrics-form-toast",
        title: "Metric creation error",
        message: "Error creating metric.",
        variant: "error",
      });
    }
  };

  const steps: Step[] = [
    {
      title: "Select parent model",
      continue: "Click on a parent model to continue",
      header: <Heading>Select a parent model</Heading>,
      render: () => (
        <ParentModelSelect
          onSelect={(parentModel) => {
            setParentModel(parentModel);
            setStep(1);
          }}
        />
      ),
    },
    {
      title: "Configure metric",
      header: <Heading>Define how this metric will be calculated</Heading>,
      render: () => <MetricForm metricData={metricFormData} metricFormProps={metricFormProps} parentModel={parentModel} />,
      onContinue: handleSubmit(
        (values) => {
          setMetricFormData(values);
          setStep(2);
        },
        () => {
          toast({
            id: "metrics-form-toast",
            title: "Metric creation error",
            message: "Metric configured incorrectly.",
            variant: "error",
          });
        },
      ),
    },
    {
      title: "Finalize metric",
      disabled: metricName.length === 0,
      header: <Heading>Finalize settings for this metric</Heading>,
      render: () => (
        <Column>
          <FormField label="Metric name">
            <TextInput value={metricName} onChange={(event) => setMetricName(event.target.value)} />
          </FormField>
          <FormField label="Metric description (optional)" mt={4}>
            <Textarea
              placeholder="Descriptions help collaborators understand this metric..."
              value={metricDescription}
              onChange={(event) => setMetricDescription(event.target.value)}
            />
          </FormField>
        </Column>
      ),
    },
  ];

  return (
    <>
      <Helmet>
        <title>New metric</title>
      </Helmet>
      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="New metric"
        onCancel={() => navigate("/schema/metrics")}
        onSubmit={handleSubmit(submit)}
      />
    </>
  );
};
