import { FC } from "react";

import { Text, Link } from "@hightouchio/ui";
import moment from "moment";
import pluralize from "pluralize";

import { useUser } from "src/contexts/user-context";
import { Table } from "src/ui/table";

const placeholder = {
  title: "No workspaces data",
  error: "Usage failed to load, please try again.",
};

export interface WorkspaceMetrics {
  id: string;
  name: string;
  created_at: string;
  destinations?: number | null;
  connections?: number | null;
  models?: number | null;
  audiences?: number | null;
  syncs?: number | null;
  roles?: {
    name: string;
    count?: number | null;
    workspace_id: string;
  }[];
}

interface Props {
  metrics: WorkspaceMetrics[];
  isLoading: boolean;
}

export const WorkspaceMetricsTable: FC<Props> = ({ metrics, isLoading }) => {
  const { workspace } = useUser();
  const usageColumns = [
    {
      name: "Name",
      cell: ({ name, slug }) => {
        return workspace?.slug === slug ? (
          <>
            {name} <Text>(this workspace)</Text>
          </>
        ) : (
          <Link href={`${window.location.origin}/${slug}`}>{name}</Link>
        );
      },
    },
    {
      name: "Members",
      max: "180px",
      cell: ({ roles }) => {
        return (roles ?? []).map((r) => `${r.count} ${pluralize(r.name, r.count)}`).join(", ");
      },
    },
    {
      name: "Destinations",
      max: "180px",
      cell: ({ destinations }) => {
        return destinations || 0;
      },
    },
    {
      name: "Syncs",
      max: "180px",
      cell: ({ syncs }) => {
        return syncs || 0;
      },
    },
    {
      name: "Created",
      max: "240px",
      cell: ({ created_at }) => {
        return moment(parseInt(created_at)).fromNow();
      },
    },
  ];

  return <Table columns={usageColumns} data={metrics} placeholder={placeholder} rowHeight="55px" loading={isLoading} />;
};
