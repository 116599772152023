import { ReactNode, FC, useState } from "react";

import { BoxProps, Column } from "@hightouchio/ui";

import {} from "src/ui/box";
import { FieldError } from "src/ui/field";
import { Popout } from "src/ui/popout";

import { HStack } from "./condition";

const MAX_FILTER_WIDTH = 320;

type Props = {
  children: ReactNode;
  content: ReactNode;
  error?: ReactNode | string | Error;
  sx?: BoxProps;
};

export const Filter: FC<Readonly<Props>> = ({ content, children, error, sx = {} }) => {
  const [open, setOpen] = useState(false);

  return (
    <Column sx={sx}>
      <Popout
        content={
          <HStack gap={2} sx={{ overflowX: "auto" }}>
            {content}
          </HStack>
        }
        contentSx={{ p: 2 }}
        minHeight={32}
        sx={{ flex: 1, maxWidth: "fit-content", alignItems: "flex-start" }}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
      >
        <HStack
          _hover={{
            borderColor: open ? "primary.base" : "gray.border",
            bg: "base.lightBackground",
          }}
          _active={{
            border: "1px solid",
            borderColor: "primary.base",
            boxShadow: error ? "outlineRed" : "outline",
          }}
          px={2}
          gap={1}
          alignItems="center"
          height="32px"
          border="1px solid"
          borderColor={error ? "danger" : open ? "primary.base" : "base.border"}
          boxShadow={error ? "outlineRed" : open ? "outline" : "none"}
          bg="base.lightBackground"
          borderRadius="6px"
          maxWidth={`${MAX_FILTER_WIDTH}px`}
          overflow="hidden"
          transition="all 120ms ease-in"
          whiteSpace="nowrap"
        >
          {children}
        </HStack>
      </Popout>

      {error && <FieldError error={error} sx={{ fontWeight: "semi", fontSize: 0 }} />}
    </Column>
  );
};
