import { mean, sum } from "lodash";

import { allDatesISOFormat } from "./array-of-dates";

export const xTicks = [
  "2022-09-01T07:00:00.000Z",
  "2022-10-01T07:00:00.000Z",
  "2022-11-01T07:00:00.000Z",
  "2022-12-01T07:00:00.000Z",
  "2023-01-01T07:00:00.000Z",
  "2023-02-01T07:00:00.000Z",
  "2023-03-01T07:00:00.000Z",
  new Date().toISOString(),
];

const revenue1 = [
  200014, 200856, 200925, 200996, 202046, 202078, 202215, 202230, 202551, 202953, 202998, 203263, 203303, 203767, 204423,
  204648, 204679, 204884, 205335, 205565, 205947, 206323, 206326, 206523, 207207, 208412, 208529, 208663, 208957, 209016,
  209162, 209358, 209368, 209527, 210099, 210109, 210220, 210226, 210622, 210701, 210792, 210950, 211034, 211105, 211547,
  212459, 212479, 212740, 213427, 213624, 213791, 214203, 214210, 214749, 214978, 215166, 215580, 216541, 216873, 217100,
  217382, 218401, 218524, 218596, 218822, 219020, 219247, 219358, 219539, 228619, 233086, 243778, 251175, 253190, 273087,
  300086, 323909, 327718, 379605, 391420, 396740, 421557, 464928, 491471, 505648, 539240, 540244, 540562, 555164, 562143,
  575878, 584311, 585749, 593323, 642127, 645630, 656456, 656638, 685435, 694088, 697759, 704803, 725758, 731651, 732174,
  745147, 779798, 801638, 806344, 812187, 825462, 845694, 848879, 872979, 873863, 891094, 905953, 906394, 910096, 924286,
  924522, 931722, 932535, 933553, 955317, 966066, 967040, 989928, 992530, 993121, 997313, 999471, 999531, 1048708, 1060785,
  1065481, 1078997, 1084767, 1098805, 1096805, 1097005, 1097555, 1097400, 1095400, 1199231, 1226241, 1231655, 1244457, 1250135,
  1268300, 1270643, 1275232, 1284262, 1286289, 1286970, 1293461, 1299969, 1307455, 1310200, 1313547, 1320120, 1327313, 1327577,
  1372316, 1372318, 1382971, 1396674, 1400723, 1425279, 1429626, 1441487, 1446939, 1482173, 1491195, 1495647, 1510498, 1518146,
  1534137, 1538518, 1547053, 1554738, 1558770, 1589622, 1593970, 1606945, 1674391, 1682848, 1694802, 1700609, 1708942, 1740585,
  1747456, 1774803, 1829572, 1849860, 1850588, 1859137, 1892506, 1906640, 1931860, 1939500, 1939721, 1945407, 1947286, 1972151,
  1983716, 1998047,
];
const revenue2 = [
  63, 463, 526, 1410, 1929, 2307, 2867, 3932, 4005, 4054, 5227, 5243, 6098, 6239, 6465, 9379, 9684, 10177, 10231, 10358, 11293,
  12853, 13173, 13415, 14284, 14346, 19008, 19973, 22423, 24535, 25513, 26495, 29131, 30346, 30482, 31752, 32509, 33297, 34329,
  34713, 34749, 38575, 39031, 39442, 39653, 40232, 40445, 41466, 41634, 42191, 42886, 43070, 43745, 43851, 44751, 46079, 48835,
  50200, 50495, 51195, 51369, 51731, 52833, 52977, 53149, 56894, 58634, 59165, 59743, 61354, 61707, 61935, 63852, 65110, 65381,
  65494, 65654, 65813, 66323, 67466, 67748, 68652, 69029, 70161, 71529, 72527, 73182, 75094, 75655, 75763, 78404, 85915, 86417,
  87028, 87223, 87414, 89880, 90090, 90300, 90656, 94236, 95366, 95565, 96594, 97098, 97219, 102521, 102917, 103160, 103510,
  103751, 103794, 106241, 106343, 106521, 106753, 107303, 111272, 111573, 111738, 113747, 113943, 114961, 115735, 117182,
  117388, 117559, 118123, 118232, 119327, 120921, 122103, 123133, 124669, 125681, 126137, 126267, 126453, 128870, 130391,
  130900, 131188, 131973, 132372, 133579, 134058, 135106, 135761, 135847, 136573, 138878, 141296, 141315, 142039, 142112,
  143156, 143241, 144150, 144459, 145968, 146432, 147138, 147401, 147676, 147767, 149783, 151582, 152140, 152533, 152596,
  153023, 153292, 153774, 157296, 157718, 160798, 160917, 161322, 163168, 163366, 164629, 165053, 165495, 165962, 166398,
  169286, 170581, 172101, 172571, 172769, 172920, 173030, 173474, 174015, 174551, 175524, 176476, 177226, 178157, 180874,
  182396, 183166, 185115, 185590, 186222, 188393, 188618,
];
const revenue3 = [
  164, 1461, 2483, 2765, 3368, 3724, 4595, 5440, 5742, 6533, 6810, 7099, 9633, 10691, 11476, 14493, 14751, 18495, 24163, 26036,
  28399, 31310, 31328, 32887, 33454, 35489, 37006, 37368, 39196, 39519, 40431, 49909, 50043, 50835, 51238, 52129, 52914, 56916,
  57188, 57425, 58110, 58218, 59848, 60920, 64198, 64353, 66973, 70249, 70847, 71045, 72042, 72337, 73466, 74803, 74948, 76591,
  78364, 81631, 82450, 83362, 85802, 85840, 86608, 87642, 87847, 95645, 99063, 99278, 101225, 103079, 108160, 108781, 109903,
  111668, 112443, 112997, 113071, 113450, 114554, 114572, 115354, 117242, 117345, 120130, 124796, 126756, 128864, 129039,
  130515, 131488, 132542, 133235, 136813, 137465, 138224, 138516, 140148, 144500, 145988, 147295, 147430, 147508, 148468,
  150357, 150549, 152644, 152751, 153049, 153834, 160025, 163074, 163222, 163429, 165448, 166949, 168842, 170288, 171035,
  174230, 175132, 176449, 176532, 178098, 178377, 178419, 182452, 184340, 188900, 189434, 189476, 189981, 190219, 190628,
  190874, 193283, 195377, 196407, 197137, 198382, 198426, 200853, 201016, 203630, 204520, 205541, 207062, 209263, 212407,
  212849, 215425, 216810, 219982, 221672, 223834, 224453, 225102, 225735, 227430, 236011, 236477, 237728, 238041, 238946,
  240465, 241041, 243014, 243996, 244222, 244594, 244685, 244711, 247495, 251401, 251779, 252315, 254147, 254493, 257900,
  258801, 259087, 260267, 260466, 261197, 262343, 266504, 267601, 271747, 275258, 275701, 275896, 279080, 280107, 280734,
  281387, 282404, 283664, 285070, 286355, 287689, 288563, 289086, 289757, 290559, 292522, 293515, 295671, 298248,
];
const revenue4 = [
  1100051, 1120603, 1142435, 1162904, 1100154, 1130245, 1124093, 1134457, 1122574, 1172683, 1134962, 1137432, 1108787, 1136746,
  1141775, 1182344, 1148381, 1157061, 1187549, 1199103, 1202141, 1133928, 1168416, 1179001, 1182808, 1175708, 1177151, 1147628,
  1193723, 1187712, 1179179, 1215213, 1205438, 1220333, 1243839, 1207389, 1221951, 1222186, 1233412, 1207512, 1248428, 1233797,
  1234522, 1234688, 1234814, 1235445, 1258909, 1241842, 1243486, 1254862, 1267429, 1218087, 1262991, 1245197, 1237451, 1291570,
  1308076, 1258802, 1278817, 1280362, 1281346, 1293051, 1318367, 1290798, 1301786, 1297241, 1249629, 1303458, 1347995, 1308849,
  1310315, 1290631, 1407143, 1399233, 1320977, 1343474, 1328599, 1330633, 1391970, 1424359, 1351116, 1342760, 1347070, 1347204,
  1350271, 1370561, 1311098, 1401834, 1393920, 1359927, 1413419, 1367803, 1380619, 1372496, 1393124, 1385109, 1377825, 1379321,
  1409435, 1456837, 1388114, 1404543, 1399115, 1399506,

  1421647, 1443753, 1419981, 1462088, 1470526, 1485345, 1519571, 1502332, 1559338, 1561337, 1577879, 1581114, 1588152, 1616601,
  1617197, 1670845, 1698023, 1705784, 1716657, 1748369, 1765369, 1767273, 1821703, 1828761, 1847312, 1848240,

  1860082, 1851838, 1865205, 1897710, 1883269, 1884950, 1911249, 1839984, 1927684, 1933863, 1950229, 1999515, 2000607, 2017654,
  2003955, 2017055, 1986266, 1991181, 1991635, 2007479, 2010243, 2041475, 2023178, 2004377, 1996821, 2027489, 2028930, 2030036,
  2055369, 2020530, 2001432, 1993717, 2066791, 2077400, 2079354, 2084062, 2086041, 2086468, 2088770, 2127271, 2100945, 2102629,
  2081734, 2077338, 2121772, 2126675, 2119347, 2144611, 2098463, 2203004, 2219655, 2211039, 2163874, 2171766, 2182888, 2184633,
  2153493, 2197030, 2197805, 2161499, 2202356, 2219128, 2184180, 2228744, 2229674, 2233822, 2263838, 2264180, 2251181, 2255179,
  2267786, 2275622, 2277851, 2282058, 2282598, 2282642, 2299957,
];

const averageOrderSize1 = [
  30, 31, 31, 31, 31, 31, 31, 31, 32, 32, 33, 33, 33, 33, 33, 34, 34, 34, 34, 35, 35, 35, 35, 35, 36, 36, 36, 37, 37, 37, 38,
  39, 40, 40, 40, 40, 40, 41, 41, 41, 42, 42, 42, 42, 42, 42, 42, 42, 42, 43, 43, 43, 43, 44, 44, 44, 44, 44, 44, 45, 45, 45,
  45, 46, 47, 47, 47, 47, 47, 55, 55, 55, 55, 55, 55, 55, 55, 56, 56, 56, 56, 56, 56, 56, 56, 56, 56, 56, 57, 57, 57, 57, 57,
  57, 57, 57, 57, 57, 57, 58, 58, 58, 55, 55, 55, 58, 58, 58, 59, 59, 59, 59, 59, 59, 59, 59, 59, 59, 60, 60, 60, 60, 60, 60,
  60, 60, 60, 60, 61, 61, 61, 61, 61, 61, 61, 62, 58, 58, 59, 62, 62, 62, 62, 62, 62, 62, 63, 63, 63, 63, 63, 63, 64, 64, 64,
  64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 64, 65, 65, 65, 65, 65, 65, 65, 65, 65, 65, 66, 66, 66, 66, 66, 66, 66, 66, 66,
  67, 67, 67, 67, 67, 67, 68, 68, 68, 68, 68, 68, 69, 69, 69, 69, 69, 69, 69, 69, 69,
];
const averageOrderSize2 = [
  40, 42, 41, 37, 37, 40, 41, 42, 40, 39, 40, 40, 40, 31, 45, 44, 42, 39, 38, 41, 40, 40, 46, 42, 41, 40, 41, 42, 41, 40, 40,
  42, 43, 42, 43, 42, 41, 40, 40, 43, 43, 42, 41, 41, 39, 36, 40, 42, 41, 41, 42, 42, 43, 42, 39, 48, 41, 42, 39, 42, 43, 43,
  55, 42, 42, 41, 40, 42, 42, 43, 42, 43, 44, 44, 48, 42, 41, 43, 43, 43, 42, 42, 41, 41, 40, 40, 41, 41, 42, 41, 43, 44, 44,
  44, 45, 42, 39, 45, 48, 49, 44, 39, 30, 31, 45, 45, 46, 45, 45, 47, 41, 45, 47, 48, 42, 45, 45, 46, 47, 46, 50, 43, 41, 46,
  48, 41, 43, 46, 43, 49, 46, 46, 29, 47, 45, 49, 51, 53, 47, 47, 45, 46, 47, 41, 42, 42, 44, 46, 46, 47, 48, 49, 41, 49, 48,
  50, 48, 50, 48, 48, 48, 47, 48, 47, 47, 49, 49, 50, 50, 48, 47, 50, 51, 51, 48, 49, 49, 50, 44, 49, 43, 49, 49, 48, 50, 49,
  50, 49, 51, 51, 49, 51, 52, 53, 51, 51, 44, 49, 50, 54, 51, 49, 51, 52, 48, 47, 50,
];
const averageOrderSize3 = [
  40, 42, 41, 41, 38, 36, 40, 40, 48, 48, 40, 41, 42, 42, 41, 40, 41, 45, 39, 39, 41, 42, 39, 42, 42, 41, 43, 45, 46, 61, 43,
  43, 50, 49, 46, 44, 45, 44, 43, 43, 44, 41, 46, 47, 44, 44, 43, 44, 45, 46, 45, 46, 49, 49, 47, 50, 51, 45, 45, 42, 47, 46,
  46, 48, 48, 49, 50, 46, 48, 46, 46, 46, 46, 47, 48, 48, 42, 47, 47, 40, 40, 48, 48, 45, 50, 52, 48, 48, 49, 51, 49, 53, 49,
  49, 54, 49, 52, 49, 50, 43, 44, 30, 31, 30, 60, 61, 62, 57, 55, 51, 52, 51, 51, 52, 58, 54, 59, 52, 53, 54, 54, 52, 53, 53,
  53, 59, 60, 49, 44, 58, 59, 54, 20, 54, 55, 53, 53, 58, 54, 55, 54, 59, 54, 54, 55, 56, 53, 52, 56, 57, 55, 55, 55, 59, 56,
  56, 53, 52, 56, 58, 57, 56, 57, 55, 52, 59, 59, 53, 51, 59, 58, 57, 52, 61, 60, 58, 59, 57, 56, 58, 59, 62, 62, 61, 60, 59,
  59, 61, 61, 62, 59, 59, 60, 55, 61, 62, 60, 60, 59, 60, 61, 61, 62, 62, 64, 62, 61,
];
const averageOrderSize4 = [
  66, 64, 62, 66, 64, 63, 62, 66, 66, 65, 62, 65, 68, 62, 64, 60, 66, 68, 61, 68, 68, 65, 67, 67, 67, 68, 65, 66, 68, 68, 65,
  63, 61, 68, 60, 63, 67, 63, 65, 64, 63, 64, 68, 68, 65, 61, 60, 61, 66, 65, 67, 63, 64, 63, 60, 64, 63, 65, 60, 68, 64, 64,
  61, 60, 64, 66, 66, 60, 66, 64, 60, 61, 67, 62, 61, 66, 66, 63, 61, 62, 68, 68, 68, 60, 67, 64, 60, 64, 67, 60, 66, 63, 68,
  64, 68, 68, 64, 66, 62, 61, 68, 63, 63, 64,

  79, 84, 79, 82, 81, 82, 83, 83, 84, 81, 80, 80, 81, 84, 80, 81, 83, 82, 79, 81, 79, 79, 84, 80, 79, 81,

  68, 65, 65, 67, 63, 64, 63, 62, 62, 70, 63, 68, 66, 63, 66, 65, 64, 67, 68, 64, 67, 62, 64, 68, 67, 70, 63, 70, 64, 64, 65,
  64, 70, 63, 66, 66, 70, 65, 68, 62, 68, 66, 65, 64, 64, 64, 69, 64, 65, 69, 66, 70, 69, 62, 70, 69, 69, 67, 64, 67, 64, 62,
  62, 66, 66, 68, 63, 70, 64, 65, 70, 64, 68, 63, 66, 62, 70,
];

const numberSignUps1 = [
  60, 60, 74, 80, 55, 79, 60, 60, 67, 66, 80, 78, 56, 59, 74, 76, 73, 57, 50, 57, 54, 78, 57, 54, 56, 52, 75, 78, 72, 66, 50,
  62, 57, 51, 55, 69, 57, 67, 67, 68, 68, 50, 75, 78, 51, 72, 77, 56, 65, 74, 73, 67, 52, 56, 58, 69, 69, 72, 69, 51, 51, 54,
  66, 80, 65, 53, 73, 67, 74, 52, 55, 57, 60, 50, 50, 74, 78, 54, 70, 66, 65, 50, 53, 76, 72, 57, 78, 69, 70, 56, 65, 65, 74,
  67, 64, 56, 71, 61, 53, 71, 63, 70, 54, 69, 66, 57, 57, 71, 57, 79, 74, 67, 68, 67, 50, 67, 55, 51, 74, 72, 52, 54, 68, 66,
  70, 57, 77, 64, 77, 64, 73, 57, 58, 59, 50, 74, 51, 68, 60, 56, 72, 55, 67, 69, 54, 75, 59, 52, 52, 72, 62, 74, 50, 50, 65,
  74, 68, 70, 64, 61, 79, 70, 60, 50, 71, 67, 65, 54, 65, 55, 67, 57, 57, 70, 72, 59, 75, 77, 58, 70, 50, 56, 59, 65, 70, 71,
  76, 61, 59, 66, 54, 66, 75, 65, 62, 72, 78, 53, 54, 73, 66, 68, 56, 75, 77, 65, 78,
];
const numberSignUps2 = [
  0, 0, 0, 0, 0, 0, 1, 1, 1, 1, 2, 2, 2, 2, 2, 2, 2, 3, 3, 3, 3, 3, 3, 3, 3, 3, 3, 4, 4, 4, 4, 4, 4, 4, 5, 5, 5, 6, 7, 7, 7, 7,
  7, 7, 7, 7, 8, 8, 9, 9, 9, 10, 10, 10, 10, 10, 10, 11, 11, 11, 12, 12, 12, 12, 13, 13, 14, 14, 14, 14, 14, 14, 14, 15, 15, 15,
  15, 15, 15, 16, 16, 16, 16, 17, 17, 18, 18, 18, 18, 19, 19, 19, 19, 20, 20, 20, 21, 21, 21, 21, 21, 22, 22, 22, 22, 23, 23,
  23, 23, 24, 24, 24, 24, 25, 25, 25, 25, 26, 26, 27, 28, 28, 28, 28, 28, 28, 29, 29, 29, 29, 30, 30, 31, 31, 31, 31, 31, 31,
  32, 32, 32, 32, 33, 33, 33, 33, 33, 34, 36, 36, 36, 36, 36, 36, 36, 36, 36, 37, 37, 38, 38, 38, 38, 39, 39, 39, 39, 39, 39,
  39, 39, 40, 40, 40, 41, 41, 41, 41, 42, 42, 42, 43, 43, 43, 43, 43, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 44, 45, 45, 45,
  45, 45, 45, 46, 46, 46, 46,
];
const numberSignUps3 = [
  1, 1, 2, 2, 2, 2, 3, 3, 4, 5, 5, 5, 6, 7, 7, 7, 8, 8, 8, 8, 8, 10, 10, 11, 13, 13, 13, 14, 14, 14, 14, 15, 15, 15, 15, 16, 16,
  16, 17, 18, 18, 20, 20, 20, 21, 22, 22, 23, 23, 23, 23, 25, 26, 26, 26, 26, 27, 27, 28, 28, 28, 28, 28, 29, 30, 30, 30, 30,
  30, 30, 31, 31, 32, 32, 32, 32, 32, 33, 33, 33, 34, 34, 34, 35, 35, 35, 35, 35, 36, 36, 36, 37, 37, 37, 37, 38, 38, 38, 38,
  38, 38, 38, 39, 39, 39, 40, 41, 41, 42, 42, 43, 43, 43, 43, 44, 45, 46, 46, 46, 48, 48, 49, 49, 49, 49, 49, 50, 51, 51, 51,
  51, 52, 52, 53, 53, 53, 54, 54, 54, 55, 55, 55, 55, 56, 56, 57, 58, 59, 59, 60, 61, 61, 62, 63, 63, 63, 64, 64, 64, 65, 65,
  65, 67, 68, 68, 69, 69, 69, 70, 70, 70, 70, 70, 70, 71, 71, 71, 72, 72, 72, 72, 72, 73, 73, 73, 73, 73, 73, 73, 73, 73, 74,
  74, 74, 75, 75, 75, 75, 76, 76, 77, 77, 78, 78, 78, 79, 80,
];
const numberSignUps4 = [
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
  0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
];

export const revenueData = [
  allDatesISOFormat.map((date, index) => ({ date, data: revenue1[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: revenue2[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: revenue3[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: revenue4[index] ?? 0 })),
];

export const averageOrderSizeData = [
  allDatesISOFormat.map((date, index) => ({ date, data: averageOrderSize1[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: averageOrderSize2[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: averageOrderSize3[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: averageOrderSize4[index] ?? 0 })),
];

export const signUpData = [
  allDatesISOFormat.map((date, index) => ({ date, data: numberSignUps1[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: numberSignUps2[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: numberSignUps3[index] ?? 0 })),
  allDatesISOFormat.map((date, index) => ({ date, data: numberSignUps4[index] ?? 0 })),
];

// ------------

const syncs = [
  {
    id: 1,
    status: "success",
    last_run_at: "2023-03-08T18:17:37.477+00:00",
    destination: {
      id: 67622,
      name: "Facebook Custom Audiences",
      slug: "facebook-custom-audiences",
      type: "facebook",
      credential_id: null,
      definition: {
        icon: "https://cdn.sanity.io/images/pwmfmi47/production/a90feacc04182f95fa6daf663a2a9861b470857c-1365x1365.svg",
        name: "Facebook Custom Audiences",
      },
    },
  },
  {
    id: 2,
    status: "success",
    last_run_at: "2023-03-08T18:17:37.477+00:00",
    destination: {
      id: 57795,
      name: "Braze Cohorts",
      slug: "braze-cohorts",
      type: "brazeCohorts",
      credential_id: null,
      definition: {
        icon: "https://cdn.sanity.io/images/pwmfmi47/production/dd757379e84ce72a0ebf486dd63659c00af4abed-512x512.svg",
        name: "Braze Cohorts",
      },
    },
  },
  {
    id: 3,
    status: "success",
    last_run_at: "2023-03-08T18:17:37.477+00:00",
    destination: {
      id: 59635,
      name: "Salesforce",
      slug: "salesforce-isnx2",
      type: "salesforce",
      credential_id: null,
      definition: {
        icon: "https://cdn.sanity.io/images/pwmfmi47/production/e4fa4b01bbc006d21f215f7c629fb4120eaeaeb4-512x512.svg",
        name: "Salesforce",
      },
    },
  },
];

const audience1 = {
  id: 1,
  name: "Adults ages 22 - 34",
  visual_query_filter: {
    conditions: [],
  },
  labels: {},
  connection_id: 10762,
  created_at: "2023-03-09T10:43:54.802192+00:00",
  updated_at: "2023-03-09T10:43:54.802192+00:00",
  created_by_user: {
    name: "Andrew Jesien",
  },
  updated_by_user: null,
  query_runs: [
    {
      size: 1401823,
    },
  ],
  folder: null,
  syncs,
  syncs_aggregate: { count: syncs.length },
  totalRevenue: sum(revenueData[0]?.map(({ data }) => data)),
  totalSignUps: sum(signUpData[0]?.map(({ data }) => data)),
  averageOrderSizePerMonth: mean(averageOrderSizeData[0]?.map(({ data }) => data)).toFixed(2),
  numberOfSignUpsPerDay:
    signUpData[0]?.map(({ date, data }) => ({
      date,
      1: data,
    })) || [],
  averageOrderSizeData:
    averageOrderSizeData[0]?.map(({ date, data }) => ({
      date,
      1: data,
    })) || [],
  revenueData:
    revenueData[0]?.map(({ date, data }) => ({
      date,
      1: data,
    })) || [],
  changeEvents: [
    {
      audienceId: 1,
      label: "Marketing Email Campaign - Buy one get one 50% off",
      date: new Date("October 23 2022").toISOString(),
    },
  ],
};

const audience2 = {
  id: 2,
  name: "Parents with more than one child - Split group A",
  visual_query_filter: {
    conditions: [],
  },
  labels: {},
  connection_id: 10762,
  created_at: "2023-03-09T10:43:54.802192+00:00",
  updated_at: "2023-03-09T10:43:54.802192+00:00",
  created_by_user: {
    name: "Leonie van der Sleen",
  },
  updated_by_user: null,
  query_runs: [
    {
      size: 334919,
    },
  ],
  folder: null,
  syncs: syncs.slice(2, 3),
  syncs_aggregate: { count: syncs.slice(2, 3).length },
  totalRevenue: sum(revenueData[1]?.map(({ data }) => data)),
  totalSignUps: sum(signUpData[1]?.map(({ data }) => data)),
  averageOrderSizePerMonth: mean(averageOrderSizeData[1]?.map(({ data }) => data)).toFixed(2),
  numberOfSignUpsPerDay:
    signUpData[1]?.map(({ date, data }) => ({
      date,
      2: data,
    })) || [],
  averageOrderSizeData:
    averageOrderSizeData[1]?.map(({ date, data }) => ({
      date,
      2: data,
    })) || [],
  revenueData:
    revenueData[1]?.map(({ date, data }) => ({
      date,
      2: data,
    })) || [],
};

const audience3 = {
  id: 3,
  name: "Parents with more than one child - Split group B",
  visual_query_filter: {
    conditions: [],
  },
  labels: {},
  connection_id: 10762,
  created_at: "2023-03-09T10:43:54.802192+00:00",
  updated_at: "2023-03-09T10:43:54.802192+00:00",
  created_by_user: {
    name: "Leonie van der Sleen",
  },
  updated_by_user: null,
  query_runs: [
    {
      size: 334918,
    },
  ],
  folder: null,
  syncs: syncs.slice(2, 3),
  syncs_aggregate: { count: syncs.slice(2, 3).length },
  totalRevenue: sum(revenueData[2]?.map(({ data }) => data)),
  totalSignUps: sum(signUpData[2]?.map(({ data }) => data)),
  averageOrderSizePerMonth: mean(averageOrderSizeData[2]?.map(({ data }) => data)).toFixed(2),
  numberOfSignUpsPerDay:
    signUpData[2]?.map(({ date, data }) => ({
      date,
      3: data,
    })) || [],
  averageOrderSizeData:
    averageOrderSizeData[2]?.map(({ date, data }) => ({
      date,
      3: data,
    })) || [],
  revenueData:
    revenueData[2]?.map(({ date, data }) => ({
      date,
      3: data,
    })) || [],
};

const audience4 = {
  id: 4,
  name: "Holiday Buyers 2022",
  visual_query_filter: {
    conditions: [],
  },
  labels: {},
  connection_id: 10762,
  created_at: "2023-03-09T10:43:54.802192+00:00",
  updated_at: "2023-03-09T10:43:54.802192+00:00",
  created_by_user: {
    name: "John Lopus",
  },
  updated_by_user: null,
  query_runs: [
    {
      size: 2010762,
    },
  ],
  folder: null,
  syncs: syncs.slice(0, 1),
  syncs_aggregate: { count: syncs.slice(0, 1).length },
  totalRevenue: sum(revenueData[3]?.map(({ data }) => data)),
  totalSignUps: sum(signUpData[3]?.map(({ data }) => data)),
  averageOrderSizePerMonth: mean(averageOrderSizeData[3]?.map(({ data }) => data)).toFixed(2),
  numberOfSignUpsPerDay:
    signUpData[3]?.map(({ date, data }) => ({
      date,
      4: data,
    })) || [],
  averageOrderSizeData:
    averageOrderSizeData[3]?.map(({ date, data }) => ({
      date,
      4: data,
    })) || [],
  revenueData:
    revenueData[3]?.map(({ date, data }) => ({
      date,
      4: data,
    })) || [],
};

export const performanceAudiences = [audience1, audience2, audience3, audience4];
