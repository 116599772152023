import { FC, useEffect, useState } from "react";

import { useToast, Column, Button, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/browser";
import { useParams } from "react-router-dom";
import { isPresent } from "ts-extras";

import { getSchemaModelType, getSchemaModelTypeNames } from "src/components/audiences/utils";
import { ExploreWithSave } from "src/components/explore/explore-with-save";
import { Page } from "src/components/layout";
import { Warning } from "src/components/warning";
import { useObjectQuery } from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Modal } from "src/ui/modal";
import { DeleteColumnsError, useModelRun, useQueryState, useUpdateQuery } from "src/utils/models";

export const AudienceObjectQuery: FC = () => {
  const { id } = useParams<{ id?: string }>();
  const { toast } = useToast();
  const { queryState, initQueryState, setSQL, setDBTModel, setTable, isQueryDefined } = useQueryState();

  const { data: object, isLoading: loadingObject } = useObjectQuery(
    {
      id: String(id),
    },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  const update = useUpdateQuery();

  const source = object?.connection;
  const queryType = object?.query_type as QueryType;

  const {
    runQuery,
    getSchema,
    cancelQuery,
    resetRunState,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    rawColumns,
  } = useModelRun(queryType, object?.columns, {
    modelId: object?.id,
    variables: { sourceId: source?.id, ...queryState },
  });

  useEffect(() => {
    initQueryState(object);
  }, [object]);

  const [saveError, setSaveError] = useState<{ deletedColumns: string[] } | null>(null);

  if (loadingObject) {
    return <PageSpinner />;
  }

  if (!object) {
    return <Warning subtitle="This resource may have been deleted" title="Not found" />;
  }

  const type = getSchemaModelType(object);
  const { typeNameCapitalized, typePath } = getSchemaModelTypeNames(type);

  const save = async (data) => {
    try {
      const topKSyncInterval = object?.columns.find((column) => column.top_k_sync_interval)?.top_k_sync_interval;

      await update({
        model: object,
        queryState,
        columns: data?.columns ?? rawColumns,
        topKSyncInterval: isPresent(topKSyncInterval) ? topKSyncInterval : undefined,
      });

      toast({
        id: `save-${type}`,
        title: `${typeNameCapitalized} was updated`,
        variant: "success",
      });
    } catch (error) {
      if (error instanceof DeleteColumnsError) {
        setSaveError({ deletedColumns: error.deletedColumns });
        return;
      }

      toast({
        id: `save-${type}`,
        title: `${typeNameCapitalized} couldn't be updated`,
        variant: "error",
      });

      Sentry.captureException(error);
    }
  };

  return (
    <Page
      title={`${object?.name} - ${typeNameCapitalized} - Audiences`}
      crumbs={[
        {
          label: typeNameCapitalized,
          link: `/schema/${typePath}`,
        },
        {
          label: object?.name ?? "",
          link: `/schema/${typePath}/${id}`,
        },
        {
          label: "Query",
        },
      ]}
    >
      <ExploreWithSave
        cancelQuery={cancelQuery}
        columns={columns}
        error={queryError}
        errorAtLine={queryErrorAtLine}
        getSchema={getSchema}
        isQueryDefined={isQueryDefined}
        isResultTruncated={Boolean(isResultTruncated)}
        loading={queryLoading}
        numRowsWithoutLimit={numRowsWithoutLimit}
        reset={resetRunState}
        rows={rows}
        runQuery={runQuery}
        source={source}
        type={queryType}
        onSave={save}
        {...queryState}
        rowsPerPage={15}
        onDBTModelChange={setDBTModel}
        onSQLChange={setSQL}
        onTableChange={setTable}
      />

      {saveError ? (
        <DeleteColumnModal
          columnNames={saveError.deletedColumns}
          modelName={object?.name ?? ""}
          onReset={() => setSaveError(null)}
        />
      ) : null}
    </Page>
  );
};

const DeleteColumnModal = ({
  modelName,
  columnNames,
  onReset,
}: {
  modelName: string;
  columnNames: string[];
  onReset: () => void;
}) => {
  return (
    <Modal
      footer={
        <>
          <Button variant="secondary" onClick={onReset}>
            OK
          </Button>
        </>
      }
      title="Error saving model"
      sx={{ maxWidth: 1000 }}
      onClose={onReset}
    >
      <Text>
        Your change to the model "<strong>{modelName}</strong>" would remove the following columns:
      </Text>
      <Column gap={1} my={8}>
        {columnNames.map((name) => (
          <Text key={name} fontWeight="semibold">
            {name}
          </Text>
        ))}
      </Column>
      <Text>
        At least one of those columns is used as a key in a model relationship, and saving this change would break that
        relationship. Please remove those columns from your model relationships before changing the model.
      </Text>
    </Modal>
  );
};
