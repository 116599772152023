import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const RefreshIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M11.993 4.081a1 1 0 0 1-.074 1.412L10.245 7l1.674 1.507a1 1 0 1 1-1.338 1.486l-2.5-2.25a1 1 0 0 1 0-1.486l2.5-2.25a1 1 0 0 1 1.412.074Zm.014 10a1 1 0 0 1 1.412-.074l2.5 2.25a1 1 0 0 1 0 1.486l-2.5 2.25a1 1 0 1 1-1.338-1.486L13.755 17l-1.674-1.507a1 1 0 0 1-.074-1.412Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.75 7a1 1 0 0 1 1-1h3.5a7 7 0 0 1 7 7v.25a1 1 0 1 1-2 0V13a5 5 0 0 0-5-5h-3.5a1 1 0 0 1-1-1Zm-4 2.75a1 1 0 0 1 1 1V11a5 5 0 0 0 5 5h3.5a1 1 0 1 1 0 2h-3.5a7 7 0 0 1-7-7v-.25a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
