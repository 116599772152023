import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const GlobeIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        d="M12 19.2a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Zm0 1.8a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
        clipRule="evenodd"
      />
      <path
        fillRule="evenodd"
        d="M13.572 17.559c.665-1.33 1.128-3.293 1.128-5.559s-.463-4.23-1.128-5.559C12.853 5.004 12.194 4.8 12 4.8c-.194 0-.853.204-1.572 1.641C9.763 7.771 9.3 9.734 9.3 12s.463 4.23 1.128 5.559C11.147 18.996 11.806 19.2 12 19.2c.194 0 .853-.204 1.572-1.641ZM12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3s-4.5 4.03-4.5 9 2.015 9 4.5 9Z"
        clipRule="evenodd"
      />
      <path d="M3.9 11.1h16.2v1.8H3.9v-1.8Z" />
    </Icon>
  );
};

export const GlobeSVG = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="currentColor">
    <path
      fillRule="evenodd"
      d="M12 19.2a7.2 7.2 0 1 0 0-14.4 7.2 7.2 0 0 0 0 14.4Zm0 1.8a9 9 0 1 0 0-18 9 9 0 0 0 0 18Z"
      clipRule="evenodd"
    />
    <path
      fillRule="evenodd"
      d="M13.572 17.559c.665-1.33 1.128-3.293 1.128-5.559s-.463-4.23-1.128-5.559C12.853 5.004 12.194 4.8 12 4.8c-.194 0-.853.204-1.572 1.641C9.763 7.771 9.3 9.734 9.3 12s.463 4.23 1.128 5.559C11.147 18.996 11.806 19.2 12 19.2c.194 0 .853-.204 1.572-1.641ZM12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3s-4.5 4.03-4.5 9 2.015 9 4.5 9Z"
      clipRule="evenodd"
    />
    <path d="M3.9 11.1h16.2v1.8H3.9v-1.8Z" />
  </svg>
);
