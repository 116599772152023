import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const Chevron: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M17.707 8.293a1 1 0 0 1 0 1.414l-5 5a1 1 0 0 1-1.414 0l-5-5a1 1 0 0 1 1.414-1.414L12 12.586l4.293-4.293a1 1 0 0 1 1.414 0Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  );
};
