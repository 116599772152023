import { FC } from "react";

import { Box, Row, TextProps, Text, Tooltip, TooltipProps } from "@hightouchio/ui";

import { useContentVisibility } from "src/hooks/use-content-visibility";

type Props = TextProps & TooltipProps;

export const TextWithTooltip: FC<Readonly<Props>> = ({
  children,
  isDisabled = false,
  keyboardShortcut,
  message,
  placement,
  ...props
}) => {
  const { isVisible, ref } = useContentVisibility<HTMLDivElement>();

  return (
    <>
      {/* Remove the `width: max-content` in the span to make ellipsis show */}
      <Row display="flex" alignItems="center" overflow="hidden" sx={{ ">span": { width: "100%", whiteSpace: "nowrap" } }}>
        <Tooltip
          message={message}
          isDisabled={isVisible || isDisabled}
          keyboardShortcut={keyboardShortcut}
          placement={placement}
        >
          {/* Set display to block to make ellipsis show */}
          <Box ref={ref} as={Text} isTruncated display="block" {...props}>
            {children}
          </Box>
        </Tooltip>
      </Row>
    </>
  );
};
