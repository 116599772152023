import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const LightningBoltIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.57 3.803a1 1 0 0 1 .68.947v5h3a1 1 0 0 1 .794 1.607l-6.5 8.5a1 1 0 0 1-1.794-.607v-5h-3a1 1 0 0 1-.794-1.607l6.5-8.5a1 1 0 0 1 1.115-.34ZM8.775 12.25h1.976a1 1 0 0 1 1 1v3.046l3.476-4.546H13.25a1 1 0 0 1-1-1V7.704L8.774 12.25Z"
        clipRule="evenodd"
      />
    </Icon>
  );
};
