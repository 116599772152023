import { FC, useState, useEffect } from "react";

import { Select, Column, useToast, Textarea, FormField, TextInput, Heading, Text } from "@hightouchio/ui";
import Helmet from "react-helmet";
import { useNavigate, useParams } from "react-router-dom";

import { Destinations } from "src/components/clone/destinations";
import { Explore } from "src/components/explore/explore";
import { DraftSubmissionModal } from "src/components/modals/draft-submission-modal";
import { useUser } from "src/contexts/user-context";
import {
  DraftOperation,
  ResourceToPermission,
  useCreateModelMutation,
  useDestinationDefinitionsQuery,
  useModelQuery,
} from "src/graphql";
import { QueryType } from "src/types/models";
import { PageSpinner } from "src/ui/loading";
import { Wizard } from "src/ui/wizard";
import { Step } from "src/ui/wizard/wizard";
import { useModelRun, useModelState, useQueryState } from "src/utils/models";
import { generateSlug, ResourceType, useResourceSlug } from "src/utils/slug";

export const CloneModel: FC = () => {
  const { model_id: id } = useParams<{ model_id: string }>();
  const { toast } = useToast();
  const navigate = useNavigate();
  const { user, workspace } = useUser();
  const { getSlug } = useResourceSlug(ResourceType.Model);
  const [step, setStep] = useState<number>(0);
  const [selectedSyncs, setSelectedSyncs] = useState<Set<string>>(new Set());
  const { queryState, setSQL, setTable, setDBTModel, initQueryState, setCustomQuery, isQueryDefined } = useQueryState();
  const { modelState, setName, setPrimaryKey, initModelState, setDescription } = useModelState();
  const [type, setType] = useState<QueryType | undefined>();
  const [hasQueryColumns, setHasQueryColumns] = useState(false);
  const [submitDraftModalOpen, setSubmitDraftModalOpen] = useState<boolean>(false);

  const { data: model, isLoading: modelLoading } = useModelQuery(
    { id: String(id) },
    { enabled: Boolean(id), select: (data) => data.segments_by_pk },
  );

  const source = model?.connection;
  const syncs = model?.syncs;

  const { mutateAsync: createModel, isLoading: creating } = useCreateModelMutation();

  const goToModel = (id: string) => {
    navigate(`/models/${id}`);
  };

  const { data: destinationDefinitions } = useDestinationDefinitionsQuery(undefined, {
    select: (data) => data.getDestinationDefinitions,
  });

  const create = async () => {
    const slug = await getSlug(modelState?.name);

    const mergedModelColumns = columns?.map((column) => {
      const oldColumn = model?.columns?.find((c) => c.name === column.name);
      return {
        ...column,
        // this doesn't come from the preview query result, it's user defined, so we want to keep it from the old model if it was set.
        semantic_type: oldColumn?.semantic_type,
      };
    });
    const res = await createModel({
      input: {
        slug,
        query_type: type,
        name: modelState?.name,
        description: modelState?.description,
        primary_key: modelState?.primaryKey,
        connection_id: source?.id,
        created_by: user?.id != null ? String(user?.id) : undefined,
        query_dbt_model_id: queryState?.dbtModel?.id,
        query_looker_look_id: queryState?.lookerLook?.id,
        query_table_name: queryState?.table,
        query_raw_sql: queryState?.sql,
        custom_query: queryState?.customQuery,
        columns: { data: mergedModelColumns },
        draft: workspace?.approvals_required,
        matchboosting_enabled: model?.matchboosting_enabled,
        git_sync_metadata:
          type === "dbt"
            ? {
                git_sync_config_id: queryState?.dbtModel?.git_sync_config?.id,
                file_path: queryState?.dbtModel?.original_file_path,
                dbt_model_id: queryState?.dbtModel?.id,
              }
            : null,
        destination_instances: {
          data: selectedSyncs.size
            ? syncs
                ?.filter(({ id }) => selectedSyncs.has(String(id)))
                ?.map(({ destination, config, schedule, sync_alerts, row_threshold_attempted, row_threshold_total }) => ({
                  destination_id: destination?.id,
                  config,
                  schedule,
                  slug: generateSlug(`${modelState?.name}-to-${destination?.name}`),
                  schedule_paused: true,
                  created_by: user?.id != null ? String(user?.id) : undefined,
                  row_threshold_attempted,
                  row_threshold_total,
                  alert_instances: {
                    data: sync_alerts.map((alert) => ({
                      alert_id: alert.id,
                      fatal_error: alert.fatal_error,
                      row_error: alert.row_error,
                    })),
                  },
                })) ?? []
            : [],
        },
      },
    });

    toast({
      id: "clone-model",
      title: `Cloned ${model!.name} and ${selectedSyncs?.size} syncs`,
      variant: "success",
    });

    return res;
  };

  const {
    runQuery,
    cancelQuery,
    getSchema,
    rows,
    numRowsWithoutLimit,
    isResultTruncated,
    columns,
    loading: queryLoading,
    error: queryError,
    errorAtLine: queryErrorAtLine,
    rowsCount,
    asyncPagination,
    page,
    setPage,
  } = useModelRun(type, undefined, {
    modelId: model?.id != null ? String(model?.id) : undefined,
    variables: { sourceId: source?.id, dbtModelId: queryState?.dbtModel?.id, ...queryState },
  });

  useEffect(() => {
    if (model) {
      initQueryState(model);
      initModelState(model);
      setName("");
      setType(model?.query_type as QueryType);
    }
  }, [model]);

  useEffect(() => {
    if (syncs) {
      setSelectedSyncs(new Set(syncs.map((sync) => String(sync.id))));
    }
  }, [syncs]);

  useEffect(() => {
    setHasQueryColumns(false);
  }, [queryState]);

  useEffect(() => {
    if (columns?.length && !queryError) {
      setHasQueryColumns(true);
    }
  }, [rows, columns]);

  if (modelLoading) {
    return <PageSpinner />;
  }

  const steps: Step[] = [
    {
      title: "Change model",
      disabled:
        !isQueryDefined(type) || Boolean(queryError) || (source?.definition?.supportsResultSchema ? false : !hasQueryColumns),
      onContinue: async () => {
        if (source?.definition?.supportsResultSchema && !hasQueryColumns) {
          await getSchema();
        }
        setStep((step) => step + 1);
      },
      render: () => (
        <Explore
          asyncPagination={asyncPagination}
          cancelQuery={cancelQuery}
          columns={columns}
          isQueryDefined={isQueryDefined}
          isResultTruncated={Boolean(isResultTruncated)}
          numRowsWithoutLimit={numRowsWithoutLimit}
          page={page}
          rows={rows}
          rowsCount={rowsCount}
          runQuery={runQuery}
          source={source}
          type={type}
          onPageChange={setPage}
          {...queryState}
          error={queryError}
          errorAtLine={queryErrorAtLine}
          loading={queryLoading}
          rowsPerPage={100}
          onCustomQueryChange={setCustomQuery}
          onDBTModelChange={setDBTModel}
          onSQLChange={setSQL}
          onTableChange={setTable}
          onTypeChange={setType}
        />
      ),
    },
  ];

  if (syncs?.length) {
    steps.push({
      title: "Select destinations",
      header: (
        <Column gap={2}>
          <Heading>Select destinations to clone associated syncs</Heading>
          <Text fontWeight="medium" color="text.secondary">
            New syncs to these destinations will be automatically created using the cloned model. These new syncs will be
            disabled by default.
          </Text>
        </Column>
      ),
      render: () => (
        <Destinations
          definitions={destinationDefinitions}
          selected={selectedSyncs}
          setSelected={setSelectedSyncs}
          syncs={syncs}
        />
      ),
    });
  }

  steps.push({
    title: "Finalize",
    disabled: !modelState?.name || !modelState?.primaryKey,
    submitting: creating,
    header: <Heading>Finalize your settings</Heading>,
    render: () => {
      const columnOptions: { value: string; label: string }[] = columns
        ? columns.map(({ name }) => ({ value: name, label: name }))
        : [];
      return (
        <Column gap={6}>
          <FormField label="Name">
            <TextInput placeholder={model?.name} value={modelState?.name} onChange={(event) => setName(event.target.value)} />
          </FormField>
          <FormField isOptional label="Description">
            <Textarea
              placeholder="Enter a description..."
              value={modelState?.description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </FormField>

          <FormField
            tip="A primary key is column that contains unique values to identify each of the rows (e.g, email, id)."
            label="Primary key"
          >
            <Select
              options={columnOptions}
              placeholder="Select a column..."
              value={modelState?.primaryKey}
              onChange={(value) => {
                setPrimaryKey(value ?? "");
              }}
            />
          </FormField>
        </Column>
      );
    },
  });

  return (
    <>
      <Helmet>
        <title>{model?.name ? `Clone "${model.name}" model` : "Clone model"}</title>
      </Helmet>

      <DraftSubmissionModal
        draft={{
          _set: {
            draft: false, // the draft change is to set the column `draft` to false.
          },
        }}
        getResourceId={async () => {
          const model = await create();
          return model?.insert_segments_one?.id;
        }}
        open={submitDraftModalOpen}
        operation={DraftOperation.Create}
        resource={ResourceToPermission.Model}
        onClose={() => setSubmitDraftModalOpen(false)}
        onSubmit={(id) => {
          goToModel(id);
        }}
      />
      <Wizard
        setStep={setStep}
        step={step}
        steps={steps}
        title="Clone model"
        onCancel={() => {
          navigate(`/models/${id}`);
        }}
        onSubmit={async () => {
          if (workspace?.approvals_required) {
            setSubmitDraftModalOpen(true);
            return;
          }

          const model = await create();
          if (model?.insert_segments_one?.id) {
            goToModel(model?.insert_segments_one.id);
          }
        }}
      />
    </>
  );
};
