import { groupBy } from "lodash";

import { Folder } from "src/components/folders/types";
import { FoldersQuery } from "src/graphql";

import { FilterState } from "./filters";

export const nestFolders = (folders: FoldersQuery["folders"], viewType: "syncs" | "models") => {
  const foldersByParent = groupBy(folders, "parent_id");
  const rootFolders = foldersByParent["null"] || [];

  const nest = (folder: FoldersQuery["folders"][0], depth: number, parentPath: string): Folder => {
    const children = foldersByParent[folder.id]?.sort((a, b) => (a.name > b.name ? 1 : -1)) || [];
    const path = parentPath ? `${parentPath}/${folder.name}` : folder.name;
    const syncsCount = folder.segments.reduce((acc, segment) => {
      return acc + (segment?.destination_instances_aggregate?.aggregate?.count || 0);
    }, 0);
    const count = viewType === "models" ? folder.segments_aggregate.aggregate?.count : syncsCount;

    return {
      id: folder.id,
      name: folder.name,
      depth,
      count,
      path,
      segmentIds: folder.segments.map((segment) => segment.id),
      type: folder.type || "",
      children: children.map((child) => nest(child, depth + 1, path)),
      parentId: folder.parent_id,
    };
  };

  const sumChildren = (folder: Folder) => {
    let count = folder.count || 0;
    for (const child of folder.children) {
      sumChildren(child);
      count += child.count || 0;
    }
    folder.count = count;
    return count;
  };

  const nested = rootFolders.sort((a, b) => (a.name > b.name ? 1 : -1)).map((folder) => nest(folder, 1, ""));
  nested.forEach((folder) => sumChildren(folder));
  return nested;
};

export const flattenFolders = (folders: Folder[]): Folder[] => {
  return folders
    .sort((a, b) => (a.name > b.name ? 1 : -1))
    .reduce((acc, folder) => {
      return [...acc, folder, ...flattenFolders(folder.children)];
    }, [] as Folder[]);
};

export const isFilterActive = (filter: FilterState | undefined): filter is FilterState =>
  Boolean(filter && !filter.isAllSelected && filter.selected.length);
