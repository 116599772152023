export interface HandlerCache {
  get(handler: string, key: string): Promise<any>;
  set(handler: string, key: string, result: any): Promise<void>;
}

/**
 * Generates a unique string key based on destinationId and modelId to be used in handlerCacheKey
 * @param destinationId
 * @param modelId
 * @returns String
 */
export function generateKey(destinationId?: number, modelId?: number): string {
  const key: (number | string)[] = [];
  if (destinationId) key.push(destinationId);
  if (modelId) key.push(modelId);
  return key.join(":");
}

/**
 * Creates a new HandlerCache object for storing data in localStorage for a default of 5 days. The HandlerCache object has two methods: GET and SET
 * GET - Retrieves data from localStorage based on handlerCacheKey
 * SET - Saves data to localStorage with handlerCacheKey
 * @param opts
 * @returns HandlerCache
 */
export const newLocalStorageHandlerCache = (
  opts: { prefix: string; ttl: number } = { prefix: "formkitHandlers", ttl: 5 }, // Default expiration is set to 5 days
): HandlerCache => {
  const handlerCacheKey = (handler: string, key: string) => `${opts.prefix}:${key}:${handler}`;

  return {
    async get(handler: string, key: string) {
      const cacheValue = localStorage.getItem(handlerCacheKey(handler, key));
      if (cacheValue) {
        const { timestamp, data } = JSON.parse(cacheValue);
        const now = Date.now();
        if (now - timestamp <= opts.ttl * 24 * 60 * 60 * 1000) {
          return data;
        }
      }
    },

    async set(handler: string, key: string, result: any): Promise<void> {
      const dataWithTimestamp = {
        data: result,
        timestamp: Date.now(),
      };
      localStorage.setItem(handlerCacheKey(handler, key), JSON.stringify(dataWithTimestamp));
    },
  };
};
