import { Text, Link, Spinner } from "@hightouchio/ui";

import { useDbtAccountsQuery, useDbtCredentialsQuery, useDbtJobsQuery } from "src/graphql";
import { Field } from "src/ui/field";
import { Select } from "src/ui/select";

import { ScheduleManagerProps } from "./schedule-manager";
import { DbtCloudSchedule } from "./types";

interface DbtCloudScheduleProps extends ScheduleManagerProps {
  schedule: DbtCloudSchedule;
}

export const ConfigureDbtCloudSchedule = ({ schedule, setSchedule }: DbtCloudScheduleProps) => {
  const { data: dbtCredentials, isLoading: loadingCredentials } = useDbtCredentialsQuery(undefined, {
    select: (data) => data.dbt_credentials[0],
  });

  const { isLoading: loadingAccounts, data: accountData } = useDbtAccountsQuery(undefined, {
    enabled: Boolean(dbtCredentials?.id),
  });

  const { isLoading: loadingJobs, data: jobData } = useDbtJobsQuery(
    {
      accountId: schedule?.schedule?.account?.id ?? "",
    },
    { enabled: Boolean(dbtCredentials?.id && schedule?.schedule?.account?.id) },
  );

  const accounts = accountData?.getDbtCloudAccounts || [];
  const jobs = jobData?.getDbtCloudJobs || [];

  if (loadingCredentials) {
    return <Spinner />;
  }
  if (!dbtCredentials) {
    return (
      <Text>
        <Link href="/extensions/dbt-cloud/configuration">Add your dbt credentials</Link> before continuing.
      </Text>
    );
  }
  return (
    <>
      <Field label="Account">
        <Select
          isLoading={loadingAccounts}
          options={accounts.map((account) => ({
            label: account.name,
            value: account,
          }))}
          placeholder="Select an account"
          value={
            schedule?.schedule?.account && {
              label: schedule.schedule.account.name,
              value: schedule.schedule.account,
            }
          }
          width="300px"
          onChange={(selected) => {
            setSchedule({
              ...schedule,
              schedule: {
                ...schedule?.schedule,
                dbtCredentialId: dbtCredentials.id,
                account: {
                  id: selected.value.id,
                  name: selected.value.name,
                },
              },
            });
          }}
        />
      </Field>

      <Field label="Job">
        <Select
          isLoading={loadingJobs}
          options={jobs.map((job) => ({
            label: job.name,
            value: job,
          }))}
          placeholder="Select a job"
          value={
            schedule?.schedule?.job && {
              label: schedule.schedule.job.name,
              value: schedule.schedule.job,
            }
          }
          width="300px"
          onChange={(selected) => {
            setSchedule({
              ...schedule,
              schedule: {
                ...schedule?.schedule,
                dbtCredentialId: dbtCredentials.id,
                job: {
                  id: selected.value.id,
                  name: selected.value.name,
                },
              },
            });
          }}
        />
      </Field>
    </>
  );
};
