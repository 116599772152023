import { FC, useEffect, useState } from "react";

import { Cog6ToothIcon, EyeIcon } from "@heroicons/react/24/outline";
import { Row, Column, Box, Text, Button, Menu, MenuList, Switch, MenuIconButton, ButtonGroup } from "@hightouchio/ui";

import { CustomQuery, CustomQueryForm } from "src/components/sources/forms/custom-query";
import { SqlEditor } from "src/components/sql-editor";
import { ResourcePermissionGrant } from "src/graphql";
import { useUnsavedValue } from "src/hooks/use-unsaved-value";
import * as analytics from "src/lib/analytics";
import { DBTModel, QueryableSource, QueryType, LookerLook, Sigma } from "src/types/models";
import { useObjectDefinitions } from "src/utils/sources";

import { Permission } from "../permission";
import { DBTModelSelector } from "./dbt-model-selector";
import { LookerSelector } from "./looker-selector";
import { QueryTypeMenu } from "./query-type-menu";
import { Results } from "./results";
import { SampleModelSelector } from "./sample-model-selector";
import { SigmaSelector } from "./sigma-selector";
import { TableSelector } from "./table-selector";

export type ExploreProps = {
  type?: QueryType;
  onTypeChange?: (type: QueryType) => void;
  sql?: string | null;
  onSQLChange?: (value: string) => void;
  runQuery: (options: { limit: boolean; disableRowCounter?: boolean }) => Promise<any>;
  cancelQuery: () => void;
  table?: string | null;
  onTableChange?: (table: string) => void;
  dbtModel?: DBTModel;
  lookerLook?: LookerLook;
  onDBTModelChange?: (value: DBTModel) => void;
  onLookerLookChange?: (look: LookerLook) => void;
  sigma?: Sigma;
  onSigmaChange?: (value: Sigma) => void;
  customQuery: CustomQuery | undefined;
  onCustomQueryChange?: (query: CustomQuery) => void;
  source: QueryableSource | undefined | null;
  rowsPerPage?: number;
  error: string | undefined;
  errorAtLine: number | undefined;
  rows?: any;
  rowsCount?: number | null;
  page?: number;
  asyncPagination?: boolean;
  numRowsWithoutLimit?: number | null;
  isResultTruncated: boolean;
  columns?: any;
  loading?: boolean;
  reset?: () => void;
  isQueryDefined: (QueryType) => boolean;
  onPageChange?: (page: number) => void;
  overflow?: string;
};

export const Explore: FC<Readonly<ExploreProps>> = ({
  type,
  onTypeChange,
  runQuery,
  cancelQuery,
  rows,
  rowsCount,
  asyncPagination,
  numRowsWithoutLimit,
  isResultTruncated,
  columns,
  loading,
  error: queryError,
  errorAtLine: queryErrorAtLine,
  source,
  sql,
  onSQLChange,
  customQuery,
  onCustomQueryChange,
  table,
  onTableChange,
  dbtModel,
  onDBTModelChange,
  lookerLook,
  onLookerLookChange,
  sigma,
  onSigmaChange,
  rowsPerPage,
  reset,
  isQueryDefined,
  onPageChange,
  page,
  overflow = "hidden",
}) => {
  const [limit, setLimit] = useState<boolean>(true);
  const [enableRowCounter, setEnableRowCounter] = useState<boolean>(true);

  const {
    loading: objectDefinitionsLoading,
    refetch: refetchObjectDefinitions,
    objectDefinitions,
    error: objectDefinitionsError,
  } = useObjectDefinitions(source?.id?.toString(), source?.definition?.supportedQueries.includes(QueryType.Table));

  useEffect(() => {
    if (queryError) {
      analytics.track("Model Query Error", {
        model_type: source?.definition?.type,
        query_mode: type,
        error: queryError,
      });
    }
  }, [queryError]);

  useEffect(() => {
    const handler = (event) => {
      if ((event.metaKey || event.ctrlKey) && event.key === "Enter") {
        runQuery({ limit, disableRowCounter: !enableRowCounter });
      }
    };
    window.addEventListener("keydown", handler);

    return () => window.removeEventListener("keydown", handler);
  }, [runQuery, limit, enableRowCounter]);

  useEffect(() => {
    return reset;
  }, []);

  const error = queryError || objectDefinitionsError;

  const unsavedSql = useUnsavedValue<string>("sql");

  const showQueryTypeMenu = source && type && onTypeChange && source?.definition?.supportedQueries?.length > 1;

  const previewActions = (
    <ButtonGroup>
      {!source?.definition?.cleanRoomType && (
        <Permission permissions={[{ resource: "source", grants: [ResourcePermissionGrant.Preview], resource_id: source?.id }]}>
          <Button
            icon={EyeIcon}
            isDisabled={loading || !isQueryDefined(type)}
            onClick={async () => {
              await runQuery({ limit, disableRowCounter: !enableRowCounter });
              analytics.track("Model Query Previewed", { model_type: source?.definition?.type, query_mode: type });
            }}
          >
            Preview results
          </Button>
        </Permission>
      )}
      <Menu>
        <Box sx={{ button: { border: "1px", borderColor: "gray.500" } }}>
          <MenuIconButton aria-label="Preview settings" icon={Cog6ToothIcon} />
        </Box>
        <MenuList>
          <Row width="100%" p={2} gap={4}>
            <Column sx={{ gap: 1, maxWidth: "300px" }}>
              <Text fontWeight="medium" size="md">
                Show row count
              </Text>
              <Text size="sm" color="text.secondary">
                Always show a count of the number of rows. Disabling this may result in better performance.
              </Text>
            </Column>
            <Switch isChecked={enableRowCounter} onChange={setEnableRowCounter} />
          </Row>
          <Row width="100%" p={2} gap={4}>
            <Column sx={{ gap: 1, maxWidth: "300px" }}>
              <Text fontWeight="medium" size="md">
                Limit preview to 100 rows
              </Text>
              <Text size="sm" color="text.secondary">
                Limits the returned rows to 100. For most sources, we still show the row count of the full query results.
              </Text>
            </Column>
            <Switch isChecked={limit} onChange={setLimit} />
          </Row>
        </MenuList>
      </Menu>
    </ButtonGroup>
  );

  const resultsNode = (
    <Results
      isLoading={loading}
      asyncPagination={asyncPagination}
      columns={columns}
      error={error}
      isResultTruncated={isResultTruncated}
      numRowsWithoutLimit={numRowsWithoutLimit ?? undefined}
      page={page}
      rows={rows}
      rowsCount={rowsCount}
      rowsPerPage={rowsPerPage}
      onPageChange={onPageChange}
      onCancel={cancelQuery}
    />
  );

  return (
    <Column flex={1} overflow={overflow} gap={8}>
      <Column maxHeight="38vh" gap={4} overflow="hidden" flex={type === QueryType.RawSql ? 1 : undefined}>
        {showQueryTypeMenu && onTypeChange && (
          <QueryTypeMenu supportedQueries={source.definition.supportedQueries} type={type} onChange={onTypeChange} />
        )}
        {(() => {
          if (type === QueryType.Custom && source && onCustomQueryChange) {
            return <CustomQueryForm query={customQuery} source={source} onChange={onCustomQueryChange} />;
          }
          if (type === QueryType.Table && source && onTableChange) {
            return (
              <TableSelector
                source={source}
                loading={objectDefinitionsLoading}
                objectDefinitions={objectDefinitions}
                reload={refetchObjectDefinitions}
                table={table}
                onChange={onTableChange}
              />
            );
          }
          if (type === QueryType.DbtModel && source && onDBTModelChange) {
            return <DBTModelSelector dbtModelId={dbtModel?.id} source={source} onChange={onDBTModelChange} />;
          }
          if (type === QueryType.LookerLook && source && onLookerLookChange) {
            return <LookerSelector lookerLookId={lookerLook?.id} source={source} onChange={onLookerLookChange} />;
          }
          if (type === QueryType.Sigma && source && onSigmaChange) {
            return <SigmaSelector queryError={queryError} sigma={sigma} source={source} onChange={onSigmaChange} />;
          }
          if (source?.definition?.isSampleDataSource === true && source?.definition?.sampleModels?.length) {
            return (
              <SampleModelSelector
                sql={sql ?? ""}
                source={source}
                onSQLChange={onSQLChange}
                unsavedSql={unsavedSql}
                queryErrorAtLine={queryErrorAtLine}
              />
            );
          }
          return (
            <SqlEditor
              highlightErroredLine={queryErrorAtLine}
              placeholder={
                source?.definition?.isSampleDataSource === true
                  ? "Select a sample model or enter a custom query..."
                  : "Enter your query..."
              }
              source={source ?? undefined}
              unsavedValue={unsavedSql}
              value={sql ?? ""}
              onChange={onSQLChange}
            />
          );
        })()}
      </Column>
      <Column gap={4}>
        {previewActions}
        {resultsNode}
      </Column>
    </Column>
  );
};
