import { FC, ReactNode, useEffect, useMemo, useState } from "react";

import { Box, Button, Column, Text, Tooltip } from "@hightouchio/ui";

import { Chevron } from "src/ui/icons/new-icons";
import { load, save } from "src/utils/storage";

import { ITEM_GAP } from "./constants";

const SLIDE_ANIMATION_TIME = 220;

export const CollapsibleSection: FC<{ children: ReactNode; hideTooltip?: boolean; title: string }> = ({
  children,
  hideTooltip,
  title,
}) => {
  const sectionId = useMemo(() => title.toLowerCase().split(" ").join("-") + "-collapsible-section", [title]);
  const [isCollapsed, setCollapsed] = useState(() => load(sectionId) === true);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    // save collapsed state in local storage
    save(sectionId, isCollapsed);
  }, [sectionId, isCollapsed]);

  const toggle = () => {
    setCollapsed((prevValue) => !prevValue);
  };

  const showArrow = () => {
    setIsHovered(true);
  };

  const hideArrow = () => {
    setIsHovered(false);
  };

  return (
    <>
      <Box
        _active={{
          bg: "transparent",
        }}
        _focusVisible={{
          boxShadow: "0 0 0 4px rgba(255, 255, 255, 0.7)",
        }}
        _hover={{
          bg: "transparent",
          svg: {
            display: "block",
          },
        }}
        aria-controls={sectionId}
        aria-label={`${isCollapsed ? "Collapse" : "Expand"} ${title} nav section`}
        as={Button}
        bg="transparent"
        border="none"
        display="flex"
        justifyContent="space-between"
        letterSpacing="0.03em"
        minWidth={0}
        px={[0, 0, 0, 2]}
        textTransform="uppercase"
        onBlur={hideArrow}
        onClick={toggle}
        onFocus={showArrow}
        onMouseEnter={showArrow}
        onMouseLeave={hideArrow}
      >
        <Box as={Text} color="rgba(255,255,255,.5)" display={["none", "none", "none", "block"]} fontWeight="semibold" size="sm">
          {title}
        </Box>

        {(isHovered || isCollapsed) && (
          <>
            <Box border="1px solid rgba(255, 255, 255, 0.5)" display={["block", "block", "block", "none"]} width="12px" />
            <Tooltip isDisabled={hideTooltip} message={title} placement="right">
              <Box
                color="rgba(255, 255, 255, 0.5)"
                sx={{
                  svg: { display: ["block", "block", "block", isCollapsed ? "block" : "none"] },
                }}
                transform={isCollapsed ? "rotate(90deg)" : "none"}
                transition="150ms transform ease-in-out"
              >
                <Chevron />
              </Box>
            </Tooltip>
            <Box border="1px solid rgba(255, 255, 255, 0.5)" display={["block", "block", "block", "none"]} width="12px" />
          </>
        )}

        {!isHovered && !isCollapsed && (
          <Box border="1px solid rgba(255, 255, 255, 0.3)" display={["block", "block", "block", "none"]} width="100%" />
        )}
      </Box>

      <Column
        _focusWithin={{
          overflowY: "visible !important",
        }}
        aria-hidden={isCollapsed}
        gap={ITEM_GAP}
        id={sectionId}
        maxHeight={isCollapsed ? 0 : "max-content"}
        overflowY="hidden"
        transition={`${SLIDE_ANIMATION_TIME}ms all ease-in-out`}
        // prevent focus
        visibility={isCollapsed ? "hidden" : "visible"}
      >
        {children}
      </Column>
    </>
  );
};
