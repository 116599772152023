import { FC } from "react";

import { BoxProps, Column, Row, Text } from "@hightouchio/ui";

import { CheckSVG } from "src/ui/icons/new-icons";
import { TextWithTooltip } from "src/ui/text";

import { CONTENT_SECTION_WIDTH_PX, MetadataIcons, MetadataType } from "./constants";
import { IconBox } from "./icon-box";

type FilterPopoverOptionProps = {
  bg: string;
  description?: string | null;
  icon: JSX.Element;
  isFocused?: boolean;
  isSelected?: boolean;
  label: string;
  metadata?: { modelName: string; type: MetadataType } | null;
  onClick: () => void;
};

export const FilterPopoverOption: FC<Readonly<FilterPopoverOptionProps & BoxProps>> = ({
  bg,
  description,
  icon,
  isSelected = false,
  label,
  metadata,
  sx = {},
  ...props
}) => (
  <Row
    as="button"
    align="center"
    justify="space-between"
    role="tab"
    minWidth={0}
    flex={1}
    _focus={{ boxShadow: "inset 0 0 0 4px #E0F3F5", borderRadius: "6px" }}
    _hover={{ bg: isSelected ? "forest.200" : "base.background" }}
    _active={{
      bg: isSelected ? "forest.300" : "gray.200",
      boxShadow: "none",
    }}
    bg={isSelected ? "primary.background" : "transparent"}
    border="1px solid"
    borderColor="transparent"
    borderRadius="5px"
    height={12}
    gap={4}
    width={`${CONTENT_SECTION_WIDTH_PX}px`}
    p={2}
    outline="none !important"
    transition="border-color 80ms ease-in"
    sx={sx}
    {...props}
  >
    <Row align="center" gap={2} overflowX="hidden">
      <IconBox bg={bg} icon={icon} boxSize={6} iconSize={4} />
      <Column justify="start" overflow="hidden" sx={{ span: { textAlign: "left" } }}>
        <Row gap={2}>
          <TextWithTooltip color="text.primary" message={label}>
            {label}
          </TextWithTooltip>

          {metadata?.modelName && (
            <Row align="center" maxWidth="150px" sx={{ "* > svg": { height: "12px", width: "12px", mr: 1 } }}>
              <Text color="text.tertiary">{MetadataIcons[metadata.type]}</Text>
              <TextWithTooltip color="text.tertiary" message={metadata.modelName}>
                {metadata.modelName}
              </TextWithTooltip>
            </Row>
          )}
        </Row>
        {description && (
          <TextWithTooltip color="text.tertiary" size="sm" message={description}>
            {description}
          </TextWithTooltip>
        )}
      </Column>
    </Row>
    <Row color="primary.pressed" width="24px">
      {isSelected && <CheckSVG />}
    </Row>
  </Row>
);
