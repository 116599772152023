import { FC } from "react";

import { IconProps, Icon } from "../icon";

export const SelectorIcon: FC<Readonly<IconProps>> = (props) => {
  return (
    <Icon {...props}>
      <path
        clipRule="evenodd"
        d="M12 3.6a1.2 1.2 0 0 1 .848.351l3.6 3.6a1.2 1.2 0 1 1-1.697 1.697L12 6.498 9.249 9.249A1.2 1.2 0 0 1 7.55 7.55l3.6-3.6A1.2 1.2 0 0 1 12 3.6ZM7.551 14.751a1.2 1.2 0 0 1 1.698 0L12 17.503l2.752-2.752a1.2 1.2 0 1 1 1.696 1.697l-3.6 3.6a1.2 1.2 0 0 1-1.697 0l-3.6-3.6a1.2 1.2 0 0 1 0-1.697Z"
        fill="currentColor"
        fillRule="evenodd"
      />
    </Icon>
  );
};
