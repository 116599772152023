import { useMemo, useState, FC, useCallback } from "react";

import { PaperAirplaneIcon, TrashIcon, UserPlusIcon } from "@heroicons/react/24/outline";
import { InformationCircleIcon } from "@heroicons/react/24/solid";
import {
  Row,
  Box,
  Column,
  SectionHeading,
  Button,
  SearchInput,
  Tooltip,
  Text,
  Select,
  Menu,
  MenuList,
  MenuItem,
  ButtonGroup,
  useToast,
  MenuActionsButton,
} from "@hightouchio/ui";
import { differenceInDays, format } from "date-fns";
import { useFlags } from "launchdarkly-react-client-sdk";

import { BulkDeleteConfirmationModal, ValidationError } from "src/components/modals/bulk-delete-confirmation-modal";
import { InviteFormModal } from "src/components/modals/invite-modal";
import { Permission } from "src/components/permission";
import { Settings } from "src/components/settings";
import { PermissionProvider } from "src/contexts/permission-context";
import { useUser } from "src/contexts/user-context";
import {
  ResourcePermissionGrant,
  useApproveMembershipMutation,
  useCancelInviteMutation,
  useWorkspaceQuery,
  useMembershipRequestsByWorkspaceQuery,
  usePendingInvitesQuery,
  useRejectMembershipMutation,
  useSendInviteMutation,
  useUpdateUserWorkspaceRoleMutation,
  useDeleteMembersMutation,
  MembershipsOrderBy,
  InitialQuery,
} from "src/graphql";
import { roleDisabled } from "src/pages/settings/roles";
import { Pagination, Table, TableColumn, useTableConfig } from "src/ui/table";
import { useRowSelect } from "src/ui/table/use-row-select";

enum SortKeys {
  Name = "user.name",
  Email = "user.email",
  Role = "role.name",
  CreatedAt = "user.created_at",
}

type Role = InitialQuery["workspaces"][number]["roles"][number];

export const RoleSelector = ({ userId, roleId }) => {
  const { workspace, user } = useUser();
  const { appAllowRoles } = useFlags();

  const { mutateAsync: updateRole, isLoading: loading } = useUpdateUserWorkspaceRoleMutation();

  const roles = useMemo<Role[]>(() => {
    return [...(workspace?.roles ?? [])].sort((a, b) => (Number(a.id) > Number(b.id) ? 1 : -1));
  }, [workspace?.roles]);

  const selectRole = useCallback(
    async (newRoleId) => {
      await updateRole({
        workspaceId: workspace?.id,
        userId,
        roleId: newRoleId,
      });
    },
    [workspace, userId],
  );

  // We cannot edit the role of SSO members directly - we must change their role mapping instead.
  const membership = workspace?.all_memberships.find((membership) => membership.user_id === userId);

  const isEditingMyself = userId === user?.id;
  const isSingleSignOn = Boolean(membership?.is_sso);

  return (
    <Tooltip
      isDisabled={!isEditingMyself && !isSingleSignOn}
      message={isSingleSignOn ? "You can't edit roles of SSO users" : "You can't edit your own role"}
    >
      <Box maxWidth={40}>
        <Select
          isDisabled={isEditingMyself || isSingleSignOn}
          isLoading={loading}
          isOptionDisabled={(role) => !workspace || roleDisabled(appAllowRoles, workspace, role)}
          optionLabel={(role) => role.name}
          optionValue={(role) => role.id}
          options={roles}
          placeholder="Select role..."
          value={roleId}
          onChange={selectRole}
        />
      </Box>
    </Tooltip>
  );
};

const placeholder = {
  title: "No members",
  error: "Members failed to load, please try again.",
};

export const Members = () => {
  const { user, workspace } = useUser();

  const [inviting, setInviting] = useState<boolean>(false);
  const { selectedRows, onRowSelect } = useRowSelect();
  const [confirmingDelete, setConfirmingDelete] = useState<boolean>(false);
  const [search, setSearch] = useState<string>("");

  const { limit, offset, orderBy, page, setPage, onSort } = useTableConfig<MembershipsOrderBy>({
    defaultSortKey: "user.name",
    sortOptions: Object.values(SortKeys),
    limit: 25,
  });

  const { data: workspaceData, isLoading: loadingWorkspace } = useWorkspaceQuery(
    {
      limit,
      offset,
      search: search ? `%${search}%` : undefined,
      orderBy,
      workspaceId: workspace?.id,
    },
    {
      keepPreviousData: true,
    },
  );

  const { mutateAsync: deleteMembers } = useDeleteMembersMutation();

  const memberships = workspaceData?.workspaces_by_pk?.memberships;
  const membershipCount = workspaceData?.all_memberships_aggregate?.aggregate?.count ?? 0;

  // Extract users and inject role_id into each row.
  const users = useMemo(
    () =>
      memberships?.map((membership) => ({
        ...membership.user,
        role_id: membership.role?.id,
        is_sso: membership.is_sso,
      })),
    [memberships],
  );

  const currentUserSelected = selectedRows.includes(Number(user?.id));

  const columns: TableColumn[] = [
    {
      name: "Name",
      sortDirection: orderBy?.user?.name,
      cell: ({ name }) => (
        <Text fontWeight="medium" isTruncated>
          {name}
        </Text>
      ),
      onClick: () => onSort(SortKeys.Name),
      breakpoint: "sm",
    },
    {
      name: "Email address",
      key: "email",
      max: "240px",
      sortDirection: orderBy?.user?.email,
      onClick: () => onSort(SortKeys.Email),
    },
    {
      header: () => {
        return (
          <>
            Role
            <Tooltip message="Only available in Business tier workspaces">
              <Box as={InformationCircleIcon} boxSize={5} color="gray.600" ml={1} />
            </Tooltip>
          </>
        );
      },
      max: "240px",
      sortDirection: orderBy?.role?.name,
      onClick: () => onSort(SortKeys.Role),
      cell: ({ role_id: roleId, id: userId }) => (
        <Permission fallback={workspace?.roles?.find((role) => role.id === roleId)?.name}>
          <RoleSelector roleId={roleId} userId={userId} />
        </Permission>
      ),
    },
    {
      name: "Authentication method",
      cell: ({ google_auth_id, azure_ad_auth_id, auth0_auth_id }) => {
        if (google_auth_id) {
          return "Google";
        } else if (azure_ad_auth_id) {
          return "Microsoft";
        } else if (auth0_auth_id) {
          return "Single sign on";
        }
        return "Email";
      },
      breakpoint: "md",
    },
    {
      name: "Member since",
      key: "created_at",
      max: "240px",
      sortDirection: orderBy?.user?.created_at,
      onClick: () => onSort(SortKeys.CreatedAt),
      cell: (timestamp) => format(new Date(timestamp), "MMMM do y"),
      breakpoint: "md",
    },
  ];

  return (
    <PermissionProvider permissions={[{ resource: "workspace", grants: [ResourcePermissionGrant.Update] }]}>
      <Settings route="members" title="Members">
        <Column gap={10} flex={1}>
          <PendingInvites defaultWorkspaceRoleId={workspace?.default_role_id} workspaceId={workspace?.id} />

          <AwaitingApproval workspaceId={workspace?.id} />

          <Column flex={1}>
            <Row align="center" justify="space-between">
              <SectionHeading mb={4}>Members</SectionHeading>

              {/* Organizations (only created on paid plans) can disable manually inviting users. */}
              {(!workspaceData?.workspaces_by_pk?.organization ||
                workspaceData?.workspaces_by_pk?.organization?.can_invite_users) && (
                <Permission>
                  <Button variant="primary" onClick={() => setInviting(true)}>
                    Invite new members
                  </Button>
                </Permission>
              )}
            </Row>

            <Row justify="space-between" mb={4}>
              <SearchInput
                placeholder="Search by name or email..."
                value={search}
                onChange={(event) => setSearch(event.target.value)}
              />
              {selectedRows.length > 0 && (
                <Button variant="warning" onClick={() => setConfirmingDelete(true)}>
                  Delete selected members
                </Button>
              )}
            </Row>

            <Table
              columns={columns}
              data={users}
              loading={loadingWorkspace}
              placeholder={placeholder}
              rowHeight="55px"
              selectedRows={selectedRows}
              onSelect={onRowSelect}
            />
            <Row mt={4} justify="flex-end" width="100%">
              <Pagination count={membershipCount} label="members" page={page} rowsPerPage={limit} setPage={setPage} />
            </Row>
          </Column>
        </Column>
      </Settings>

      <InviteFormModal close={() => setInviting(false)} name={workspace?.name ?? ""} open={inviting} />
      <BulkDeleteConfirmationModal
        content={
          currentUserSelected ? "You can not remove yourself from a workspace. Please update your selection." : undefined
        }
        count={selectedRows.length}
        disabled={currentUserSelected}
        isOpen={confirmingDelete}
        label="member"
        onClose={() => setConfirmingDelete(false)}
        onDelete={async () => {
          for (const userId of selectedRows) {
            const user = users?.find((user) => user.id === userId);
            if (user?.is_sso) {
              throw new ValidationError("You cannot remove an SSO user.");
            }
          }
          await deleteMembers({ workspaceId: workspace?.id, userIds: selectedRows.map(String) });
          onRowSelect([]);
        }}
      />
    </PermissionProvider>
  );
};

const AwaitingApproval = ({ workspaceId }) => {
  const { data } = useMembershipRequestsByWorkspaceQuery({
    workspaceId,
  });

  const awaitingApprovals = data?.membership_requests;

  if (!awaitingApprovals?.length) {
    return null;
  }

  return (
    <Column>
      <SectionHeading mb={4}>Awaiting approval</SectionHeading>
      <Column gap={2}>
        {awaitingApprovals?.map(({ user: { name, email, id } }) => (
          <MembershipRequest key={id} email={email} name={name} userId={id} workspaceId={workspaceId} />
        ))}
      </Column>
    </Column>
  );
};

const MembershipRequest: FC<{ name: string; email: string; workspaceId: string; userId: string }> = ({
  workspaceId,
  userId,
  name,
  email,
}) => {
  const { toast } = useToast();
  const { mutate: rejectRequest } = useRejectMembershipMutation();
  const { mutate: approveRequest } = useApproveMembershipMutation();

  return (
    <Box
      alignItems="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      px={3}
      py={2}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Box alignItems="center" bg="forest.background" borderRadius="full" boxSize={7} display="flex" justifyContent="center">
          <Box as={UserPlusIcon} boxSize={4} color="forest.500" />
        </Box>

        <Text fontWeight="medium">{name}</Text>
        <Text>{email}</Text>
      </Box>

      <ButtonGroup>
        <Button
          onClick={() => {
            rejectRequest({
              workspaceId: String(workspaceId),
              userId: String(userId),
            });
          }}
        >
          Ignore
        </Button>

        <Button
          variant="primary"
          onClick={async () => {
            try {
              await approveRequest({
                userId: String(userId),
              });

              toast({
                id: "approve-membership",
                title: `Workspace access for ${name} approved`,
                variant: "success",
              });
            } catch (error: unknown) {
              toast({
                id: "approve-membership",
                title: "Something went wrong",
                message: "Failed to approve workspace access, please try again.",
                variant: "error",
              });
            }
          }}
        >
          Approve access
        </Button>
      </ButtonGroup>
    </Box>
  );
};

const PendingInvites = ({ workspaceId, defaultWorkspaceRoleId }) => {
  const { data: pendingInvitesData } = usePendingInvitesQuery(
    {
      workspaceId,
    },
    {
      refetchInterval: 3000,
    },
  );

  const pendingInvites = pendingInvitesData?.outbound_user_invites;

  if (!pendingInvites?.length) {
    return null;
  }

  return (
    <Column>
      <SectionHeading mb={4}>Pending invitations</SectionHeading>
      <Column gap={2}>
        {pendingInvites?.map(({ recipient_email, expires_at, role_id }) => (
          <PendingInvite
            key={recipient_email}
            defaultWorkspaceRoleId={defaultWorkspaceRoleId}
            expiration={expires_at}
            recipient={recipient_email}
            roleID={role_id}
            workspaceId={workspaceId}
          />
        ))}
      </Column>
    </Column>
  );
};

const PendingInvite: FC<{
  recipient: string;
  expiration: string;
  workspaceId: string;
  roleID?: number | null;
  defaultWorkspaceRoleId: number;
}> = ({ workspaceId, recipient, expiration, roleID, defaultWorkspaceRoleId }) => {
  const { toast } = useToast();

  const { mutate: cancelInvite } = useCancelInviteMutation();
  const { mutateAsync: sendInvite } = useSendInviteMutation();

  const now = new Date();
  const expiryDate = new Date(expiration);

  const expired = expiryDate < now;
  const daysLeftUntilExpiry = differenceInDays(expiryDate, now);

  return (
    <Box
      alignItems="center"
      border="1px"
      borderColor="gray.300"
      borderRadius="md"
      display="flex"
      justifyContent="space-between"
      px={3}
      py={1.5}
    >
      <Box alignItems="center" display="flex" gap={2}>
        <Box alignItems="center" bg="forest.100" borderRadius="full" boxSize={7} display="flex" justifyContent="center">
          <Box as={UserPlusIcon} boxSize={4} color="forest.500" />
        </Box>

        <Text fontWeight="medium">{recipient}</Text>
      </Box>

      <Box alignItems="center" display="flex" gap={3}>
        {expired ? (
          <Text>Expired</Text>
        ) : (
          <Text>Expires in {daysLeftUntilExpiry ? `${daysLeftUntilExpiry} days` : "<1 day"}</Text>
        )}

        <Menu>
          <MenuActionsButton />

          <MenuList>
            <MenuItem
              icon={PaperAirplaneIcon}
              onClick={async () => {
                try {
                  await sendInvite({
                    recipientEmail: recipient,
                    recipientRoleID: roleID || defaultWorkspaceRoleId,
                  });

                  toast({
                    id: "resend-invite",
                    title: `Invitation for ${recipient} was resent`,
                    variant: "success",
                  });
                } catch (error: unknown) {
                  toast({
                    id: "resend-invite",
                    title: "Something went wrong",
                    message: "Failed to resend an invitation, please try again.",
                    variant: "error",
                  });
                }
              }}
            >
              Resend invitation
            </MenuItem>

            <MenuItem
              icon={TrashIcon}
              onClick={() => {
                cancelInvite({
                  recipientEmail: recipient,
                  workspaceId,
                });
              }}
            >
              Cancel invitation
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
    </Box>
  );
};
